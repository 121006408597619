import { gql } from "@apollo/client/core";

export const COMPANY_DOCUMENTS = gql`
  query companyDocuments($companyId: String!) {
    companyDocuments(companyId: $companyId)
      @rest(
        type: "CompanyDocuments"
        method: GET
        path: "/documents/companies/{args.companyId}/customer"
      ) {
      items {
        id
        kind
        name
        mimeType
        img
      }
    }
  }
`;
