import { useEffect, useState } from "react";
import { CustomButton } from "components/Buttons";
import { P } from "components/Fonts";
import { CustomButtonVariants } from "components/Buttons/types";
import { Auth } from "aws-amplify";
import {
  Alert,
  Box,
  FormControl,
  Grid,
  InputAdornment,
  Stack,
  Typography,
} from "@mui/material";
import { FieldValues, useForm } from "react-hook-form";
import { toast } from "toast";
import * as Sentry from "@sentry/react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ROUTER_PATHES } from "routes/routes";
import IconButton from "components/@extended/IconButton";
import {
  EyeInvisibleOutlined,
  EyeOutlined,
  InfoCircleFilled,
} from "@ant-design/icons";
import { strengthColor, strengthIndicator } from "utils/passwordStrength";
import { StringColorProps } from "models/PasswordColors";
import { AuthControlledTextField } from "../styles";
import { CognitoPasswordRegex } from "utils/regexValidations";

export const AuthResetPassword: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [level, setLevel] = useState<StringColorProps>();
  const [showPassword, setShowPassword] = useState(false);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
    watch,
  } = useForm();
  const COMMON_PROPS = { control: control, errors: errors, register: register };

  let newPassword = watch("password");

  useEffect(() => {
    if (redirect) {
      navigate(ROUTER_PATHES.LOGIN, { replace: true });
    }
  }, [redirect, navigate]);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const changePassword = (value: string) => {
    const temp = strengthIndicator(value);
    setLevel(strengthColor(temp));
  };

  useEffect(() => {
    changePassword("");
  }, []);

  const onSubmitHandler = ({ code, password }: FieldValues) => {
    setIsLoading(true);

    const userEmail = searchParams.get("username");

    if (!userEmail) {
      toast.error("No user found");
      return false;
    }

    Auth.forgotPasswordSubmit(userEmail.trim(), code.trim(), password.trim())
      .then(() => setRedirect(true))
      .catch((error: any) => {
        Sentry.captureException(error);
        toast.error(error?.message as string);

        setIsLoading(false);
      });
  };

  return (
    <form noValidate onSubmit={handleSubmit(onSubmitHandler)}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Stack spacing={1}>
            <AuthControlledTextField
              {...COMMON_PROPS}
              required
              fullWidth
              name="code"
              label="Enter the verification code"
              autoComplete="code"
              id="verification-code"
              variant="outlined"
              type="password"
            />
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Stack spacing={1}>
            <AuthControlledTextField
              {...COMMON_PROPS}
              required
              fullWidth
              name="password"
              label="Enter password"
              autoComplete="new-password"
              id="password"
              variant="outlined"
              type={showPassword ? "text" : "password"}
              onKeyUp={(e) =>
                changePassword((e.target as HTMLInputElement).value)
              }
              rules={{
                validate: (value) =>
                  CognitoPasswordRegex.test(value.trim()) ||
                  "Password is not complex enough. Please try again.",
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      edge="end"
                      color="secondary"
                    >
                      {showPassword ? (
                        <EyeOutlined />
                      ) : (
                        <EyeInvisibleOutlined />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Stack>
          <FormControl fullWidth sx={{ mt: 2 }}>
            <Grid container spacing={2} alignItems="center">
              <Grid item>
                <Box
                  sx={{
                    bgcolor: level?.color,
                    width: 85,
                    height: 8,
                    borderRadius: "7px",
                  }}
                />
              </Grid>
              <Grid item>
                <Typography variant="caption" fontSize="0.75rem">
                  {level?.label}
                </Typography>
              </Grid>
            </Grid>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Stack spacing={1}>
            <AuthControlledTextField
              {...COMMON_PROPS}
              required
              fullWidth
              name="confirmPassword"
              label="Enter confirm password"
              autoComplete="new-password"
              id="confirm-password-reset"
              variant="outlined"
              type="password"
              rules={{
                validate: (value) =>
                  value === newPassword || "The passwords do not match",
              }}
            />
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Stack spacing={1}>
            <Alert color="info" variant="outlined" icon={<InfoCircleFilled />}>
              <Typography variant="h6">
                Password must contain a lower case letter
              </Typography>
              <Typography variant="h6">
                Password must contain an upper case letter
              </Typography>
              <Typography variant="h6">
                Password must contain a special character
              </Typography>
              <Typography variant="h6">
                Password must contain a number
              </Typography>
              <Typography variant="h6">
                Password must contain at least 8 characters
              </Typography>
            </Alert>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <CustomButton
            type="submit"
            variant={CustomButtonVariants.black}
            loading={isLoading}
            width="100%"
          >
            <P size="small">Update Password</P>
          </CustomButton>
        </Grid>
      </Grid>
    </form>
  );
};
