import { useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { FAQDashboardProps, FaqItem } from "./types";
import { P } from "components/Fonts";
import { CustomCard } from "components/Cards";
import { StyledFaqFooter, StyledReadMore } from "./styles";
import { LinkExternalDomain } from "components/LinkExternalDomain";
import { IntercomTriggerWrapper } from "components/IntercomTriggerWrapper";
import utils from "utils";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

export const FAQDashboard: React.FC<FAQDashboardProps> = ({
  faqs,
  firstOpened,
}) => {
  const [expanded, setExpanded] = useState(firstOpened ? faqs[0].question : "");

  const handleChange = (question: string, isExpanded: boolean) => {
    setExpanded(!isExpanded ? question : "");
  };

  const getFaqItem = (
    { question, answer, readMorelink }: FaqItem,
    isExpanded: boolean
  ) => {
    return (
      <>
        <AccordionSummary
          expandIcon={isExpanded ? <RemoveIcon /> : <AddIcon />}
        >
          <P size="small" emphasis color={utils.colors.grass}>
            {question}
          </P>
        </AccordionSummary>
        <AccordionDetails sx={{ display: isExpanded ? "" : "none" }}>
          {answer}

          {readMorelink && (
            <LinkExternalDomain to={readMorelink}>
              <StyledReadMore>
                <P size="small" emphasis>
                  Read more →
                </P>
              </StyledReadMore>
            </LinkExternalDomain>
          )}
        </AccordionDetails>
      </>
    );
  };

  return (
    <CustomCard borderRadius="strong" shadow="xstrong">
      <P size="large" emphasis colorOpacity="75%">
        FAQ's
      </P>
      <br />
      <div>
        {faqs.map((faqItem, index) => {
          const isExpanded = expanded === faqItem.question;
          return (
            <Accordion
              key={index}
              expanded={isExpanded}
              elevation={0}
              sx={{
                marginBottom: "15px",
                border: "1px solid #F6F6F6",
                borderRadius: "6px",
                boxShadow: "0px 2px 0px rgba(0, 0, 0, 0.02)",
                "&.MuiAccordion-root:before": {
                  backgroundColor: "white",
                },
              }}
              onChange={() => handleChange(faqItem.question, isExpanded)}
            >
              <div key={index}>{getFaqItem(faqItem, isExpanded)}</div>
            </Accordion>
          );
        })}
      </div>

      <StyledFaqFooter>
        <P
          size="small"
          color={utils.colors.charcoal}
          colorOpacity="75%"
          emphasis
        >
          Do you have any further questions?
        </P>
        <P color="#888888" size="xsmall">
          If you cannot find an answer to your question, please make sure to
          check our{" "}
          <LinkExternalDomain to="https://www.doola.com/faq">
            <P
              color={utils.colors.grass}
              size="small"
              display="contents"
              emphasis
            >
              extended FAQs
            </P>
          </LinkExternalDomain>{" "}
          or{" "}
          <IntercomTriggerWrapper>
            <P
              color={utils.colors.grass}
              size="small"
              display="contents"
              emphasis
            >
              contact us live
            </P>
          </IntercomTriggerWrapper>
          .
        </P>
      </StyledFaqFooter>
    </CustomCard>
  );
};
