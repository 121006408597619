import styled, { css } from "styled-components/macro";

export const IconWrapper = css`
  padding: 8px;
  padding: 11px;
  color: white;
  display: flex;
  margin-right: 5px;
  > svg {
    margin: auto;
  }
`;

export const IconsWrapper = styled.div`
  display: flex;
  margin: auto 0;
`;

export const NoGraphWrapper = styled.div`
  height: 90%;
  display: flex;
  padding-top: 20px;
  margin-top: 50px;
`;
