import { P } from "components/Fonts";
import SensitiveCardData from "./SensitiveData";
import { CardDetailsWrapper, TextWrapper } from "./styles";
import { SVGCardDetails } from "./svgs";
/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */

type Props = {
  name: string;
  cardNumber: string;
  expiration: string;
  cvc: string;
  id: string;
};

export default ({
  name,
  cardNumber,
  expiration,
  cvc,
  id,
}: Props): JSX.Element => {
  return (
    <CardDetailsWrapper>
      <SVGCardDetails />
      <TextWrapper>
        <P size="small" colorOpacity="75%">
          {name}
        </P>
        <SensitiveCardData
          cardId={id}
          cardNumber={cardNumber}
          expiration={expiration}
          cvc={cvc}
        />
      </TextWrapper>
    </CardDetailsWrapper>
  );
};
