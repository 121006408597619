import { GAClickTrackerWrapper } from "components/GoogleAnalytics";
import { FunctionComponent } from "react";
import { LinkExternalDomainProps } from "./types";

export const LinkExternalDomain: FunctionComponent<LinkExternalDomainProps> = ({
  to,
  children,
  sametab = false,
  style,
}) => (
  <GAClickTrackerWrapper event="external_domain_link" labelReference={to}>
    <a
      href={to}
      target={sametab ? "_self" : "_blank"}
      rel="noreferrer"
      style={{
        textDecoration: "none",
        color: "inherit",
        ...style,
      }}
    >
      {children}
    </a>
  </GAClickTrackerWrapper>
);
