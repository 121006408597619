import styled, { css } from "styled-components/macro";

const commonStyles = css`
  padding: 15px 20px;
  border-bottom: 1px solid #dddddd;
`;

export const RowStatementWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${commonStyles}
`;

export const RowStatementHeaderWrapper = styled.div`
  ${commonStyles}
`;

export const SVGDownloadWrapper = styled.div`
  cursor: pointer;
  display: contents;
  :hover {
    opacity: 0.5;
  }
`;
