import Documents from "views/DashboardApp/Documents";
import Dashboard from "views/DashboardApp/Dashboard";
import Referrals from "views/DashboardApp/Referral";
import Rewards from "views/DashboardApp/Rewards";
import Services from "views/DashboardApp/Services";
import {
  BankingHome,
  BankingPayments,
  BankingTransactions,
  BankingCards,
  BankingCardProfile,
  BankingCardCreateACard,
  BankingStatements,
} from "views/DashboardApp/Banking";
import { SidebarItemT } from "./types";
import {
  BANKING_NON_SIDEBAR_ROUTES,
  BANKING_ROUTES,
  ROUTER_PATHES,
} from "routes/routes";
import ReceiptIcon from "@mui/icons-material/Receipt";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import {
  DollarOutlined,
  FileTextOutlined,
  GiftOutlined,
  HomeOutlined,
  ProfileOutlined,
} from "@ant-design/icons";

export const buildDynamicRoute = {
  card: (id: string) => BANKING_NON_SIDEBAR_ROUTES.card.replace(":id", id),
  cardSuccess: (id: string) =>
    BANKING_NON_SIDEBAR_ROUTES.createACardSucess.replace(":id", id),
};

export const TabsData: SidebarItemT[] = [
  {
    label: "Dashboard",
    route: ROUTER_PATHES.DASHBOARD,
    icon: <HomeOutlined />,
    element: <Dashboard />,
  },
  {
    label: "Documents",
    route: ROUTER_PATHES.DOCUMENTS,
    icon: <FileTextOutlined />,
    element: <Documents />,
  },
  // {
  //   label: "Mail",
  //   route: ROUTER_PATHES.MAIL,
  //   icon: <MailOutlined />,
  //   element: <Mail />,
  // },
  {
    label: "Services",
    route: ROUTER_PATHES.SERVICES,
    icon: <ProfileOutlined />,
    element: <Services />,
  },
  {
    label: "Refer & Earn",
    route: ROUTER_PATHES.REFERRALS,
    icon: <DollarOutlined />,
    element: <Referrals />,
  },
  {
    label: "Rewards",
    route: ROUTER_PATHES.REWARDS,
    icon: <GiftOutlined />,
    element: <Rewards />,
  },
];

export const BankingSectionItems: SidebarItemT[] = [
  {
    label: "Home",
    route: ROUTER_PATHES.BANKING,
    element: <BankingHome />,
  },
  {
    label: "Transactions",
    route: BANKING_ROUTES.transaction,
    icon: <ReceiptIcon />,
    element: <BankingTransactions />,
  },
  {
    label: "Cards",
    route: BANKING_ROUTES.cards,
    icon: <CreditCardIcon />,
    element: <BankingCards />,
  },
  {
    label: "Payments",
    route: BANKING_ROUTES.payments,
    icon: <AccountBalanceWalletIcon />,
    element: <BankingPayments />,
  },
  // {
  //   label: "Accounts",
  //   route: BANKING_ROUTES.accounts,
  //   icon: <AccountBoxIcon />,
  //   element: <BankingAccounts />,
  // },
  {
    label: "Statements",
    route: BANKING_ROUTES.statements,
    icon: <AccountBalanceWalletIcon />,
    element: <BankingStatements />,
  },
];

export const BankingHiddenRoutes = [
  {
    route: BANKING_NON_SIDEBAR_ROUTES.card,
    element: <BankingCardProfile viewAs="profile" />,
  },
  {
    route: BANKING_NON_SIDEBAR_ROUTES.createACard,
    element: <BankingCardCreateACard />,
  },
  {
    route: BANKING_NON_SIDEBAR_ROUTES.createACardSucess,
    element: <BankingCardProfile viewAs="created" />,
  },
];
