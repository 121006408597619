import { Alert, AlertTitle, Grid } from "@mui/material";
import { P } from "components/Fonts";
import { LoadingComponent } from "components/Loading";
import { ResourcesCard } from "components/ResourcesCard";
import YourPlan from "components/YourPlan";
import { useAuth } from "hooks/useAuth";
import { CompanyService, Service } from "models/ServiceModel";
import { AddonsComponent } from "./Addons";
import { ResourcesData } from "./data";

type Props = {
  services: CompanyService[];
};

export const Services: React.FC<Props> = ({ services }) => {
  const addonServices = new Service(services).addonsServices();

  return (
    <Grid container spacing={3}>
      <Grid item lg={12}>
        <P size="large" emphasis>
          Services
        </P>
        {services.length ? (
          <P size="medium" colorOpacity="50%">
            These are the services for your company
          </P>
        ) : (
          <>
            <P size="medium" colorOpacity="50%">
              No services at this time...
            </P>
            <Alert severity="warning" variant="filled">
              <AlertTitle>Note</AlertTitle>
              If you recently made a purchase please allow up to 24 hours for
              your services to be added to the dashboard.
            </Alert>
          </>
        )}
      </Grid>
      <Grid item lg={4} mb={2}>
        <YourPlan services={services} />
      </Grid>
      <Grid item lg={4}>
        <ResourcesCard
          resources={ResourcesData.resources}
          title={ResourcesData.title}
          subTitle={ResourcesData.subtitle}
        />
        <br />
        <AddonsComponent services={addonServices as any} />
      </Grid>
    </Grid>
  );
};

export const ServicesFetcher: React.FC = () => {
  const { currentCompany } = useAuth();

  if (!currentCompany) return <LoadingComponent />;

  return <Services services={currentCompany.services} />;
};

export default ServicesFetcher;
