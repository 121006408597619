import { PersistedCompany } from "models/CompanyModel";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import {
  selectCurrentCompany,
  selectCurrentTestABVariant,
  selectPromotionCode,
  selectEverflowPartner,
} from "state/authSlice";

export const useAuth = () => {
  const currentCompany = useSelector(selectCurrentCompany) as PersistedCompany;
  const currentTestABVariant = useSelector(selectCurrentTestABVariant);
  const currentEverflowPartner = useSelector(selectEverflowPartner);
  const currentPromotionCode = useSelector(selectPromotionCode);

  return useMemo(
    () => ({
      currentCompany,
      currentTestABVariant,
      currentEverflowPartner,
      currentPromotionCode,
    }),
    [
      currentCompany,
      currentTestABVariant,
      currentEverflowPartner,
      currentPromotionCode,
    ]
  );
};
