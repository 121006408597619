import styled from "styled-components/macro";

export const LastPaymentRowWrapper = styled.div`
  display: flex;
  border-bottom: 1px solid #f6f6f6;
  padding: 10px;
  align-items: center;
`;

export const IconWrapper = styled.div`
  width: 50px;
  svg {
    width: auto;
    height: 40px;
    margin-left: -12px;
  }
`;

export const NoDataYetWrapper = styled.div`
  height: 90%;
  display: flex;
`;
