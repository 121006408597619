import { useRef, useState } from "react";
import { useTheme } from "@mui/material/styles";
import {
  Badge,
  Box,
  ClickAwayListener,
  Paper,
  Popper,
  useMediaQuery,
} from "@mui/material";
import MainCard from "components/MainCard";
import IconButton from "components/@extended/IconButton";
import Transitions from "components/@extended/Transitions";
import { BellOutlined, BellFilled } from "@ant-design/icons";
import { P } from "components/Fonts";
import { NoNotificationsWrapper } from "./styles";

const Notification = () => {
  const theme = useTheme();
  const matchesXs = useMediaQuery(theme.breakpoints.down("md"));

  const anchorRef = useRef<any>(null);
  const [open, setOpen] = useState(false);
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: MouseEvent | TouchEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const iconColor = theme.palette.mode === "dark" ? "grey.200" : "grey.900";
  const iconColorHover =
    theme.palette.mode === "dark" ? "grey.500" : "grey.500";

  return (
    <Box sx={{ flexShrink: 0, ml: 0.75 }}>
      <IconButton
        color="secondary"
        sx={{
          color: iconColor,
          bgcolor: "none",
          fontSize: "24px",
          "&:hover": {
            bgcolor: "transparent",
            color: iconColorHover,
            transition: "all .3s ease-in-out",
          },
          "&::after": { boxShadow: "none" },
        }}
        aria-label="open profile"
        ref={anchorRef}
        aria-controls={open ? "profile-grow" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <Badge color="primary">
          <BellOutlined />
        </Badge>
      </IconButton>
      <Popper
        placement={matchesXs ? "bottom" : "bottom-end"}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal={false}
        sx={{ zIndex: "1200" }}
        popperOptions={{
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [matchesXs ? -5 : 0, 9],
              },
            },
          ],
        }}
      >
        {({ TransitionProps }) => (
          <Transitions type="fade" in={open} {...TransitionProps}>
            <Paper
              sx={{
                boxShadow: theme.customShadows.z1,
                width: "100%",
                minWidth: 285,
                maxWidth: 420,
                [theme.breakpoints.down("md")]: {
                  maxWidth: 285,
                },
              }}
            >
              <ClickAwayListener onClickAway={handleClose}>
                <MainCard
                  title="Notifications"
                  elevation={0}
                  border={false}
                  content={false}
                >
                  <MainCard
                    sx={{
                      ".MuiCardContent-root": NoNotificationsWrapper,
                    }}
                  >
                    <BellFilled
                      style={{
                        color: "#1e1e1e50",
                        fontSize: "20px",
                        marginBottom: "10px",
                      }}
                    />
                    <P size="small" colorOpacity="50%">
                      There are no notifications at the moment
                    </P>
                  </MainCard>
                </MainCard>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
    </Box>
  );
};

export default Notification;
