interface IPackageServices {
  label: string;
  value?: number;
  discount?: boolean;
}

export enum PricingPackagesIds {
  companyFormationPackage = "1",
  totalComplianceBundle = "2",
}

export interface IPricingPackage {
  id: PricingPackagesIds;
  label: string;
  price: number;
  stripePriceId: string;
  isMonthly: boolean;
  stateFeeIncluded: boolean;
  name: string;
  recurring: boolean;
  saveAmount: number;
  description?: string;
  services?: IPackageServices[];
}
