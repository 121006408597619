import { ROUTER_PATHES } from "routes/routes";

const APPS_WITHOUT_BG = [ROUTER_PATHES.MAIL, ROUTER_PATHES.BANKING];

export default function useAppWithoutBackground() {
  const appWithoutBg = APPS_WITHOUT_BG.some((route) => {
    return window.location.href.indexOf(route) > -1;
  });

  return appWithoutBg;
}
