import React, { FunctionComponent } from "react";
import { StyledCopyLink } from "./styles";
import { Popover } from "@mui/material";
import { P } from "components/Fonts";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

type Props = {
  linkToCopy: string;
};

export const CopyIcon: FunctionComponent<Props> = ({ linkToCopy }) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    navigator.clipboard.writeText(linkToCopy);
    setAnchorEl(event.currentTarget);

    setTimeout(() => {
      handleClose();
    }, 2000);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <StyledCopyLink onClick={(event) => handleClick(event as any)}>
      <ContentCopyIcon />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <P size="xsmall" display="inline" margin="10px">
          The link has been copied to your clipboard.
        </P>
      </Popover>
    </StyledCopyLink>
  );
};
