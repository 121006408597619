import { Grid } from "@mui/material";
import { CustomButton } from "components/Buttons";
import { CustomCard } from "components/Cards";
import { P } from "components/Fonts";
import { buildCardTableData, buildCardTableDataMock } from "./services";
import Table from "./Table";
import BankingCardCreateACard from "./CreateACard";
import { useNavigate } from "react-router-dom";
import {
  useGetCardLimitsByIdMutation,
  useGetCardsByAccountMutation,
} from "services/banking-api";
import { useEffect, useState } from "react";
import { useAuthBanking } from "hooks/useAuthBanking";
import { TableItem } from "./Table/types";
import { LoadingComponent } from "components/Loading";
import { CustomButtonVariants } from "components/Buttons/types";
import { BANKING_NON_SIDEBAR_ROUTES } from "routes/routes";
export { BankingCardCreateACard };
/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */

type Props = {
  rowsData: TableItem[];
};

const Cards: React.FC<Props> = ({ rowsData }) => {
  const navigate = useNavigate();

  const _onClick = () => {
    navigate(BANKING_NON_SIDEBAR_ROUTES.createACard);
  };

  return (
    <Grid container spacing={3} mt={5}>
      <Grid item lg={12}>
        <CustomCard
          shadow="xstrong"
          borderRadius="strong"
          styles={{ minWidth: "700px", overflowX: "auto" }}
        >
          <P size="large" emphasis margin="0px 0px 20px 0px">
            Cards
          </P>

          <CustomButton
            onClick={_onClick}
            variant={CustomButtonVariants.black}
            borderRadius="rounded"
          >
            <P size="small">+ New Card</P>
          </CustomButton>
          <br />
          <br />
          <Table rowsData={rowsData}></Table>
        </CustomCard>
      </Grid>
    </Grid>
  );
};

export default (): JSX.Element => {
  const { currentAccountId, currentSandboxMode } = useAuthBanking();

  if (currentSandboxMode) {
    const rowsData = buildCardTableDataMock();
    return <Cards rowsData={rowsData} />;
  }

  const [getCardsByAccount, { data: cardsData }] =
    useGetCardsByAccountMutation();
  const [getCardLimitsById] = useGetCardLimitsByIdMutation();
  const [cardData, setCardsData] = useState<any>(undefined);

  useEffect(() => {
    getCardsByAccount({ accountId: currentAccountId });
  }, [getCardsByAccount, currentAccountId]);

  useEffect(() => {
    if (cardsData) {
      const getData = async () => {
        const callbackSucces = (data: any) => {
          setCardsData(data);
        };
        await buildCardTableData(cardsData, getCardLimitsById, callbackSucces);
      };
      getData();
    }
  }, [cardsData, getCardLimitsById]);

  if (!cardsData || !cardData) {
    return <LoadingComponent />;
  }

  return <Cards rowsData={cardData} />;
};
