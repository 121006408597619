import { ROUTER_PATHES } from "./routes";
import GuestGuard from "utils/route-guard/GuestGuard";
import AuthWrapper from "views/Auth/AuthWrapper";
import Login from "views/Auth/Login";
import RecoverPassword from "views/Auth/RecoverPassword";
import ResetPassword from "views/Auth/ResetPassword";

const LoginRoutes = {
  path: "/",
  children: [
    {
      path: "/",
      element: (
        <GuestGuard>
          <AuthWrapper />
        </GuestGuard>
      ),
      children: [
        {
          path: ROUTER_PATHES.LOGIN,
          element: <Login />,
        },
        {
          path: ROUTER_PATHES.RECOVER_PASSWORD,
          element: <RecoverPassword />,
        },
        {
          path: ROUTER_PATHES.RESET_PASSWORD,
          element: <ResetPassword />,
        },
      ],
    },
  ],
};

export default LoginRoutes;
