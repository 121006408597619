import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { CustomButton } from "components/Buttons";
import { CustomButtonVariants } from "components/Buttons/types";
import { P } from "components/Fonts";
import { useState } from "react";
import utils from "utils";
import { SVGPlaneWrapper } from "views/DashboardApp/Banking/Payments/MakePaymentSteps/styles";
import { SVGCheck } from "views/DashboardApp/Banking/SharedComponents/SVGs";

/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
type Props = {
  onVerify: (value: string) => void;
};

export default ({ onVerify }: Props): JSX.Element => {
  const [open, setOpen] = useState(true);
  const [value, setValue] = useState("");

  const handleClose = () => {
    setOpen(false);
  };

  const onClick = () => {
    onVerify(value);
    handleClose();
  };

  return (
    <Dialog
      open={open}
      maxWidth="sm"
      onClick={(event) => {
        event.stopPropagation();
      }}
      onClose={handleClose}
    >
      <DialogTitle>
        <P
          size="large"
          display="flex"
          style={{
            alignItems: "baseline",
            justifyContent: "center",
            flexWrap: "wrap",
          }}
        >
          <SVGPlaneWrapper>
            <SVGCheck color={utils.colors.gold} />
          </SVGPlaneWrapper>
          Verify your identity
        </P>
      </DialogTitle>
      <DialogContent>
        <P size="small" colorOpacity="50%" margin="20px 0px">
          We sent you an SMS verification.
        </P>

        <input
          type="text"
          value={value}
          placeholder="SMS Code"
          style={{
            width: "100%",
            padding: "10px",
            borderRadius: "5px",
            border: "1px solid gray",
          }}
          onChange={(e) => setValue(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <CustomButton variant={CustomButtonVariants.primary} onClick={onClick}>
          Verify
        </CustomButton>
      </DialogActions>
    </Dialog>
  );
};
