import { ControlledTextField } from "components/FormElements/FormControllers";
import styled from "styled-components/macro";

export const AuthControlledTextField = styled(ControlledTextField)`
  .MuiInputBase-input.MuiOutlinedInput-input {
    padding: 10px 14px;
  }

  .MuiInputLabel-root {
    transform: translate(14px, 10px) scale(1);

    &.Mui-focused,
    &.MuiFormLabel-filled {
      transform: translate(14px, -9px) scale(0.75);
    }
  }
`;
