import styled, { css } from "styled-components/macro";
import utils from "utils";

interface ItemProps {
  readonly otherFont?: boolean;
  readonly emphasis?: boolean; // get rid of emphasis to use weight
  readonly weight?: "500" | "700";
  readonly size: "large" | "medium" | "small" | "xsmall" | string;
  readonly color?: "primary" | string;
  readonly colorOpacity?: "75%" | "50%" | "30%";
  readonly display?: "block" | "flex" | "contents" | "inline";
  readonly margin?: string;
  readonly textTransform?: "capitalize" | "uppercase" | "lowercase";
  readonly paddingBottom?: 0 | 0.5 | 1 | 2 | 3 | 4 | 5 | 6;
  readonly style?: any;
  children?: React.ReactNode;
}

export const P: React.FC<ItemProps> = (props) => (
  <StyledBuilder className="content-text" {...props}>
    {props.children}
  </StyledBuilder>
);

const PLargeStyled = css`
  // font-size: 24px;
  font-size: 1.5rem;
  // line-height: 32px;
  line-height: 2rem;
`;

const PMediumStyled = css`
  // font-size: 18px;
  font-size: 1.125rem;
  // line-height: 24px;
  line-height: 1.5rem;
`;

const PSmallStyled = css`
  // font-size: 14px;
  font-size: 0.875rem;
  // line-height: 24px;
  line-height: 1.5rem;
`;

const PXSmallStyled = css`
  // font-size: 12px;
  font-size: 0.75rem;
  // line-height: 16px;
  line-height: 1rem;
`;

const StyledBuilder = styled.div<ItemProps>`
  font-family: ${(props) =>
    props.otherFont ? "Frank Ruhl Libre" : "IBM Plex Sans, Arial, sans-serif"};
  font-style: normal;
  display: ${(props) => props.display ?? "block"};
  font-weight: ${(props) => {
    if (props.weight) {
      return props.weight;
    }

    if (props.emphasis) {
      return "bolder";
    }

    return "inherit";
  }};
  color: ${({ color }) => {
    switch (color) {
      case "primary":
        return utils.colors.grass;
      case undefined:
        return "inherit";
      default:
        return color;
    }
  }};
  opacity: ${(props) => props.colorOpacity ?? props.colorOpacity};
  margin: ${(props) => props.margin ?? "none"};
  padding-bottom: ${(props) =>
    (props.paddingBottom ? props.paddingBottom : 0) *
    utils.constants.units.main}px;
  ${(props) =>
    props.textTransform &&
    css`
      text-transform: ${props.textTransform};
    `}
  ${(props) => {
    switch (props.size) {
      case "large":
        return PLargeStyled;
      case "medium":
        return PMediumStyled;
      case "small":
        return PSmallStyled;
      case "xsmall":
        return PXSmallStyled;
      default:
        return `font-size: ${props.size}`;
    }
  }};
`;
