import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import GlobalStyles from "@mui/material/GlobalStyles";
import { Tracker } from "services/tracking";
import { Integrations } from "@sentry/tracing";
import * as Sentry from "@sentry/react";
import PostHog from "posthog-js";
import config from "config";

import { ToastContainer } from "toast";

import "react-toastify/dist/ReactToastify.min.css";
import { LoadingComponent } from "components/Loading";
import { useAppDispatch } from "state/hooks";
import { useIntercom } from "react-use-intercom";
import { Amplify, Auth } from "aws-amplify";
import { ICurrentUser } from "models/CustomerModel";
import ThemeCustomization from "themes";
import Routes from "routes";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "components/ErrorPage";
import useCurrentUser from "graphql/hooks/useCurrentUser";

const tracker = Tracker.getInstance();
Amplify.configure(config.amplify);

const PAYPAL_OPTIONS = {
  "client-id": config.payPalClientId,
  // We disable all paypal payment methods except paypal itself since other methods
  // can create accounting issues, and are otherwise best handled through stripe or
  // another vendor.
  "disable-funding":
    "card,credit,bancontact,blik,eps,giropay,ideal,mercadopago,mybank,p24,sepa,sofort,venmo",
  vault: "true",
  intent: "subscription",
};

export default function App() {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { update } = useIntercom();

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    Sentry.init({
      dsn: config.sentryDns,
      integrations: [new Integrations.BrowserTracing()],
      tracesSampleRate: 0.4,
    });

    PostHog.init(config.posthogKeys, {
      api_host: "https://app.posthog.com",
    });

    // tracker.getExperimentVariant("pricing-plans", (variant: string) => {
    //   dispatch(setTestABVariant(variant));

    //   setIsLoading(false);
    // });

    setIsLoading(false);
  }, [dispatch]);

  useEffect(() => {
    tracker.trackPageView(location.pathname);
  }, [location]);

  const { currentUser } = useCurrentUser();

  useEffect(() => {
    if (currentUser?.email) {
      tracker.tagUser(currentUser.email);
      tracker.trackProfitWell(currentUser.email);
      tracker.identifyFullStoryUser(currentUser);
      Sentry.setUser({
        email: currentUser.email,
      });
    }
  }, [location, currentUser]);

  useEffect(() => {
    if (currentUser?.email) {
      tracker.identifyPostHogUser(currentUser);

      const updateIntercom = async () => {
        const idTokenPayload = (await Auth.currentSession())
          .getIdToken()
          .decodePayload();

        const intercomHMAC = idTokenPayload["intercom:hmac"];
        updateIntercomWithProps(currentUser, intercomHMAC);
      };

      updateIntercom().catch(console.error);
    }
  }, [currentUser]); // eslint-disable-line react-hooks/exhaustive-deps

  const updateIntercomWithProps = (
    user: ICurrentUser,
    intercomHMAC: string
  ) => {
    update({
      userId: user.id,
      name: user.fullName,
      email: user.email,
      userHash: intercomHMAC,
    });
  };

  if (!(PostHog as any).__loaded || isLoading) return <LoadingComponent />;

  return (
    <ThemeCustomization>
      <GlobalStyles
        styles={{
          ul: {
            margin: 0,
            padding: 0,
            listStyle: "none",
          },
        }}
      />
      <ToastContainer />
      <PayPalScriptProvider options={PAYPAL_OPTIONS}>
        <ErrorBoundary FallbackComponent={ErrorFallback} onReset={() => {}}>
          <Routes />
        </ErrorBoundary>
      </PayPalScriptProvider>
    </ThemeCustomization>
  );
}
