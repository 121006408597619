/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */

import { P } from "components/Fonts";
import { Label } from "components/Label";
import React from "react";
import { useNavigate } from "react-router-dom";
import { moneyFormatter } from "utils/formatters";
import {
  SVGPhysicalCardMini,
  SVGVirtualCardMini,
} from "views/DashboardApp/Banking/SharedComponents/SVGs";
import {
  NoDataYetWrapper,
  SVGCardMiniWrapper,
  TableCellWrapper,
  TableHeaderWrapper,
  TableRowWrapper,
} from "./styles";
import { CardType, TableCellProps, TableItem } from "./types";
import { PopOver } from "./PopOver";
import { buildDynamicRoute } from "views/DashboardApp/Layout/Sidebar/data";
import { useAuthBanking } from "hooks/useAuthBanking";

type Props = {
  rowsData: TableItem[];
};

export default ({ rowsData }: Props): JSX.Element => {
  const { currentSandboxMode } = useAuthBanking();

  return (
    <>
      <TableHeader />
      {rowsData.map((rowData) => (
        <TableRow
          key={rowData.id}
          {...rowData}
          isSandbox={currentSandboxMode}
        />
      ))}
      {rowsData.length === 0 && <NoDataYet />}
    </>
  );
};

const TableHeader: React.FC = () => {
  return (
    <TableHeaderWrapper>
      <TableCell>
        <P size={"xsmall"} colorOpacity="50%">
          Cardholder
        </P>
      </TableCell>
      <TableCell maxWidth="200px">
        <P size={"xsmall"} colorOpacity="50%">
          Card
        </P>
      </TableCell>
      <TableCell maxWidth="200px" justifyContent="right">
        <P size={"xsmall"} colorOpacity="50%">
          Spent this month
        </P>
      </TableCell>
      <TableCell maxWidth="200px" justifyContent="center">
        <P size={"xsmall"} colorOpacity="50%">
          Type
        </P>
      </TableCell>
      <TableCell maxWidth="200px" justifyContent="center">
        <P size={"xsmall"} colorOpacity="50%">
          Status
        </P>
      </TableCell>
    </TableHeaderWrapper>
  );
};

type TableRowProps = TableItem & { isSandbox: boolean };

const TableRow: React.FC<TableRowProps> = ({
  id,
  cardHolder,
  cardNumber,
  cardType,
  spentThisMonth,
  status,
  isSandbox,
}) => {
  const navigate = useNavigate();
  const _handleClick = (_event: React.MouseEvent<HTMLElement>) => {
    navigate(buildDynamicRoute.card(id));
  };

  return (
    <TableRowWrapper
      hoverColor={cardType === "Virtual" ? "#51442a10" : "#1e1e1e10"}
    >
      {isSandbox && <PopOver />}
      <TableCell>
        <P size={"small"} emphasis>
          {cardHolder}
        </P>
      </TableCell>
      <TableCell maxWidth="200px" onClick={_handleClick}>
        <P size={"small"} display="flex" style={{ alignItems: "center" }}>
          <SVGCardMini cardType={cardType} />
          {cardNumber}
        </P>
      </TableCell>
      <TableCell maxWidth="200px" justifyContent="right">
        <P size={"small"}>${moneyFormatter(spentThisMonth / 100, false)}</P>
      </TableCell>
      <TableCell maxWidth="200px" justifyContent={"center"}>
        <P size={"small"} colorOpacity="50%">
          {cardType}
        </P>
      </TableCell>
      <TableCell maxWidth="200px" justifyContent={"center"}>
        <P size={"small"}>
          <Label color={`#7879F110`} text={status} />
        </P>
      </TableCell>
    </TableRowWrapper>
  );
};

const TableCell: React.FC<TableCellProps> = (props) => {
  return <TableCellWrapper {...props}>{props.children}</TableCellWrapper>;
};

const SVGCardMini = ({ cardType }: { cardType: CardType }) => {
  const isPhysical = cardType === "Physical";
  const isVirtual = cardType === "Virtual";

  return (
    <SVGCardMiniWrapper>
      {isPhysical && <SVGPhysicalCardMini />}
      {isVirtual && <SVGVirtualCardMini />}
    </SVGCardMiniWrapper>
  );
};

const NoDataYet: React.FC = () => (
  <NoDataYetWrapper>
    <P size="small" colorOpacity="50%" margin="auto">
      No cards yet
    </P>
  </NoDataYetWrapper>
);
