import { Grid } from "@mui/material";
import {
  MakeAPaymentButtonsWrapper,
  MakeAPaymentContainerWrapper,
} from "./style";

import { FormProvider, useForm } from "react-hook-form";
import { P } from "components/Fonts";
import { CustomButton } from "components/Buttons";
import { useState } from "react";
import { MakePaymentForm, PaymentOptionEnum } from "./types";

import {
  FlowSteps,
  FORM_INIT_VALUES,
  logicOverShowBack,
  logicOverShowContinue,
} from "./services";
import {
  BeneficiaryCompany,
  BeneficiaryIndividual,
  useGetTokenWithVerificationMutation,
  useSendPaymentInternationalTransferToCompanyMutation,
} from "services/banking-extra-api";
import { useSendPaymentMutation } from "services/banking-api";
import { useAuthBanking } from "hooks/useAuthBanking";
import countries from "resources/countries";
import { Step9 } from "./MakePaymentSteps";
import { CustomButtonVariants } from "components/Buttons/types";
import { useAuth } from "hooks/useAuth";

/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */

const createHash = (data: string) => {
  return Math.abs(
    data.split("").reduce((a, b) => ((a << 5) - a + b.charCodeAt(0)) | 0, 0)
  ).toString();
};

export default (): JSX.Element => {
  const { currentCompany } = useAuth();
  const { currentAccountId } = useAuthBanking();
  const [currentStep, setCurrentStep] = useState(0);
  const [
    getTokenWithVerification,
    { isError: isErrorVerify, error: verifyError },
  ] = useGetTokenWithVerificationMutation();

  const [sendPayment, { isError: isErrorPayment, error: paymentError }] =
    useSendPaymentMutation();

  const [sendPaymentInternationalCompany] =
    useSendPaymentInternationalTransferToCompanyMutation();

  const methods = useForm<MakePaymentForm>({ defaultValues: FORM_INIT_VALUES });

  const showBack = logicOverShowBack(currentStep);
  const showContinue = logicOverShowContinue(currentStep);

  const handleNext = async () => {
    try {
      const isValid = await methods.trigger();
      // const isValid = true; //TODO: unmock this.
      isValid && setCurrentStep((e) => e + 1);
    } catch (error: any) {
      console.log("Error", error);
    }
  };

  const handleSubmit = async (data: MakePaymentForm): Promise<void> => {
    try {
      const tokenWithVerificationResponse = await getTokenWithVerification({
        token: data.verificationToken,
        code: data.verificationCode,
        companyId: currentCompany.id,
      }).unwrap();

      const amount = data.transactionValue * 100;

      const idempotencyKey =
        "id-" +
        createHash(
          `${data.recipientName}-${data.aChRoutingNumber}-${data.accountNumber}-${amount}`
        );

      if (data.optionType === PaymentOptionEnum.ach) {
        await sendPayment({
          token: tokenWithVerificationResponse.token,
          amount,
          description: data.description,
          direction: "Credit",
          counterparty: {
            routingNumber: data.aChRoutingNumber,
            accountNumber: data.accountNumber,
            accountType: data.aChAccountType,
            name: data.recipientName,
          },
          accountId: currentAccountId,
          idempotencyKey,
        });
      } else if (data.optionType === PaymentOptionEnum.internationalTransfer) {
        const destinationCountry = countries.find(
          (item) => item.label === data.recipientCountry
        );

        let beneficiary: BeneficiaryCompany | BeneficiaryIndividual;
        if (data.intTransRecipientType === "person") {
          const name = data.recipientName.split(" ");

          beneficiary = {
            beneficiaryEntityType: "individual",
            beneficiaryAddress: data.recipientAddress1,
            beneficiaryCity: data.recipientCity,
            beneficiaryCountry: destinationCountry!.code,
            beneficiaryFirstName: `${name.shift()}`,
            beneficiaryLastName: name.join(" "),
            bicSwift: data.intTransSwiftBic,
            account_number: data.accountNumber,
          };
        } /* if (data.intTransRecipientType === 'business') */ else {
          beneficiary = {
            beneficiaryEntityType: "company",
            beneficiaryAddress: data.recipientAddress1,
            beneficiaryCity: data.recipientCity,
            beneficiaryCountry: destinationCountry!.code,
            beneficiaryCompanyName: data.recipientName,
            bicSwift: data.intTransSwiftBic,
            account_number: data.accountNumber,
          };
        }

        await sendPaymentInternationalCompany({
          beneficiary,
          token: tokenWithVerificationResponse.token,
          amount,
          description: data.description,
          accountId: currentAccountId,
          companyId: currentCompany.id,
        });
      } else if (data.optionType === PaymentOptionEnum.wire) {
        alert("Not Implemented Yet");
      }

      handleNext();
    } catch (e) {
      console.log(e);
    }
  };

  if ((isErrorPayment && paymentError) || (isErrorVerify && verifyError))
    return (
      <Grid container spacing={3} mt={5}>
        <Grid item lg={12}>
          <MakeAPaymentContainerWrapper>
            <Step9
              errors={
                (paymentError as any)?.data?.errors ||
                (verifyError as any)?.data?.errors
              }
            />
          </MakeAPaymentContainerWrapper>
        </Grid>
      </Grid>
    );

  return (
    <Grid container spacing={3} mt={5}>
      <Grid item lg={12}>
        <MakeAPaymentContainerWrapper>
          <FormProvider {...methods}>
            <form noValidate onSubmit={methods.handleSubmit(handleSubmit)}>
              {FlowSteps[currentStep].render(handleNext)}
            </form>
            <MakeAPaymentButtonsWrapper>
              {showBack && (
                <CustomButton
                  onClick={() => setCurrentStep((e) => e - 1)}
                  variant={CustomButtonVariants.link}
                >
                  <P size="small" emphasis>
                    ← Back
                  </P>
                </CustomButton>
              )}
              {!showBack && <div></div>}
              {showContinue && (
                <CustomButton
                  onClick={handleNext}
                  variant={CustomButtonVariants.black}
                  borderRadius="rounded"
                >
                  <P size="small">Continue</P>
                </CustomButton>
              )}
            </MakeAPaymentButtonsWrapper>
          </FormProvider>
        </MakeAPaymentContainerWrapper>
      </Grid>
    </Grid>
  );
};
