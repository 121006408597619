import { P } from "components/Fonts";
import { StyledLink } from "./styles";
import { CopyIcon } from "./CopyIcon";
import useCurrentUser from "graphql/hooks/useCurrentUser";

export const LinkReferral = () => {
  const { currentUser } = useCurrentUser();

  return (
    <StyledLink>
      <CopyIcon linkToCopy={currentUser?.referralData.link || ""} />
      <P size="xsmall" display="inline" margin="auto 0">
        {currentUser?.referralData.link}
      </P>
    </StyledLink>
  );
};
