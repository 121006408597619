export enum TransactionType {
  Book = "bookTransaction",
  Card = "cardTransaction",
  OriginatedACH = "originatedAchTransaction",
  ReceivedACH = "receivedAchTransaction",
}

export enum PaymentMethod {
  International = "International",
  ACH = "ACH",
  Book = "Book",
  Card = "Card Payment",
}

export enum TransactionDirection {
  Credit = "Credit",
  Debit = "Debit",
}

export enum TransactionLabel {
  Incoming = "in",
  Outgoing = "out",
}

export enum TransactionBeneficiaryType {
  Individual = "individual",
  Company = "company",
}

export enum TransactionTagsType {
  International = "international",
}

export interface TransactionTagsBase {
  beneficiaryCity: string;
  beneficiaryCountry: string;
  beneficiaryAddress: string;
  beneficiaryBicSwift: string;
  type: TransactionTagsType;
}

export interface TransactionTagsIndividual extends TransactionTagsBase {
  beneficiaryEntityType: TransactionBeneficiaryType.Individual;
  beneficiaryFirstName: string;
  beneficiaryLastName: string;
}

export interface TransactionTagsCompany extends TransactionTagsBase {
  beneficiaryEntityType: TransactionBeneficiaryType.Company;
  beneficiaryCompanyName: string;
}

export type TransactionTags =
  | TransactionTagsIndividual
  | TransactionTagsCompany;

export interface TransactionCounterparty {
  accountNumber: string;
  accountType: string;
  name: string;
  routingNumber: string;
}

export interface TransactionAttributes {
  createdAt: string;
  amount: number;
  balance: number;
  counterparty?: TransactionCounterparty;
  counterpartyRoutingNumber?: string;
  companyName?: string;
  description: string;
  direction: TransactionDirection;
  summary: string;
  tags?: TransactionTags;
}

export interface TransactionBase {
  attributes: TransactionAttributes;
  id: string;
}

export interface TransactionBook extends TransactionBase {
  type: TransactionType.Book;
}

export interface TransactionOriginatedACH extends TransactionBase {
  type: TransactionType.OriginatedACH;
}

export interface TransactionReceivedACH extends TransactionBase {
  type: TransactionType.ReceivedACH;
}

export interface TransactionCard extends TransactionBase {
  type: TransactionType.Card;
}

export type TransactionItem =
  | TransactionBook
  | TransactionOriginatedACH
  | TransactionReceivedACH
  | TransactionCard;

export interface TransactionObject {
  id: string;
  type: TransactionLabel;
  toFrom: string;
  date: string;

  paymentMethod: PaymentMethod;
  amount: number;
  status: string;
  summary: string;
  moreDetails: {
    bankName: string;
    IBAN: string;
    reference1: string;
    reason: string;
    reference2: string;
    pdfDocument: string;
  };
}
