import styled from "styled-components/macro";

type Props = {
  noShadow?: boolean;
  withBorder?: boolean;
  iconColor?: string;
  boxColor?: string;
  margin?: number;
};

export const AlertWrapper = styled.div<Props>`
  .MuiAlert-root {
    background: ${(props) => props.boxColor || "white"};
    border: ${(props) =>
      props.withBorder ? "1px solid rgba(0, 0, 0, 0.1)" : "none"};
    border-radius: 10px;
    box-shadow: ${(props) =>
      props.noShadow ? "none" : "0px 20px 50px rgba(0, 0, 0, 0.07)"};
    margin: ${(props) => `${props.margin || 0}px`};

    .MuiAlert-icon {
      color: ${(props) => props.iconColor || "#03a9f4"};
    }
  }
`;
