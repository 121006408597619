import { useEffect, useState } from "react";
import { useGetCompanyStateMutation } from "services/banking-extra-api";
import { setForm, setInit } from "state/authBankingSlice";
import { useAppDispatch } from "state/hooks";
import { LoadingComponent } from "components/Loading";
import { useInterval } from "usehooks-ts";
import { SandboxLabelWrapper } from "./styles";
import { P } from "components/Fonts";
import { AlertCard } from "components/AlertCard";
import { useAuthBanking } from "hooks/useAuthBanking";
import { useAuth } from "hooks/useAuth";

const refreshTokenIntervalInMilliseconds = 1000 * 60;

const SandboxLabel = () => {
  return (
    <SandboxLabelWrapper>
      <AlertCard
        severity="error"
        iconColor="#ef5350"
        htmlText={
          <>
            <P size="xsmall" color="#5f2120">
              Welcome to the doola banking Demo. <b>Note:</b> The information
              displayed in the demo environment is not real or processed by our
              system.
            </P>
          </>
        }
      />
    </SandboxLabelWrapper>
  );
};

const BankingGateway: React.FC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  const { currentCompany } = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useAppDispatch();
  const [getCompanyState, { data, error: stateError }] =
    useGetCompanyStateMutation();
  const { currentState } = useAuthBanking();

  useEffect(() => {
    setIsLoading(true);
    getCompanyState(currentCompany.id);
  }, [getCompanyState, currentCompany]);

  useInterval(() => {
    getCompanyState(currentCompany.id);
  }, refreshTokenIntervalInMilliseconds);

  useEffect(() => {
    if (stateError) {
      dispatch(
        setForm({
          state: "APPLICATION_MISSING",
        })
      );
    }

    if (data) {
      dispatch(setForm(data));
      setIsLoading(false);

      if (data.state === "READY") {
        dispatch(setInit(data));
      }
    }
  }, [data, dispatch, stateError]);

  if (isLoading) {
    return <LoadingComponent />;
  }

  return (
    <>
      {currentState !== "READY" && <SandboxLabel />}
      {children}
    </>
  );
};

export default BankingGateway;
