import styled from "styled-components/macro";

export const LinkAndButtomsWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`;
export const CustomLinkWrapper = styled.div`
  width: inherit;
`;
