import styled from "styled-components/macro";
import utils from "utils";

export const WrapperAddAnother = styled.div`
  margin: -25px;
  padding: 5px 25px;
  margin-top: 15px;
  border-top: 1px solid #d5d5d5;
`;

export const ItemWrapper = styled.div`
  display: flex;
  padding: 10px 0px;
  cursor: pointer;
  :hover {
    font-weight: bold;
  }
  svg {
    margin-right: 20px;
    width: 30px;
  }
`;

export const SVGThreeDotsStyled = styled.div`
  svg {
    cursor: pointer;
    position: absolute;
    right: 20px;
    top: 30px;
    z-index: 1;
    width: 20px;
    height: 5px;
    :hover {
      path {
        fill: ${utils.colors.gold};
      }
    }

    @media only screen and (max-width: ${utils.screenSize.tablet}) {
      right: 10px;
    }
  }
`;
