export enum CouponsType {
  fixed = "fixed",
  percentage = "percentage",
}

const notLimitedObj = {
  isLimited: false,
  validUntil: "",
};

export const emptyCoupon = {
  code: "",
  valid: false,
  value: 0,
  type: "",
  recurring: false,
  limitedOffer: notLimitedObj,
  stateFeeOff: false,
};

const COUPONS = {
  AMZSCOUT: {
    type: CouponsType.percentage,
    value: 5,
    recurring: true,
    limitedOffer: notLimitedObj,
    stateFeeOff: false,
  },
  DOOLAFRIDAY: {
    type: CouponsType.percentage,
    value: 10,
    recurring: true,
    limitedOffer: notLimitedObj,
    stateFeeOff: false,
  },
  STATEFEEOFF: {
    type: CouponsType.fixed,
    value: 0,
    recurring: true,
    limitedOffer: {
      isLimited: true,
      validUntil: "2022-06-04T00:00:00.000-05:00",
    },
    stateFeeOff: true,
  },
  MYONLINECA: {
    type: CouponsType.percentage,
    value: 5,
    recurring: true,
    limitedOffer: notLimitedObj,
    stateFeeOff: false,
  },
  HOTSUMMERDEAL: {
    type: CouponsType.percentage,
    value: 10,
    recurring: true,
    limitedOffer: {
      isLimited: true,
      validUntil: "2022-08-01T00:00:00.000-05:00",
    },
    stateFeeOff: false,
  },
  // "99EVERFLOWNONUSLLCOFFER": {
  //   type: CouponsType.fixed,
  //   value: 99,
  //   recurring: true,
  //   limitedOffer: notLimitedObj,
  //   stateFeeOff: false,
  // },
  // TESTCOUPON: {
  //   type: CouponsType.percentage,
  //   value: 10,
  //   recurring: true,
  //   limitedOffer: {
  //     isLimited: true,
  //     validUntil: "2022-08-22T17:00:00.000-07:00",
  //   },
  //   stateFeeOff: false,
  // },
} as {
  [name: string]: {
    type: CouponsType;
    value: number;
    recurring: boolean;
    limitedOffer: {
      isLimited: boolean;
      validUntil: string;
    };
    stateFeeOff: boolean;
  };
};

const validCoupon = (code: string) => {
  switch (code.toUpperCase()) {
    case "DOOLAFRIDAY":
      const todayIsFriday = new Date().getDay() === 5;

      return todayIsFriday;
    case "STATEFEEOFF":
      const startDate = "2022-05-30T17:00:00.000-05:00"; // -> valid after Friday 00:00 EST, May 30th.;
      const deadlineDate =
        COUPONS[code.toUpperCase() as keyof typeof COUPONS].limitedOffer
          .validUntil; // -> valid till Midnight EST on Jun 4th.;
      const now = new Date().getTime();
      const deadlineDateSec = new Date(deadlineDate).getTime();
      const startDateSec = new Date(startDate).getTime();

      return now > startDateSec && now < deadlineDateSec;
    default:
      return true;
  }
};

export const getCouponData = (
  code: string
): {
  valid: boolean;
  type: CouponsType;
  value: number;
  recurring: boolean;
  limitedOffer: {
    isLimited: boolean;
    validUntil: string;
  };
  stateFeeOff: boolean;
} => {
  const coupon = COUPONS[code.toUpperCase() as keyof typeof COUPONS];
  if (!coupon) {
    return {
      valid: false,
      value: 0,
      type: CouponsType.fixed,
      recurring: false,
      limitedOffer: notLimitedObj,
      stateFeeOff: false,
    };
  } else {
    return {
      valid: validCoupon(code),
      value: coupon.value,
      type: coupon.type,
      recurring: coupon.recurring,
      limitedOffer: coupon.limitedOffer,
      stateFeeOff: coupon.stateFeeOff,
    };
  }
};
