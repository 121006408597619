import { Outlet } from "react-router-dom";
import AuthGuard from "utils/route-guard/AuthGuard";
import BankingGateway from "views/DashboardApp/Banking/BankingGateway";
import { DashboardLayout } from "views/DashboardApp/Layout";
import {
  BankingHiddenRoutes,
  BankingSectionItems,
  TabsData,
} from "views/DashboardApp/Layout/Sidebar/data";
import Profile from "views/DashboardApp/Profile";
import { ROUTER_PATHES } from "./routes";

const MainRoutes = {
  path: "/",
  children: [
    {
      path: "/",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: ROUTER_PATHES.SETTINGS,
          element: <Profile />,
        },
        ...TabsData.map(({ route, element }) => ({
          path: route,
          element: element,
        })),
        {
          path: "/",
          element: (
            <BankingGateway>
              <Outlet />
            </BankingGateway>
          ),
          children: [
            ...BankingSectionItems.map(({ route, element }) => ({
              path: route,
              element: element,
            })),
          ],
        },
        ...BankingHiddenRoutes.map(({ route, element }) => ({
          path: route,
          element: element,
        })),
      ],
    },
  ],
};

export default MainRoutes;
