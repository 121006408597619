import styled from "styled-components/macro";
import utils from "utils";

export const StyledLink = styled.div`
  display: flex;
  justify-content: center;
  gap: 15px;
  color: ${utils.colors.grass};
  background: #fff;
  border: 1px solid #c4c4c4;
  border-radius: 6px;
  padding: 10px;
  margin: 0px 5px;

  @media only screen and (max-width: ${utils.screenSize.mobile}) {
    > div:last-child {
      max-width: 165px;
      width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
`;
