import { Theme } from "@mui/material/styles";
import { Box, Grid, Stack, useMediaQuery } from "@mui/material";
import AuthFooter from "./components/AuthFooter";
import AuthCard from "./components/AuthCard";
import { Logo } from "components/Logos";
import MainBackground from "assets/MainBackground";
import { LinkExternalDomain } from "components/LinkExternalDomain";
import { TrustPilotSVG } from "assets/TrustPilotSVG";
import YCLOGO from "assets/ycombinator.svg";
import { Outlet } from "react-router-dom";

const AuthWrapper = () => {
  const matchDownSM = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  return (
    <Box sx={{ minHeight: "100vh" }}>
      <MainBackground isBlurred />
      <Grid
        container
        direction="column"
        justifyContent="flex-end"
        sx={{
          minHeight: "100vh",
        }}
      >
        <Stack
          direction={matchDownSM ? "column" : "row"}
          justifyContent={matchDownSM ? "center" : "space-between"}
          spacing={2}
          textAlign={matchDownSM ? "center" : "inherit"}
          sx={{ ml: 3, mt: 3, mr: 3 }}
        >
          <Logo />
          <Stack
            direction={matchDownSM ? "column" : "row"}
            justifyContent={matchDownSM ? "center" : "space-between"}
            alignItems={"center"}
            spacing={2}
          >
            <img src={YCLOGO} height={50} alt="doola YC" />
            <LinkExternalDomain to="https://www.trustpilot.com/review/doola.com">
              <TrustPilotSVG width={140} />
            </LinkExternalDomain>
          </Stack>
        </Stack>
        <Grid item xs={12}>
          <Grid
            item
            xs={12}
            container
            justifyContent="center"
            alignItems="center"
            sx={{
              minHeight: {
                xs: "calc(100vh - 210px)",
                sm: "calc(100vh - 134px)",
                md: "calc(100vh - 130px)",
              },
            }}
          >
            <Grid item>
              <AuthCard>
                <Outlet />
              </AuthCard>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sx={{ m: 3, mt: 1 }}>
          <AuthFooter />
        </Grid>
      </Grid>
    </Box>
  );
};
export default AuthWrapper;
