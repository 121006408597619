import styled from "styled-components/macro";

export const MakeAPaymentWrapper = styled.div`
  display: flex;
  align-items: center;

  .content-text {
    white-space: nowrap;
  }

  button {
    padding: 5px 20px;
  }
`;
