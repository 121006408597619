import styled from "styled-components/macro";
import utils from "utils";
import { Close } from "@mui/icons-material";
import { HoverPointer } from "assets/GlobalStyled";

export const ApplicationStatusModalWrapper = styled.div`
  position: relative;
  padding: 30px 50px;
  text-align: center;
`;

export const StyledCloseIcon = styled(Close)`
  position: absolute;
  right: 20px;
  top: 20px;
  width: 30px;
  height: 30px;
  fill: #f1f1f1;
  ${HoverPointer}

  &:hover,
  &:focus {
    fill: ${utils.colors.grass};
  }
`;
