import { Grid } from "@mui/material";
import { CustomButton } from "components/Buttons";
import { CustomCard } from "components/Cards";
import { P } from "components/Fonts";
import utils from "utils";
import { SVGCheck } from "../../SharedComponents/SVGs";
import {
  BackToCardsWrapper,
  CardProfileWrapper,
  ProfileTitleWrapper,
  SVGCheckWrapper,
} from "./styles";
import CardDetails from "./CardDetails";
import {
  BillingSectionProps,
  CardProfileContainerProps,
  CardProfileProps,
  ProfileTitleProps,
} from "./types";
import { useNavigate, useParams } from "react-router-dom";
import {
  buildCardData,
  buildCardDataMocked,
  getCountryNameByCode,
} from "./services";
import { useGetCardByIdMutation } from "services/banking-api";
import { useEffect } from "react";
import { useAuthBanking } from "hooks/useAuthBanking";
import { LoadingComponent } from "components/Loading";
import { CustomButtonVariants } from "components/Buttons/types";
import { BANKING_ROUTES } from "routes/routes";

/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */

// const MOCK_DATA = SAND_BOX_DATA.cardsView.profileCard;

const CardProfile: React.FC<CardProfileProps> = ({ viewAs, data }) => {
  const navigate = useNavigate();

  const _handleClick = () => {
    navigate(BANKING_ROUTES.cards);
  };

  const isViewAsCreated = viewAs === "created";
  const isViewAsProfile = viewAs === "profile";

  if (!data) return <></>;

  return (
    <CardProfileWrapper>
      <CustomCard borderRadius="strong" shadow="strong" padding={1}>
        <Grid container mb={2} display="block">
          <Grid item xs={12} sm={12} textAlign="center">
            {isViewAsCreated && <CreatedAtTitle />}
            {isViewAsProfile && <ProfileTitle name={data.details.name} />}
            <CardDetails {...data.details} />
          </Grid>
          <Grid item xs={12} sm={12}>
            {/* {isViewAsProfile && <SpentSection {...data.spent} />} */}
          </Grid>
          <Grid item xs={12} sm={12}>
            <BillingSection address={data.address} />
          </Grid>
        </Grid>
      </CustomCard>
      <BackToCardsWrapper>
        <CustomButton
          onClick={_handleClick}
          variant={CustomButtonVariants.black}
          borderRadius="rounded"
        >
          <P size="small">View All Cards →</P>
        </CustomButton>
      </BackToCardsWrapper>
    </CardProfileWrapper>
  );
};

const CreatedAtTitle: React.FC = () => (
  <>
    <SVGCheckWrapper>
      <SVGCheck color={utils.colors.grass} />
    </SVGCheckWrapper>
    <P size="large" emphasis margin="20px 0px 10px 0px">
      You have created a new card
    </P>
    <P size="small" colorOpacity="50%" margin="10px 0px 30px 0px">
      This card is activated and ready to use.
    </P>
  </>
);

const ProfileTitle: React.FC<ProfileTitleProps> = ({ name }) => (
  <>
    <ProfileTitleWrapper>
      <P size="large" emphasis>
        Office Card ·
      </P>
      <P size="large" colorOpacity="75%">
        &nbsp;Virtual Debit
      </P>
    </ProfileTitleWrapper>
    <P
      size="small"
      colorOpacity="50%"
      margin="0px 0px 20px 0px"
      style={{ textAlign: "left" }}
    >
      {name}
    </P>
  </>
);

const BillingSection: React.FC<BillingSectionProps> = ({ address }) => {
  const { address1, address2, state, zip, country: countryCode } = address;

  return (
    <>
      <P
        size="medium"
        emphasis
        margin="20px 0px 10px 0"
        style={{
          borderTop: `1px solid ${utils.colors.charcoal}15`,
          paddingTop: "30px",
        }}
      >
        Billing address
      </P>
      <P size="small" colorOpacity="75%">
        {address1}
      </P>
      <P size="small" colorOpacity="75%">
        {address2}
      </P>
      <P size="small" colorOpacity="75%">
        {`${state} ${zip}`}
      </P>
      <P size="small" colorOpacity="75%">
        {getCountryNameByCode(countryCode)}
      </P>
    </>
  );
};

// const SpentSection: React.FC<SpendSectionProps> = ({
//   spentThisMonth,
//   available,
//   limit,
// }) => {
//   return (
//     <>
//       <SpentRowWrapper>
//         <P size="xsmall">${moneyFormatter(spentThisMonth, false)}</P>
//         <P size="xsmall" colorOpacity="50%">
//           &nbsp;spent this month
//         </P>
//       </SpentRowWrapper>
//       <SpentRowWrapper>
//         <P size="xsmall">${moneyFormatter(available, false)}</P>
//         <P size="xsmall" colorOpacity="50%">
//           &nbsp;{`available · ${moneyFormatter(limit, false)} limit`}
//         </P>
//       </SpentRowWrapper>
//     </>
//   );
// };

const CardProfileContainer: React.FC<CardProfileContainerProps> = ({
  viewAs,
}) => {
  const { currentAccountId: accountId } = useAuthBanking();
  const { id = "" } = useParams();

  const [getCardById, { data: cardData }] = useGetCardByIdMutation();

  useEffect(() => {
    getCardById({ cardId: id });
  }, [getCardById, id]);

  if (!accountId) {
    const data = buildCardDataMocked(`${id}`);
    return <CardProfile data={data} viewAs={viewAs} />;
  }

  if (!cardData) {
    return <LoadingComponent />;
  }

  const data = buildCardData(cardData);

  return <CardProfile data={data} viewAs={viewAs} />;
};

export default CardProfileContainer;
