import MenuBookIcon from "@mui/icons-material/MenuBook";
import DescriptionIcon from "@mui/icons-material/Description";
import SupportIcon from "@mui/icons-material/Support";

export const ResourcesData = {
  title: "Resources",
  subtitle: "",
  resources: [
    {
      icon: <MenuBookIcon />,
      title: "Doola Guide to Starting a Real Business",
      description:
        "We have put together a primer explaning accounting, taxes, employment, and more.",
      link: "https://www.canva.com/design/DAErUTGp9x0/SThk24jloNJgDpsC68o9NQ/view?utm_content=DA%5B%E2%80%A6%E2%80%A6tm_campaign=designshare&utm_medium=link&utm_source=sharebutton#1",
    },
    {
      icon: <DescriptionIcon />,
      title: "Documentation",
      description:
        "Check out our help center which can answer many questions you might have your mind.",
      link: "http://help.doola.com/en",
    },
    {
      icon: <SupportIcon />,
      title: "Help & Support",
      description:
        "Still have questions? No worries! We're here to help, one click away.",
      link: "link to open the intercom here",
    },
  ],
};
