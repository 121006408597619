import { Statement } from "models/Requests";

export const buildStatementData = (BEData: Statement[]) => {
  return BEData.map(({ id, period }) => ({
    id,
    period: period,
  }));
};

type MonthNumbers =
  | "01"
  | "02"
  | "03"
  | "04"
  | "05"
  | "06"
  | "07"
  | "08"
  | "09"
  | "10"
  | "11"
  | "12";

const getMonth = (monthNumber: MonthNumbers) => {
  switch (monthNumber) {
    case "01":
      return "Jan";
    case "02":
      return "Feb";
    case "03":
      return "Mar";
    case "04":
      return "Apr";
    case "05":
      return "May";
    case "06":
      return "Jun";
    case "07":
      return "Jul";
    case "08":
      return "Aug";
    case "09":
      return "Sep";
    case "10":
      return "Oct";
    case "11":
      return "Nov";
    case "12":
      return "Dec";
  }
};

var lastDayMonth = (year: string, monthNumber: MonthNumbers) => {
  return new Date(parseInt(year), parseInt(monthNumber), 0).getDate();
};

export const formatPeriod = (period: string) => {
  //period comes in this format:YYYY-MM i.e: 2020-01
  const [year, month] = period.split("-");

  const MonthText = getMonth(month as MonthNumbers);

  return `${MonthText} 1 - ${MonthText} ${lastDayMonth(
    year,
    month as MonthNumbers
  )}, ${year}`;
};
