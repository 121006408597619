/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */

import { Skeleton, Tooltip } from "@mui/material";
import { P } from "components/Fonts";
import config from "config";
import { useAuth } from "hooks/useAuth";
import React from "react";
import { useState } from "react";
import {
  useGetTokenWithVerificationMutation,
  useGetVerificationMutation,
} from "services/banking-extra-api";
import TwoFAModal from "../../../2FAmodal";
import { CardNumberWrapper, CCVExpWrapper, CVCWrapper } from "./styles";

type Props = {
  cardId: string;
  cardNumber: string;
  expiration: string;
  cvc: string;
};

declare global {
  interface Window {
    VGSShow: any;
  }
}

const SensitiveCardData: React.FC<Props> = ({
  cardId,
  cardNumber,
  expiration,
  cvc,
}) => {
  const { currentCompany } = useAuth();
  const [sensitiveDataShown, setSensitiveDataShown] = useState(false);
  const [requestMFA, { data: mFAData, isLoading: requestingMFA }] =
    useGetVerificationMutation();

  const [getTokenWithVerification, { isLoading: loadingGetToken }] =
    useGetTokenWithVerificationMutation();

  const revealData = async (twoFAValue: string) => {
    if (!mFAData || !twoFAValue) {
      return;
    }

    const tokenWithVerificationResponse = await getTokenWithVerification({
      token: mFAData.verificationToken,
      code: twoFAValue,
      companyId: currentCompany.id,
    }).unwrap();

    const { token } = tokenWithVerificationResponse;

    const show = window.VGSShow.create(config.banking.veryGoodSecurity);

    const iFrameStyles = {
      fontSize: "16px",
      display: "block",
      opacity: "75%",
      color: "white",
    };

    const cvv2iframe = show.request({
      name: "data-text",
      method: "GET",
      path: "/cards/" + cardId + "/secure-data/cvv2",
      headers: {
        Authorization: "Bearer " + token,
      },
      htmlWrapper: "text",
      jsonPathSelector: "data.attributes.cvv2",
    });

    cvv2iframe.render(`#cvv2`, { ...iFrameStyles, paddingTop: "3px" });

    const cardNumberIframe = show.request({
      name: "data-text",
      method: "GET",
      path: "/cards/" + cardId + "/secure-data/pan",
      headers: {
        Authorization: "Bearer " + token,
      },
      htmlWrapper: "text",
      serializers: [
        show.SERIALIZERS.replace(
          "(\\d{4})(\\d{4})(\\d{4})(\\d{4})",
          "$1 $2 $3 $4"
        ),
      ],
      jsonPathSelector: "data.attributes.pan",
    });
    cardNumberIframe.render(`#cardNumber`, iFrameStyles);
    setSensitiveDataShown(true);
  };

  const _onClick = () => requestMFA(currentCompany.id);

  return (
    <>
      <CardNumberWrapper id="cardNumber">
        {!sensitiveDataShown && (
          <Tooltip title="Click to reveal" placement="top">
            {requestingMFA || loadingGetToken ? (
              <LoadingSkeletons type="card-number" />
            ) : (
              <span onClick={_onClick}>{cardNumber}</span>
            )}
          </Tooltip>
        )}
      </CardNumberWrapper>
      <CCVExpWrapper>
        <P size="small" colorOpacity="75%">
          {expiration}
        </P>
        <P size="small" colorOpacity="75%">
          &nbsp;{`CVC`}&nbsp;
        </P>
        <span id="cvv2">
          {!sensitiveDataShown && (
            <Tooltip title="Click to reveal" placement="bottom">
              {requestingMFA || loadingGetToken ? (
                <LoadingSkeletons type="cvc" />
              ) : (
                <CVCWrapper onClick={_onClick}>{`${cvc}`}</CVCWrapper>
              )}
            </Tooltip>
          )}
        </span>
      </CCVExpWrapper>
      {mFAData && <TwoFAModal onVerify={revealData} />}
    </>
  );
};

type LoadingSkeletonsProps = {
  type: "card-number" | "cvc";
};
const LoadingSkeletons: React.FC<LoadingSkeletonsProps> = ({ type }) => {
  const isCardNumber = type === "card-number";
  return (
    <Skeleton
      variant="text"
      style={{ background: "#6b6b6b", width: isCardNumber ? "150px" : "30px" }}
    />
  );
};

export default SensitiveCardData;
