import Typography from "@mui/material/Typography";
import DescriptionIcon from "@mui/icons-material/Description";
import Grid from "@mui/material/Grid";
import { Document, ICompanyDocumentsData } from "models/Requests";
import { LoadingComponent } from "components/Loading";
import { DocumentCard } from "./DocumentCard";
import { LinkExternalDomain } from "components/LinkExternalDomain";
import { P } from "components/Fonts";
import { useAuth } from "hooks/useAuth";
import { useQuery } from "@apollo/client";
import { COMPANY_DOCUMENTS } from "graphql/queries/CompanyDocuments";

const Documents = () => {
  const { currentCompany } = useAuth();
  const { data, loading } = useQuery<ICompanyDocumentsData>(COMPANY_DOCUMENTS, {
    variables: { companyId: currentCompany.id },
  });

  if (loading) return <LoadingComponent />;

  if (data?.companyDocuments.items.length) {
    return (
      <Grid container spacing={3}>
        <Grid item lg={12} mb={4}>
          <P size="large" emphasis>
            Documents
          </P>
          <P size="medium" colorOpacity="50%">
            You have {data.companyDocuments.items.length} documents
          </P>
        </Grid>
        {data.companyDocuments.items.map((file: Document, index: number) => (
          <Grid item lg={3} xs={12} key={index}>
            <LinkExternalDomain
              to={`https://drive.google.com/file/d/${file.id}/view?usp=sharing`}
            >
              <DocumentCard
                name={file.name}
                type={file.mimeType}
                img={file.img}
              />
            </LinkExternalDomain>
          </Grid>
        ))}
      </Grid>
    );
  }

  return (
    <Grid
      container
      spacing={2}
      m={2}
      direction="column"
      alignItems="center"
      justifyContent="center"
      sx={{ height: "calc(100vh - 118px)" }}
    >
      <div style={{ opacity: 0.75 }}>
        <Typography align="center" variant="h2" component="h1">
          <DescriptionIcon style={{ fontSize: 40 }} />
        </Typography>
        <Typography align="center" variant="h6">
          No documents yet
        </Typography>
        <br />
        <Typography align="center" variant="body2">
          There are currently no documents associated with your account.
          <br />
          As documents are created, they will start to be available here.
        </Typography>
      </div>
    </Grid>
  );
};

export default Documents;
