import { CustomButton } from "components/Buttons";
import { CustomButtonVariants } from "components/Buttons/types";
import { P } from "components/Fonts";
import { useNavigate } from "react-router-dom";
import { BANKING_ROUTES } from "routes/routes";
import { SVGPlaneIcon } from "../SVGs";

export const MakeAPayment: React.FC = () => {
  const navigate = useNavigate();

  return (
    <CustomButton
      onClick={() => navigate(BANKING_ROUTES.payments)}
      variant={CustomButtonVariants.black}
      borderRadius="rounded"
    >
      <div style={{ display: "flex" }}>
        <P size="small" margin="0px 15px 0px 0px">
          Make a Payment
        </P>
        <SVGPlaneIcon />
      </div>
    </CustomButton>
  );
};
