import { useCallback, useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import { LoadingComponent } from "components/Loading";
import { RateCard } from "./RateCard";
import { P } from "components/Fonts";
import { InviteSomeone } from "./InviteSomeone";
import { LinkRouter } from "components/LinkRouter";
import { CompanyService, Service } from "models/ServiceModel";
import { YourPlan } from "components/YourPlan";
import { capitalizedSentence } from "utils/formatters";
import { removeIds } from "state/apiSlice";
import { resetStep, resetForm } from "state/formSlice";
import { useAppDispatch } from "state/hooks";
import { useLocation, useNavigate } from "react-router-dom";
import { TriggerConfetti } from "components/Animations/Confetti";
import { useAuth } from "hooks/useAuth";
import { DashboardProps } from "./types";
import { ROUTER_PATHES } from "routes/routes";
import TaxPopup from "./TaxPopup";
import { canPurchaseTaxComplianceBundle } from "components/YourPlan/services";
import { useGenerateTaxComplianceBundleLinkMutation } from "services/payments-api";
import { FAQDashboard } from "components/FAQs";
import { FAQ_DATA } from "components/FAQs/data";
import MissingFormPopup from "./MissingFormPopup";
import {
  BellOutlined,
  FileTextOutlined,
  ProfileOutlined,
} from "@ant-design/icons";
import { Banner } from "./Banner";
import DashboardReferralImage from "./bannerimageanuli.png";
import TaxWomanImage from "./bannerimageelizabeth.png";
import { CustomButtonVariants } from "components/Buttons/types";
import { useTheme } from "@mui/material";
import useCurrentUser from "graphql/hooks/useCurrentUser";
import { useQuery } from "@apollo/client";
import { COMPANY_DOCUMENTS } from "graphql/queries/CompanyDocuments";
import { ICompanyDocumentsData } from "models/Requests";

const Dashboard: React.FC<DashboardProps> = ({ user, documents, company }) => {
  const queryParams = new URLSearchParams(useLocation().search);
  const animateConfetti = queryParams.get("origin") === "success";
  const [showTaxPopup, setShowTaxPopup] = useState(false);
  // const [showMailRoomBanner, setShowMailRoomBanner] = useState(false);
  const [getLink] = useGenerateTaxComplianceBundleLinkMutation();
  const navigate = useNavigate();
  const theme = useTheme();

  const clickUpsellingBanner = useCallback(() => {
    const generateLink = async () => {
      const link = await getLink(company.id).unwrap();
      return link.url;
    };

    generateLink()
      .then((url) => {
        window.open(
          `${url}?client_reference_id=${company.id}&prefilled_email=${user.email}`
        );
      })
      .catch(console.log);
  }, [company, user, getLink]);

  useEffect(() => {
    // if (isServiceInactive(company.services, ServiceNames.mailRoom)) {
    //   setShowMailRoomBanner(true);
    // }

    if (canPurchaseTaxComplianceBundle(company)) {
      setShowTaxPopup(true);
    }
  }, [company]);

  return (
    <>
      <Grid container spacing={3}>
        {animateConfetti && <TriggerConfetti />}
        <Grid item lg={12}>
          <P size="medium">Welcome back,</P>
          <P size="large" emphasis={true}>
            {capitalizedSentence(user.fullName)}
          </P>
        </Grid>

        <Grid item md={12} lg={4}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <YourPlan services={company.services} />
            </Grid>
            <Grid item md={12}>
              <InviteSomeone />
            </Grid>
            {/* <Grid item md={12}>
              <Resources />
            </Grid> */}
          </Grid>
        </Grid>
        <Grid
          container
          item
          lg={7}
          spacing={2}
          style={{ alignContent: "baseline" }}
        >
          <Grid item lg={4} xs={12}>
            <LinkRouter to={ROUTER_PATHES.SERVICES}>
              <RateCard
                icon={<ProfileOutlined style={{ fontSize: "24px" }} />}
                title="Services"
                number={calculateServicesNumber(company.services)}
              />
            </LinkRouter>
          </Grid>
          <Grid item lg={4} xs={12}>
            <LinkRouter to={ROUTER_PATHES.DOCUMENTS}>
              <RateCard
                icon={<FileTextOutlined style={{ fontSize: "24px" }} />}
                title="Documents"
                number={documents.length}
              />
            </LinkRouter>
          </Grid>
          <Grid item lg={4} xs={12}>
            <RateCard
              icon={<BellOutlined style={{ fontSize: "24px" }} />}
              title="Notifications"
              number="0"
            />
          </Grid>

          {/* {showMailRoomBanner && (
            <Grid item xs={12}>
              <Banner
                textColor="#fff"
                bgColor={theme.palette.secondary.dark}
                imageSrc={MailWomanImage}
                title="Got US business mail?"
                text="View and forward your US business mail to any address, anytime with doola Mail."
                buttonText="Get doola Mail"
                buttonType={CustomButtonVariants.inversedBlack}
                onClick={() => navigate(ROUTER_PATHES.MAIL)}
              />
            </Grid>
          )} */}

          {showTaxPopup && (
            <Grid item xs={12}>
              <Banner
                textColor={theme.palette.secondary.dark}
                bgColor="#E1F5FE"
                imageSrc={TaxWomanImage}
                title="Prepare now! Avoid up to $25,000 in IRS penalties"
                text="After your company formation, you need to complete your tax season
                  filings and state filings. Get covered now and stay stress-free."
                buttonText="Get Tax & Compliance"
                buttonType={CustomButtonVariants.black}
                onClick={clickUpsellingBanner}
              />
            </Grid>
          )}

          {/* {!showMailRoomBanner && !showTaxPopup && ( */}
          {!showTaxPopup && (
            <Grid item xs={12}>
              <Banner
                textColor={theme.palette.secondary.dark}
                bgColor="#FFFDE7"
                imageSrc={DashboardReferralImage}
                title="Want some extra money?"
                text="Refer a friend and earn a 15% commission for every successful
referral!"
                buttonText="View Referral Program"
                buttonType={CustomButtonVariants.black}
                onClick={() => navigate(ROUTER_PATHES.REFERRALS)}
              />
            </Grid>
          )}

          <Grid item xs={12}>
            <FAQDashboard faqs={FAQ_DATA} />
          </Grid>
        </Grid>
      </Grid>
      {showTaxPopup && <TaxPopup onClick={clickUpsellingBanner} />}
    </>
  );
};

const DashboardContainer = () => {
  const { currentCompany } = useAuth();
  const { currentUser } = useCurrentUser();
  const dispatch = useAppDispatch();
  const { data, loading } = useQuery<ICompanyDocumentsData>(COMPANY_DOCUMENTS, {
    variables: { companyId: currentCompany.id },
  });

  useEffect(() => {
    dispatch(resetStep());
    dispatch(resetForm());
    dispatch(removeIds());
  }, [dispatch]);

  if (loading || !currentUser) return <LoadingComponent />;

  return (
    <>
      <Dashboard
        user={currentUser}
        company={currentCompany}
        documents={data?.companyDocuments.items || []}
      />
      <MissingFormPopup user={currentUser} company={currentCompany} />
    </>
  );
};

export default DashboardContainer;

const calculateServicesNumber = (services: CompanyService[]) => {
  const serviceObj = new Service(services);
  const planCount = serviceObj.hasAPlan() ? 4 : 0; // 4 steps has the current plan.
  return serviceObj.addonsServices().length + planCount;
};
