import { LoadingComponent } from "components/Loading";
import Grid from "@mui/material/Grid";
import { useEffect } from "react";
import { MiniCard } from "./MiniCard";
import { P } from "components/Fonts";
import { IntercomTriggerWrapper } from "components/IntercomTriggerWrapper";
import utils from "utils";
import { useGetRewardsListMutation } from "services/make";

const Rewards: React.FC = () => {
  const [getRewardsList, { data: rewardsData }] = useGetRewardsListMutation();

  useEffect(() => {
    getRewardsList({});
  }, [getRewardsList]);

  if (!rewardsData) {
    return <LoadingComponent />;
  }

  return (
    <Grid container spacing={3}>
      <Grid item lg={12}>
        <P size="large" emphasis>
          Rewards
        </P>
        <P size="medium" colorOpacity="50%">
          Your doola membership has exclusive benefits and discounts
        </P>
      </Grid>
      <Grid item lg={9} mb={5}>
        <P size="small">
          As a doola customer, you get exclusive benefits and discounts on
          services that help you run and grow your company. doola’s partner
          offers are worth over $50,000 in value and can be redeemed at any
          time. Please contact us{" "}
          <IntercomTriggerWrapper>
            <span style={{ color: utils.colors.grass }}>via live chat</span>
          </IntercomTriggerWrapper>{" "}
          to get instructions on how to redeem.
        </P>
      </Grid>
      <Grid container spacing={4}>
        {rewardsData.records.map((reward, index) => (
          <Grid key={index} item lg={3} xs={12}>
            <MiniCard
              name={reward.fields.Name}
              body={reward.fields.Benefit}
              url={reward.fields["Company URL"]}
            />
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};
export default Rewards;
