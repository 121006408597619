import {
  Wrapper,
  DiscoverWrapper,
  NeedGuidanceWrapper,
  IconWrapper,
} from "./styles";

import { P } from "components/Fonts";
import SupportIcon from "@mui/icons-material/Support";
import { IntercomTriggerWrapper } from "components/IntercomTriggerWrapper";

export default function Guidance() {
  return (
    <Wrapper>
      <IconWrapper>
        <SupportIcon />
      </IconWrapper>
      <NeedGuidanceWrapper>
        <P size="small" emphasis>
          Need some guidance?
        </P>
      </NeedGuidanceWrapper>

      <IntercomTriggerWrapper>
        <DiscoverWrapper>
          <P size="small" emphasis>
            Live Chat With Us
          </P>
        </DiscoverWrapper>
      </IntercomTriggerWrapper>
    </Wrapper>
  );
}
