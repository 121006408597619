import styled, { css } from "styled-components/macro";
import utils from "utils";
import { CustomButtonProps, CustomButtonVariants } from "./types";

export const ButtonWrapper = styled.button<CustomButtonProps>`
  position: relative;
  border: none;
  border-radius: ${({ borderRadius }) =>
    borderRadius === "rounded" ? "26px" : "6px"};
  padding: 10px ${utils.constants.units.main * 2}px;
  opacity: ${({ $loading }) => ($loading ? "50%" : "100%")};

  text-align: center;
  margin-top: auto;
  margin-bottom: auto;
  cursor: pointer;
  width: ${({ width }) => width ?? "fit-content"};
  ${({ variant }) => additionalStyles(variant)};
  transition: all 0.3s ease-out;

  &:hover {
    ${({ variant }) =>
      variant !== CustomButtonVariants.link &&
      css`
        box-shadow: 3px 3px 2px rgb(0 0 0 / 10%);
      `}
    ${({ variant }) =>
      variant === CustomButtonVariants.black &&
      css`
        background: #595959;
      `}
  }

  ${({ disabled }) =>
    disabled &&
    css`
      color: #d9d9d9;
      background: #f5f5f5;
      border: 1px solid #d9d9d9;
      box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
      cursor: default;
      pointer-events: none;

      &:hover {
        box-shadow: none;
      }
    `}
`;

function additionalStyles(variant: CustomButtonVariants) {
  switch (variant) {
    case CustomButtonVariants.primary:
      return css`
        background-color: ${utils.colors.grass};
        color: white;
      `;
    case CustomButtonVariants.inversedPrimary:
      return css`
        background-color: white;
        color: ${utils.colors.grass};
      `;
    case CustomButtonVariants.inversedPink:
      return css`
        background-color: white;
        color: ${utils.colors.neonPink};
      `;
    case CustomButtonVariants.black:
      return css`
        background-color: ${utils.colors.charcoal};
        color: white;
      `;
    case CustomButtonVariants.inversedBlack:
      return css`
        background-color: white;
        color: ${utils.colors.charcoal};
        border: 1px solid #d9d9d9;

        &:hover {
          background-color: ${utils.colors.charcoal};
          color: white;
        }
      `;
    case CustomButtonVariants.link:
      return css`
        background-color: transparent;
        color: black;
        padding-left: 0px;
        padding-right: 0px;
      `;
    case CustomButtonVariants.neonPink:
      return css`
        background-color: ${utils.colors.neonPink};
        color: white;
      `;
    case CustomButtonVariants.green:
      return css`
        background-color: ${utils.colors.green};
        color: white;
      `;
  }
}

export const StyledButtonLoading = styled.span`
  position: absolute;
  left: 15px;
  top: 50%;
  display: flex;
  transform: translateY(-50%);

  > span {
    height: 20px !important;
    width: 20px !important;
  }
`;
