import { CustomCard } from "components/Cards";
import { FunctionComponent } from "react";
import { P } from "components/Fonts";
import { ImageWrapper, CustomWrapper } from "./styles";
import { S3_REWARDS_IMAGES_BUCKET_URL } from "services/make";
import { LinkExternalDomain } from "components/LinkExternalDomain";

type Props = {
  name: string;
  body: string;
  url: string;
};

export const MiniCard: FunctionComponent<Props> = ({ name, body, url }) => {
  const logoURL = `${S3_REWARDS_IMAGES_BUCKET_URL}${formatNameImage(name)}.png`;
  return (
    <LinkExternalDomain to={url}>
      <CustomCard styles={CustomWrapper} shadow="xstrong" borderRadius="strong">
        <ImageWrapper>
          <img alt={`Img-${name}`} src={logoURL} />
        </ImageWrapper>
        <br />
        <P size="small" emphasis={true}>
          {name}
        </P>
        <P size="xsmall">{body}</P>
      </CustomCard>
    </LinkExternalDomain>
  );
};

const formatNameImage = (companyName: string): string =>
  companyName.replaceAll(" ", "_");
