import config from "config";
import TagManager from "react-gtm-module";
import {
  GA_VIDEO_CATEGORY_EVENTS,
  GA_VIDEO_CATEGORIES,
  TP_GA4_CLICK_EVENT_NAMES,
  GA4_CUSTOM_EVENT_DATA,
} from "resources/googleAnalytics";
import { TrackerGA4 } from "./GA4tracking";
import { ICurrentUser } from "models/CustomerModel";
import PostHog from "posthog-js";

interface TransactionData {
  transactionId: string; //(Required)	Unique transaction identifier
  transactionAffiliation?: string; // (Optional)	Partner or store
  transactionTotal: number; //(Required)	Total value of the transaction
  transactionShipping?: number; // (Optional)	Shipping charge for the transaction
  transactionTax?: number; // (Optional) Tax amount for the transaction
  transactionProducts?: ProductData[]; // (Optional)	List of items purchased in the transaction	array of product objects
  conversionValue: number;
}

interface ProductData {
  name: string; // (Required)	Product name
  sku: string; // (Required)	Product SKU
  category?: string; // (Optional)	Product category
  price: number; // (Required)	Unit price	numeric
  quantity: number; // (Required)	Number of items	numeric
}

interface DataLayerData {
  event?: string;
}

const GA4Tracker = TrackerGA4.getInstance();

export class Tracker {
  public trackerId: string;
  private static instance: Tracker;
  private constructor() {
    this.trackerId = Math.floor(Math.random() * 10000) + "";
    TagManager.initialize(config.GTM);
  }
  public static getInstance(): Tracker {
    if (!Tracker.instance) {
      Tracker.instance = new Tracker();
    }
    return Tracker.instance;
  }

  public completeRegistration = (email: string) => {
    try {
      GA4Tracker.enteredEmail();
    } catch (error) {
      console.error(error);
    }
    try {
      if ((window as any).fbq) {
        (window as any).fbq("track", "Complete Registation", {
          value: email,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  public transactionComplete = (data: TransactionData) => {
    const transactionData: TransactionData & DataLayerData = {
      ...data,
      event: "purchase",
    };
    TagManager.dataLayer({
      dataLayer: transactionData,
    });

    try {
      if ((window as any).fbq) {
        (window as any).fbq("track", "Purchase", {
          currency: "USD",
          value: data.transactionTotal,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };
  public productData = (data: ProductData) => {
    const productData: ProductData = {
      ...data,
    };
    TagManager.dataLayer({
      dataLayer: productData,
    });
  };
  public trackPageView = (path: string) => {
    TagManager.dataLayer({
      dataLayer: {
        event: "pageview",
      },
    });
  };

  public trackGA4Custom = (
    event: TP_GA4_CLICK_EVENT_NAMES,
    data: GA4_CUSTOM_EVENT_DATA
  ) => {
    TagManager.dataLayer({
      dataLayer: {
        event: event,
        ...data,
      },
    });
  };

  public trackVideoGA = (
    gaLabel: string,
    gaValue: number,
    gaAction: GA_VIDEO_CATEGORY_EVENTS,
    gaCategoryName: GA_VIDEO_CATEGORIES
  ) => {
    TagManager.dataLayer({
      dataLayer: {
        event: "video-tracker",
        GAAction: gaAction,
        GACategoryName: gaCategoryName,
        GALabel: gaLabel,
        GAValue: gaValue,
      },
    });
  };

  public trackEverflowConversion = (
    transactionId: string,
    amount: number,
    email: string,
    offerId: string
  ) => {
    console.log("Trancking conversion", amount, offerId, transactionId, email);

    TagManager.dataLayer({
      dataLayer: {
        event: "everflow-conversion",
        EFOfferId: offerId,
        EFAmount: amount,
        EFTransactionId: transactionId,
        EFEmail: email,
      },
    });
  };

  public trackProfitWell = (email?: string) => {
    const dataToSet = email
      ? { event: "start_pw", pw_user_email: email }
      : { event: "start_pw" };

    TagManager.dataLayer({
      dataLayer: dataToSet,
    });
  };

  public triggerUpdateCustomerIo = (
    customerId: string,
    email: string,
    everflowPartnerName: "TRUIC-SIGNUP" | "LLCU-SIGNUP"
  ) => {
    const dataToSet = {
      event: "update_customer_io_partner",
      userId: customerId, // Optional: it's needed the ID or the email. https://customer.io/docs/javascript-quick-start/
      email: email,
      everflowPartnerName: everflowPartnerName,
    };

    TagManager.dataLayer({
      dataLayer: dataToSet,
    });
  };

  public identifyFullStoryUser = async (user: ICurrentUser) => {
    if ((window as any).FS) {
      (window as any).FS.identify(user.id, {
        email: user.email,
        displayName: user.fullName,
      });
    }
  };

  public identifyPostHogUser = async (user: ICurrentUser) => {
    const companyId = user.companyIds.find(Boolean);
    await PostHog.identify(companyId, {
      email: user.email,
      fullName: user.fullName,
    });
  };

  public tagUser = async (email: string) => {
    await TagManager.dataLayer({
      dataLayer: {
        event: "login",
        userId: email,
      },
    });
  };

  public getExperimentVariant = (
    experimentName: PostHogExperiment,
    callback?: (variant: string) => void
  ) => {
    const posthogExperimentName = mapPosthogExperiments[experimentName];
    getPosthogVariantAsync(posthogExperimentName, callback, 0);
  };

  public setOptimizerVariant = (
    callback: (variant: string | undefined) => void
  ) => {
    const implementExperimentA = (value: any) =>
      console.log("🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀 ~ variant.value", value);

    const dataToSet = {
      event: "optimize.callback",
      name: config.GoogleOptimize.experimentId,
      callback: implementExperimentA,
    };

    getTheOptimizerVariant(callback, 0);

    TagManager.dataLayer({
      dataLayer: dataToSet,
    });

    const dataToSetTest = {
      callback: (value: any, name: any) =>
        console.log("Experiment with ID: " + name + " is on variant: " + value),
      event: "optimize.callback",
    };

    TagManager.dataLayer({
      dataLayer: dataToSetTest,
    });
  };
}

declare global {
  interface Window {
    google_optimize: any;
  }
}

const timePeriod = 500;
const maxWaitingTime = 2500;

const getTheOptimizerVariant = (
  callback: (variant: string | undefined) => void,
  count: number
) => {
  const newPricingVariant = "1";
  callback(newPricingVariant);
  return;
  // if (window.google_optimize) {
  //   const variant = window.google_optimize.get(
  //     config.GoogleOptimize.experimentId
  //   );
  //   console.log("🚀 variant", variant);
  //   callback(variant);
  // } else {
  //   if (count * timePeriod > maxWaitingTime) {
  //     console.log("fallback");
  //     console.error(
  //       `Fallback getting the variant. TimeOut: ${count * timePeriod}`
  //     );
  //     callback(undefined);
  //   } else {
  //     console.log("not being loaded yet", count);
  //     setTimeout(() => {
  //       getTheOptimizerVariant(callback, count + 1);
  //     }, timePeriod);
  //   }
  // }
};

const getPosthogVariantAsync = (
  posthogExperimentName: string,
  callback: (variant: string) => void = (variant: string) => {},
  count: number
) => {
  if (PostHog.isFeatureEnabled(posthogExperimentName)) {
    const variant = PostHog.getFeatureFlag(posthogExperimentName);

    callback(variant as string);
  } else {
    if (count * timePeriod > maxWaitingTime) {
      callback("control");
    } else {
      setTimeout(() => {
        getPosthogVariantAsync(posthogExperimentName, callback, count + 1);
      }, timePeriod);
    }
  }
};

type PostHogExperiment = "tax-banner" | "pricing-plans";

const mapPosthogExperiments = {
  "tax-banner": "experiment-Tax-Compliance-Upsell-v1-10-05-2022",
  "pricing-plans":
    "experiment-signup-flow-tax-compliance-bundle-199vs152-8-23-2022",
};
