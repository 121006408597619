import styled from "styled-components/macro";
import utils from "utils";
import { HoverPointer } from "assets/GlobalStyled";

interface ItemProps {
  readonly isSelected: boolean;
}

export const ItemWrapper = styled.div<ItemProps>`
  ${HoverPointer}
  color: ${(props) =>
    props.isSelected ? utils.colors.gold : utils.colors.white};
  border-radius: 6px;
  margin: 0px 16px 0px 16px;
  display: flex;
  padding: 6px;
`;
