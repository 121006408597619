import React, { FunctionComponent } from "react";
import { P } from "components/Fonts";
import { CustomCard } from "components/Cards";
import { StyledSectionResources } from "./styles";
import { ResourcesCardProps, TResources } from "./types";
import { LinkExternalDomain } from "components/LinkExternalDomain";
import utils from "utils";
import { IntercomTriggerWrapper } from "components/IntercomTriggerWrapper";

export const ResourcesCard: FunctionComponent<ResourcesCardProps> = ({
  resources,
  title,
  subTitle,
}) => {
  return (
    <CustomCard
      styles={{ display: "grid" }}
      shadow="xstrong"
      borderRadius="strong"
    >
      <P size="large" emphasis={true}>
        {title}
      </P>

      <P size="small">{subTitle}</P>

      {resources.map((resource, index) => (
        <ResourceSection key={index} {...resource} />
      ))}
    </CustomCard>
  );
};

type Props = {
  link: string;
  children?: React.ReactNode;
};

const LinkWrapper: React.FC<Props> = ({ link, children }) => {
  if (link === "link to open the intercom here") {
    return <IntercomTriggerWrapper>{children}</IntercomTriggerWrapper>;
  } else {
    return <LinkExternalDomain to={link}>{children}</LinkExternalDomain>;
  }
};

const ResourceSection = ({ icon, title, description, link }: TResources) => {
  return (
    <StyledSectionResources>
      <div style={{ color: utils.colors.grass, marginRight: "10px" }}>
        {icon}
      </div>
      <div>
        <LinkWrapper link={link}>
          <P color={utils.colors.grass} size="small" emphasis={true}>
            {title}
          </P>
        </LinkWrapper>
        <P size="small">{description}</P>
      </div>
    </StyledSectionResources>
  );
};
