import { Alert } from "@mui/material";
import { P } from "components/Fonts";
import { FunctionComponent } from "react";
import utils from "utils";
import { AlertWrapper } from "./styles";

type Props = {
  text?: string;
  htmlText?: JSX.Element;
  noShadow?: boolean;
  withBorder?: boolean;
  iconColor?: string;
  boxColor?: string;
  margin?: number;
  severity?: "info" | "error";
};

export const AlertCard: FunctionComponent<Props> = ({
  text,
  htmlText,
  noShadow,
  withBorder,
  iconColor,
  boxColor,
  margin,
  severity = "info",
}) => (
  <AlertWrapper
    noShadow={noShadow}
    withBorder={withBorder}
    iconColor={iconColor}
    boxColor={boxColor}
    margin={margin}
  >
    <Alert severity={severity}>
      {htmlText ? (
        htmlText
      ) : (
        <P size="xsmall" color={utils.colors.charcoal} colorOpacity="75%">
          {text}
        </P>
      )}
    </Alert>
  </AlertWrapper>
);
