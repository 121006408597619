import styled from "styled-components/macro";

type Props = {
  mobileView?: boolean;
  hiddenGuidance?: boolean;
};

export const SidebarItemsWrapper = styled.div<Props>`
  max-height: ${(props) =>
    props.hiddenGuidance || props.mobileView
      ? "calc(100% - 170px)"
      : "calc(100% - 350px)"};
  overflow-y: auto;
`;
