import { Box } from "@mui/material";
import { ItemWrapper } from "./styles";
import { P } from "components/Fonts";

type Props = {
  isSelected: boolean;
  sidebarItem: {
    icon?: JSX.Element;
    label: string;
  };
};

export default function SubItem({ isSelected, sidebarItem }: Props) {
  return (
    <ItemWrapper key={sidebarItem.label} isSelected={isSelected}>
      <Box style={{ margin: "auto 0px" }} p={0} pl={6}>
        <P size="small" emphasis={isSelected}>
          {sidebarItem.label}
        </P>
      </Box>
    </ItemWrapper>
  );
}
