import { ShadowSuperStrong } from "assets/GlobalStyled";
import styled from "styled-components/macro";
import utils from "utils";
import { PropsPercentaje } from "./types";

export const RevenueWrapper = styled.div`
  display: flex;
  padding: 15px;
  align-items: center;
  margin: 15px 0px;
  border-radius: 16px;
  background: #ffffff;
  ${ShadowSuperStrong};
`;

export const IconWrapper = styled.div`
  width: 70px;
  svg {
    width: auto;
    height: 58px;
  }

  @media only screen and (max-width: ${utils.screenSize.mobile}) {
    width: 50px;

    svg {
      height: 40px;
    }
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 85px;

  @media only screen and (max-width: ${utils.screenSize.mobile}) {
    margin-bottom: 0;
  }
`;

export const PercentageWrapper = styled.div<PropsPercentaje>`
  background: ${({ isPositive }) =>
    isPositive ? `${utils.colors.gold}10` : "#F8839F10"};
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;
  border-radius: 12px;
  padding: 0px 15px;
  font-weight: bold;
  margin-top: -30px;

  svg {
    margin-left: 5px;
  }

  @media only screen and (max-width: ${utils.screenSize.mobile}) {
    padding: 0 5px;
  }
`;
