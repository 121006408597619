import { P } from "components/Fonts";
import { AccountSelectorProps } from "./types";

export const AccountSelector: React.FC<AccountSelectorProps> = ({ number }) => {
  return (
    <>
      <P size={"xsmall"} colorOpacity="50%" paddingBottom={1}>
        Account
      </P>
      <P size="small" emphasis paddingBottom={2}>
        {number}
      </P>
    </>
  );
};
