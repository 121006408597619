import styled from "styled-components/macro";
import utils from "utils";
import { Close } from "@mui/icons-material";
import { HoverPointer } from "assets/GlobalStyled";
import TaxSeasonImage from "./tax-season-popup.png";

export const TaxPopupWrapper = styled.div`
  width: 680px;
  min-height: 500px;
  position: relative;
  background: url("${TaxSeasonImage}") center center;
  background-repeat: no-repeat;
  padding-top: 50px;
  text-align: center;
`;

export const StyledCloseIcon = styled(Close)`
  position: absolute;
  right: 20px;
  top: 20px;
  width: 40px !important;
  height: 40px !important;
  fill: ${utils.colors.charcoal};
  ${HoverPointer}

  &:hover,
  &:focus {
    fill: ${utils.colors.grass};
  }
`;
