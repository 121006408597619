import styled, { css } from "styled-components/macro";
import utils from "utils";
import { ReactComponent as DoolaLogo } from "assets/doola_logo_black.svg";

export const StyledLogo = styled(DoolaLogo)<{
  width?: number;
  $darkMode?: boolean;
  $saveWidth?: boolean;
}>`
  width: ${({ width }) => `${width ?? 110}px`};

  .brandName {
    fill: ${({ $darkMode }) => ($darkMode ? "#fff" : "#1e1e1e")};
  }
  @media only screen and (max-width: ${utils.screenSize.mobile}) {
    ${({ $saveWidth, width }) =>
      !$saveWidth &&
      css`
        width: ${width ? width - 20 : 80}px;
      `}
  }
`;
