import { WrappFadeAnimation } from "assets/GlobalStyled";
import { CustomCard } from "components/Cards";
import { P } from "components/Fonts";
import { useEffect } from "react";
import { useState } from "react";
import { useFormContext } from "react-hook-form";
import utils from "utils";
import { PaymentOption } from "../types";
import { EventAvailableStyled, PaymentOptionSubWrapper } from "./styles";

type PaymentOptionOnView = {
  title: string;
  value: PaymentOption;
  description: string;
  comment: string;
};

const PAYMENT_OPTIONS: PaymentOptionOnView[] = [
  {
    title: "ACH",
    value: "ach",
    description: "1-3 business days",
    comment: "No Fees",
  },
  // {
  //   title: "Wire",
  //   value: "wire",
  //   description: "1 business day",
  //   comment: "No Fees. Wires are irreversible",
  // },
  // {
  //   title: "International Transfer",
  //   value: "international-transfer",
  //   description: "Varies by Country",
  //   comment: "No Fees. Wires are irreversible",
  // },
];

/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default (): JSX.Element => {
  const { getValues, setValue } = useFormContext();

  const currentSelection = getValues("optionType");

  const [selected, setSelected] = useState(currentSelection);

  useEffect(() => {
    setValue("optionType", selected);
  }, [setValue, selected]);

  return (
    <>
      <WrappFadeAnimation>
        <P size="large" emphasis margin="30px 0px">
          How do you want to pay money?
        </P>

        {PAYMENT_OPTIONS.map(
          ({ title, description, comment, value }, index) => (
            <CustomCard
              key={index}
              borderRadius="strong"
              shadow="strong"
              onClick={() => setSelected(value)}
              styles={{
                marginBottom: "15px",
                cursor: "pointer",
                border:
                  selected === value
                    ? `1px solid ${utils.colors.gold}`
                    : "none",
              }}
            >
              <P size="small" emphasis>
                {title}
              </P>
              <PaymentOptionSubWrapper>
                <P
                  size="xsmall"
                  style={{ alignItems: "center", display: "flex" }}
                >
                  <EventAvailableStyled />
                  {description}
                </P>
                <P size="xsmall" colorOpacity="50%" style={{ float: "right" }}>
                  {comment}
                </P>
              </PaymentOptionSubWrapper>
            </CustomCard>
          )
        )}

        <br />
      </WrappFadeAnimation>
    </>
  );
};
