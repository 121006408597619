import { P } from "components/Fonts";
import { CustomCard } from "components/Cards";
import { StyledReadMore } from "./styles";
import { LinkReferral } from "components/LinkReferral";
import { LinkRouter } from "components/LinkRouter";
import { ROUTER_PATHES } from "routes/routes";
import utils from "utils";

export const InviteSomeone: React.FC<{}> = () => {
  return (
    <CustomCard
      shadow="xstrong"
      borderRadius="strong"
      styles={{
        display: "flow-root",
        background: `linear-gradient(#98CC83, #D4F1C9)`,
      }}
    >
      <P size="large" color={utils.colors.charcoal} weight="500">
        Invite someone to doola 💛
      </P>
      <br />

      <LinkReferral />
      <br />
      <P color={utils.colors.charcoal} size="small">
        Know an entrepreneur who would love doola? For each person you invite
        that joins doola, you can earn a 15% commission.
      </P>

      <LinkRouter to={ROUTER_PATHES.REFERRALS}>
        <StyledReadMore>
          <P color={utils.colors.grass} size="small" emphasis>
            Refer & Earn →
          </P>
        </StyledReadMore>
      </LinkRouter>
    </CustomCard>
  );
};
