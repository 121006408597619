import { buildProfileData } from "./services";
import { LoadingComponent } from "components/Loading";
import { DataEnteredSummary } from "components/DataEntered";
import { Grid } from "@mui/material";
import { P } from "components/Fonts";
import { AlertCard } from "components/AlertCard";
import utils from "utils";
import { IntercomTriggerWrapper } from "components/IntercomTriggerWrapper";
import { CustomCard } from "components/Cards";
import { useAuth } from "hooks/useAuth";
import useCurrentUser from "graphql/hooks/useCurrentUser";

const ProfileContainer = () => {
  const { currentCompany } = useAuth();
  const { currentUser } = useCurrentUser();

  if (!currentCompany || !currentUser) return <LoadingComponent />;

  const dataToPass = buildProfileData({
    user: currentUser,
    company: currentCompany,
  });

  return (
    <Grid container spacing={3}>
      <Grid item lg={12}>
        <P size="large" emphasis>
          Settings
        </P>
      </Grid>

      <Grid item lg={8} mb={1}>
        <AlertCard
          htmlText={
            <>
              <P size="xsmall" colorOpacity="75%">
                We send the below information to the state so please make sure
                all data entered in correctly. In case of any requested changes
                please{" "}
                <IntercomTriggerWrapper>
                  <span
                    style={{ color: utils.colors.grass, fontWeight: "bold" }}
                  >
                    live with chat us
                  </span>
                </IntercomTriggerWrapper>{" "}
                or email us at{" "}
                <a
                  href={"mailto: hello@doola.com"}
                  style={{
                    textDecoration: "none",
                    color: utils.colors.grass,
                  }}
                >
                  hello@doola.com
                </a>
              </P>
            </>
          }
        />
      </Grid>

      <Grid item lg={11} md={8}>
        <CustomCard shadow="xstrong" borderRadius="strong">
          <DataEnteredSummary {...dataToPass} />
        </CustomCard>
      </Grid>
    </Grid>
  );
};

export default ProfileContainer;
