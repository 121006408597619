import { Grid } from "@mui/material";
import { WrappFadeAnimation } from "assets/GlobalStyled";
import { P } from "components/Fonts";
import {
  ControlledSelectField,
  ControlledTextField,
} from "components/FormElements/FormControllers";
import { useFormContext } from "react-hook-form";
import countries from "resources/countries";
import { getPaymentOptionTitle } from "../services";

const ACCOUNT_TYPES = [
  { value: "Checking", name: "Checking" },
  { value: "Savings", name: "Savings" },
  // { value: "personalChecking", name: "Personal Checking" },
  // { value: "PersonalSavings", name: "Personal Savings" },
];

const RECIPIENT_TYPES = [
  { value: "person", name: "Person" },
  { value: "business", name: "Business" },
];

/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */

export default (): JSX.Element => {
  const {
    register,
    control,
    formState: { errors },
    getValues,
  } = useFormContext();

  const optionType = getValues("optionType");

  const isAch = optionType === "ach";
  const isInternationalWire = optionType === "international-transfer";
  const COMMON_PROPS = { control: control, errors: errors, register: register };

  return (
    <>
      <WrappFadeAnimation>
        <P size="Large" emphasis>
          {getPaymentOptionTitle(optionType)}
          Details
        </P>

        <Grid container spacing={1}>
          <Grid item xs={12} sm={12}>
            {isInternationalWire && (
              <ControlledSelectField
                {...COMMON_PROPS}
                required={true}
                label="Recipient Type"
                name={"intTransRecipientType"}
                options={[{ value: "", name: "" }, ...RECIPIENT_TYPES]}
              />
            )}

            {isInternationalWire && (
              <ControlledSelectField
                {...COMMON_PROPS}
                required={true}
                native={true}
                label="Country or Territory"
                name={"intTransCountry"}
                options={[
                  { value: "", name: "" },
                  ...countries
                    .sort((a, b) => a.label.localeCompare(b.label))
                    .map((country) => ({
                      value: country.label,
                      name: country.label,
                    })),
                ]}
              />
            )}

            <ControlledTextField
              required
              {...COMMON_PROPS}
              name="accountNumber"
              label="Account Number"
              type="number"
            />

            {isInternationalWire ? (
              <ControlledTextField
                required
                {...COMMON_PROPS}
                name="intTransSwiftBic"
                label="SWIFT / BIC code"
              />
            ) : (
              <ControlledTextField
                required
                {...COMMON_PROPS}
                name="aChRoutingNumber"
                label="Routing Number"
                type="number"
                rules={{
                  minLength: { value: 2, message: "Can not be empty" },
                  validate: (value) =>
                    /^\d{9}$/.test(value.trim()) ||
                    "Enter the valid routing number. Routing numbers are always 9 digits long.",
                }}
              />
            )}

            <ControlledTextField
              required
              {...COMMON_PROPS}
              name="description"
              label="Description (Max. 90 character)"
            />

            {isAch && (
              <ControlledSelectField
                {...COMMON_PROPS}
                required={true}
                label="Account Type"
                name="aChAccountType"
                options={ACCOUNT_TYPES}
              />
            )}
          </Grid>
        </Grid>
      </WrappFadeAnimation>
    </>
  );
};
