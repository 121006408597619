import { useEffect } from "react";
import { TrackerGA4 } from "services/GA4tracking";
import { GuardProps } from "./types";
import { Navigate, useLocation } from "react-router-dom";
import { AUTH_USER_TOKEN_KEY, validateToken } from "utils/auth";
import useCurrentUser from "graphql/hooks/useCurrentUser";
import config from "config";

// ==============================|| AUTH GUARD ||============================== //

const GA4Tracker = TrackerGA4.getInstance();

const AuthGuard = ({ children }: GuardProps) => {
  const isTokenValid = validateToken(localStorage.getItem(AUTH_USER_TOKEN_KEY));
  const { currentUser } = useCurrentUser();

  useEffect(() => {
    if (isTokenValid && currentUser) {
      GA4Tracker.login(currentUser.id);
    }
  }, [isTokenValid, currentUser]);

  if (!isTokenValid) {
    return <Navigate to={`${config.basePath}/login`} />;
  }

  return children;
};

const AuthGuardContainer = ({ children }: GuardProps) => {
  const queryParams = new URLSearchParams(useLocation().search);
  const tokenQueryParam = queryParams.get("token");

  if (tokenQueryParam) {
    localStorage.setItem(AUTH_USER_TOKEN_KEY, tokenQueryParam);
  }

  return <AuthGuard children={children} />;
};

export default AuthGuardContainer;
