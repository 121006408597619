import styled from "styled-components/macro";

export const BankingCardCreateACardWrapper = styled.div`
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
`;

export const ButtonCreateACardWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
`;
