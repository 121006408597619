import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  setCompanyStateSelected,
  selectIsAtCheckout,
  setCompanyEntityType,
  setStepOne,
} from "state/formSlice";
import { selectFormSubmit } from "state/formSlice";
import { setPromotionCode, setEverflowPartner } from "state/authSlice";
import { emptyCoupon } from "resources/coupons";
import { EF_UTILS, Everflow } from "resources/everflow";
import { LoadingComponent } from "components/Loading";
import PostHog from "posthog-js";
import { getReferralData } from "utils/Referral";
import { ROUTER_PATHES } from "routes/routes";
import { useCreateOrUpdateCustomerFromFormMutation } from "services/forms-api";
import { useAppDispatch, useAppSelector } from "state/hooks";

export default function PricingPage() {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );
  const EverflowService = new Everflow(queryParams);
  const isLLCUniversityAffiliate = queryParams.get("_ef_transaction_id");
  const formSubmitState = useAppSelector(selectFormSubmit);
  const isAtCheckout = useAppSelector(selectIsAtCheckout);

  const [, setSelectedState] = useState(
    formSubmitState.companyStateAbbreviated
  );
  const [, setEntityType] = useState(formSubmitState.entityType);
  const [createOrUpdateCustomerFromForm] =
    useCreateOrUpdateCustomerFromFormMutation();

  useEffect(() => {
    const statePreSelectedByQueryParams = queryParams.get("selectedState");
    const entityPreSelectedByQueryParams = queryParams.get("selectedEntity");
    const emailByQueryParams = queryParams.get("email")?.toLowerCase();
    const { referral } = getReferralData();

    if (statePreSelectedByQueryParams) {
      dispatch(setCompanyStateSelected(statePreSelectedByQueryParams));
      setSelectedState(statePreSelectedByQueryParams);
    }

    if (entityPreSelectedByQueryParams) {
      dispatch(setCompanyEntityType(entityPreSelectedByQueryParams));
      setEntityType(entityPreSelectedByQueryParams);
    }

    if (emailByQueryParams) {
      createOrUpdateCustomerFromForm({
        fullName: "User from Landing Page",
        email: emailByQueryParams,
        referredBy: referral || "",
      });

      dispatch(setStepOne({ email: emailByQueryParams }));
    }

    if (EverflowService.isEverflowPartner()) {
      handleURL(queryParams, dispatch);
    } else {
      if (isAtCheckout) {
        navigate(ROUTER_PATHES.SIGNUP_CHECKOUT);
      } else {
        navigate(ROUTER_PATHES.SIGNUP_STEP_1);
      }
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (isLLCUniversityAffiliate) {
    return <LoadingComponent text="You are being redirected..." />;
  }

  return <LoadingComponent />;
}

const handleURL = (queryParams: URLSearchParams, dispatch: any) => {
  const EverflowService = new Everflow(queryParams);
  const { transactionId, offerId, partnerId, goTo } = EverflowService;
  let everflowString;

  if (EF_UTILS.isDaoOffer(offerId)) {
    dispatch(setCompanyEntityType("DAO"));
    dispatch(setCompanyStateSelected("WY"));

    everflowString = EF_UTILS.isTruicPartner(partnerId)
      ? "TRUIC_WYDAO_LLC"
      : "LLCU_WYDAO_LLC";
  } else {
    everflowString = EF_UTILS.isTruicPartner(partnerId)
      ? "TRUIC_NonUS_LLC"
      : "LLCU_NonUS_LLC";
  }

  PostHog.capture("Set Everflow user properties", {
    $set: { everflow: everflowString },
  });

  dispatch(
    setEverflowPartner({
      transactionId: transactionId,
      offerId: offerId,
      partnerId: partnerId,
    })
  );

  dispatch(setPromotionCode(emptyCoupon));

  setTimeout(() => {
    window.location.href = goTo;
  }, 1500);
};
