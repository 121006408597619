import { emptySplitAuthApi } from "./empty-auth-api";

const COMPANIES_URL = `/companies`;

export const companiesApi = emptySplitAuthApi.injectEndpoints({
  endpoints: (builder) => ({
    currentCompany: builder.mutation<any, any>({
      query: (companyId: string) => ({
        url: `${COMPANIES_URL}/account/${companyId}`,
        method: "GET",
      }),
    }),
  }),
});

export const { useCurrentCompanyMutation } = companiesApi;
