import { Grid } from "@mui/material";
import { LinkRouter } from "components/LinkRouter";
import { Logo } from "components/Logos";
import Notification from "components/Notification";
import { ROUTER_PATHES } from "routes/routes";
import Profile from "../../Header/HeaderContent/Profile";
import CompanySelector from "./CompanySelector";
import { SidebarHeaderWrapper } from "./styles";

interface SidebarHeaderProps {
  isDarkMode: boolean;
}

const SidebarHeader = ({ isDarkMode }: SidebarHeaderProps) => {
  return (
    <SidebarHeaderWrapper>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <LinkRouter to={ROUTER_PATHES.DASHBOARD}>
            <Logo $darkMode={isDarkMode} />
          </LinkRouter>
        </Grid>
        <Grid item>
          <Grid container alignItems="center">
            <Grid item>
              <Notification />
            </Grid>
            <Grid item>
              <Profile
                justAvatar
                popperPlacement="bottom-start"
                avatarSize="sm"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <CompanySelector />
    </SidebarHeaderWrapper>
  );
};

export default SidebarHeader;
