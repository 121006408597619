import { Navigate, useRoutes } from "react-router-dom";
import { ROUTER_PATHES } from "routes/routes";
import GuestGuard from "utils/route-guard/GuestGuard";
import PricingPage from "views/PricingPage";
import LoginRoutes from "./LoginRoutes";
import MainRoutes from "./MainRoutes";
// import SignupRoutes from "./SignupRoutes";

export default function Routes() {
  return useRoutes([
    {
      path: "/",
      element: (
        <GuestGuard>
          <PricingPage />
        </GuestGuard>
      ),
    },
    {
      path: "*",
      element: <Navigate to={ROUTER_PATHES.LOGIN} />,
    },
    LoginRoutes,
    // SignupRoutes,
    MainRoutes,
  ]);
}
