import styled from "styled-components/macro";
import utils from "utils";

export const CardDetailsWrapper = styled.div`
  position: relative;
  color: #ffff;
  text-align: left;
  text-transform: uppercase;

  svg {
    width: 385px;

    @media only screen and (max-width: ${utils.screenSize.mobile}) {
      width: 270px;
    }
  }
`;
export const TextWrapper = styled.div`
  position: absolute;
  top: 130px;
  left: 20px;

  @media only screen and (max-width: ${utils.screenSize.mobile}) {
    top: 120px;
    left: 15px;
  }
`;
