import styled from "styled-components/macro";
import utils from "utils";

export const MakeAPaymentContainerWrapper = styled.div`
  width: 600px;
  margin: auto;
  display: grid;

  @media only screen and (max-width: ${utils.screenSize.mobile}) {
    width: 270px;
  }
`;

export const MakeAPaymentButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 30px 0;
`;
