import { FunctionComponent } from "react";
import { CustomButtonProps } from "./types";
import { ButtonWrapper, StyledButtonLoading } from "./styles";
import { P } from "components/Fonts";
import CircularProgress from "@mui/material/CircularProgress";

export const CustomButton: FunctionComponent<CustomButtonProps> = ({
  loading,
  ...props
}) => {
  const propsToPass = {
    ...props,
    onClick: props.disabled || loading ? () => {} : props.onClick,
  };

  return (
    <ButtonWrapper $loading={loading} {...propsToPass}>
      {loading && (
        <StyledButtonLoading>
          <CircularProgress color="inherit" />
        </StyledButtonLoading>
      )}
      <P size="small">{props.children}</P>
    </ButtonWrapper>
  );
};
