import { Dialog, DialogActions, DialogTitle } from "@mui/material";
import { CustomButton } from "components/Buttons";
import { CustomButtonVariants } from "components/Buttons/types";
import { P } from "components/Fonts";
import { useState } from "react";
import { StyledCloseIcon, TaxPopupWrapper } from "./styles";

export interface TaxPopupProps {
  onClick: () => void;
}

const TaxPopup: React.FC<TaxPopupProps> = ({ onClick }) => {
  const [isOpen, setIsOpen] = useState(true);

  const closeModalHandler = () => {
    setIsOpen(false);
  };

  return (
    <Dialog open={isOpen} maxWidth="md">
      <TaxPopupWrapper>
        <StyledCloseIcon onClick={closeModalHandler} />
        <DialogTitle>
          <P size="35px" emphasis>
            Hey, Tax Season is Approaching!
          </P>
        </DialogTitle>
        <P
          size="20px"
          weight="500"
          style={{
            maxWidth: "430px",
            margin: "0 auto 30px",
          }}
        >
          Not prepared? We can complete all your IRS tax and state filings,
          worry-free.
        </P>
        <DialogActions sx={{ justifyContent: "center" }}>
          <CustomButton
            style={{ borderRadius: "14px", padding: "15px 35px" }}
            variant={CustomButtonVariants.green}
            onClick={onClick}
          >
            <P size="large" textTransform="uppercase" emphasis>
              Get my taxes filed
            </P>
          </CustomButton>
        </DialogActions>
      </TaxPopupWrapper>
    </Dialog>
  );
};

export default TaxPopup;
