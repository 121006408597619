import { Divider, Grid } from "@mui/material";
import { WrappFadeAnimation } from "assets/GlobalStyled";
import { CustomButton } from "components/Buttons";
import { CustomButtonVariants } from "components/Buttons/types";
import { CustomCard } from "components/Cards";
import { P } from "components/Fonts";
import { useAuth } from "hooks/useAuth";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useGetVerificationMutation } from "services/banking-extra-api";
import { moneyFormatter } from "utils/formatters";
import { SVGCheck, SVGPlaneIcon } from "../../SharedComponents/SVGs";
import { getPaymentOptionTitle } from "../services";

import { MakePaymentForm } from "../types";
import { SVGPlaneWrapper } from "./styles";

/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */

type Props = {
  onNext: () => void;
};

export default ({ onNext }: Props): JSX.Element => {
  const { currentCompany } = useAuth();
  const { getValues, setValue } = useFormContext();
  const [getVerification, { data, isLoading }] = useGetVerificationMutation();

  useEffect(() => {
    if (!data) {
      return;
    }

    setValue("verificationToken", data.verificationToken);
    onNext();
  }, [data, setValue, onNext]);

  const _onPayment = async () => {
    getVerification(currentCompany.id);
  };

  const {
    optionType,
    transactionValue,
    recipientName,
    recipientEmail,
    accountNumber,
    recipientCountry,
    recipientAddress1,
    recipientAddress2,
    recipientCity,
    recipientState,
    recipientPostalCode,
    transactionMessage,
    description,
    aChAccountType,
    aChRoutingNumber,
    intTransRecipientType,
    intTransCountry,
    intTransSwiftBic,
  } = getValues() as MakePaymentForm;

  const isAch = optionType === "ach";
  const isIntWire = optionType === "international-wire";

  const COMMON_DATA = [
    { name: "Recipient", value: recipientName },
    { name: "Email", value: recipientEmail },
    // { name: "Notify Recipient", value: notifyRecipient ? "Yes" : "No" },
    { name: "Recipient Account Number", value: accountNumber },
    { name: "Recipient Country", value: recipientCountry },
    { name: "Recipient Address 1", value: recipientAddress1 },
    { name: "Recipient Address 2", value: recipientAddress2 },
    { name: "Recipient City", value: recipientCity },
    { name: "Recipient State", value: recipientState },
    { name: "Recipient Postal Code", value: recipientPostalCode },
    { name: "Transaction Message", value: transactionMessage },
    { name: "Description", value: description },
  ];

  const ACH_DATA = [
    { name: "ACH Account Type", value: aChAccountType },
    { name: "ACH Routing Number", value: aChRoutingNumber },
  ];

  const INT_WIRE_DATA = [
    { name: "Transaction Recipient Type", value: intTransRecipientType },
    {
      name: "International Trandaction Country",
      value: intTransCountry,
    },
    { name: "Swift/Bic", value: intTransSwiftBic },
  ];

  const SUMMARY_DATA = [
    ...COMMON_DATA,
    ...(isAch ? ACH_DATA : []),
    ...(isIntWire ? INT_WIRE_DATA : []),
  ];

  return (
    <>
      <WrappFadeAnimation>
        <CustomCard borderRadius="strong" shadow="strong">
          <Grid container spacing={1} mb={2}>
            <Grid item xs={12} sm={12} display="flex">
              <SVGPlaneWrapper>
                <SVGPlaneIcon />
              </SVGPlaneWrapper>
              <div>
                <P size="medium" colorOpacity="50%" emphasis>
                  {`Sending ${getPaymentOptionTitle(optionType)} Payment for:`}
                </P>
                <P size="large" emphasis>
                  ${moneyFormatter(transactionValue, false)}
                </P>
              </div>
            </Grid>
          </Grid>

          <Divider />
          <Grid container spacing={1} mt={2}>
            <Grid item xs={12} sm={12}>
              <P size="xsmall" colorOpacity="75%">
                Payment Details:
              </P>
            </Grid>
            {SUMMARY_DATA.map(({ name, value }) => {
              return (
                <>
                  <Grid item xs={12} sm={6} display="flex">
                    <SVGCheck />
                    <P size="xsmall" colorOpacity="50%">
                      {name}
                    </P>
                  </Grid>
                  <Grid item xs={12} sm={6} className="ph-no-capture">
                    <P size="xsmall">{value}</P>
                  </Grid>
                </>
              );
            })}
          </Grid>
          <Grid container spacing={1} mt={2}>
            <Grid item xs={12} sm={12} textAlign="right">
              <CustomButton
                onClick={_onPayment}
                variant={CustomButtonVariants.black}
                borderRadius="rounded"
                loading={isLoading}
                type="button"
              >
                <P size="small" margin="0px 15px 0px 0px">
                  Send Payment →
                </P>
              </CustomButton>
            </Grid>
          </Grid>
        </CustomCard>
      </WrappFadeAnimation>
    </>
  );
};
