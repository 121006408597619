import { Divider, Grid } from "@mui/material";
import { CustomCard } from "components/Cards";
import { P } from "components/Fonts";
import { StatusLabel } from "components/StatusLabel";
import {
  CompanyService,
  ServiceNames,
  ServiceStatus,
} from "models/ServiceModel";
import React from "react";
import utils from "utils";
import { colorWithOpacity, formatServiceName } from "utils/formatters";

type Props = {
  services: CompanyService[];
};

export const AddonsComponent: React.FC<Props> = ({ services }) => {
  if (services.length === 0) {
    return null;
  }

  return (
    <CustomCard
      styles={{ display: "flex-inline" }}
      shadow="xstrong"
      borderRadius="strong"
    >
      <P size="large" emphasis={true}>
        Your Addons
      </P>

      <br />
      {services.map((service, index) => {
        const statusData = formatStatus(service.status, service.name);
        const isLast = services.length === index + 1;

        return (
          <React.Fragment key={index}>
            <Grid container spacing={2} style={{ alignItems: "center" }}>
              <Grid item lg={7}>
                <P key={service.name} size="small" margin="10px 20px">
                  {formatServiceName(service.name)}
                </P>
              </Grid>
              <Grid item lg={5}>
                <StatusLabel
                  label={statusData.label}
                  color={statusData.color}
                />
              </Grid>
            </Grid>
            {!isLast && <Divider />}
          </React.Fragment>
        );
      })}
    </CustomCard>
  );
};

const formatStatus = (status: string, serviceName: string) => {
  const isCompleted = status === ServiceStatus.completed;

  const getCompleteLabel = () => {
    switch (serviceName) {
      case ServiceNames.iTin:
      case ServiceNames.phoneLine:
        return "Handed off to doola Partner";
      case ServiceNames.banking:
        return "Ready for Registration";
      default:
        return "Completed";
    }
  };

  return {
    label: isCompleted ? getCompleteLabel() : "In progress",
    color: isCompleted
      ? colorWithOpacity(utils.colors.grass, 50)
      : colorWithOpacity(utils.colors.gold, 50),
  };
};
