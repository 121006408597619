import Chart from "react-apexcharts";
import { format } from "fecha";
import { CustomCard } from "components/Cards";
import { P } from "components/Fonts";
import utils from "utils";
import { moneyFormatter } from "utils/formatters";
import { NoGraphWrapper } from "./styles";

type Props = {
  dates: any[];
};

export const ActivityChart: React.FC<Props> = (props) => {
  const chartOptions = {
    options: {
      chart: {
        id: "basic-bar",
        foreColor: "#6D6D6D",
      },
      grid: {
        borderColor: "none",
      },
      dataLabels: {
        enabled: false,
        formatter: (val: any, opts: any) => {
          return `$${moneyFormatter(val, false)}`;
        },
      },
      xaxis: {
        categories: props.dates.map((x) =>
          format(new Date(x.attributes.createdAt), "ddd")
        ),
        axisBorder: {
          show: false,
          offsetY: 10,
        },
        axisTicks: {
          show: true,
          borderType: "dotted",
          color: `${utils.colors.charcoal}10`,
          height: 140,
          offsetY: -140,
        },
        tooltip: {
          enabled: false,
          offsetY: -70,
          formatter: (val: any, opts: any) =>
            `$${moneyFormatter(opts.series[0][val - 1], false)}`,
        },
      },
      colors: [utils.colors.gold, utils.colors.peach],
      fill: {
        type: "gradient", // 'solid' / 'pattern' / 'image'
      },
      yaxis: {
        show: false,
        labels: {
          show: false,
        },
      },
    },
    series: [
      {
        name: "",
        data: props.dates.map((x) => x.attributes.balance),
      },
    ],
  };

  const showGraph = props.dates.length !== 0;

  return (
    <CustomCard
      styles={{
        minHeight: "265px",
      }}
      shadow="strong"
      borderRadius="strong"
    >
      <P size="large">Latest activity</P>
      {!showGraph && <NoGraph />}
      {showGraph && (
        <Chart
          options={chartOptions.options}
          series={chartOptions.series}
          type="area"
          height={200}
        />
      )}
    </CustomCard>
  );
};

const NoGraph: React.FC = () => (
  <NoGraphWrapper>
    <P size="small" colorOpacity="50%" margin="auto">
      No activity yet
    </P>
  </NoGraphWrapper>
);
