import { ROUTER_PATHES } from "routes/routes";

export enum SignupChatSteps {
  Pricing = 0,
  Typeform = 1,
  InfoSummary = 2,
  OrderSummary = 3,
  Payment = 4,
  Success = 5,
}

export const getChatUrlByStep = (stateValue: SignupChatSteps) => {
  switch (stateValue) {
    case SignupChatSteps.Pricing:
      return "/";
    case SignupChatSteps.Typeform:
      return ROUTER_PATHES.SIGNUP_STEP_1;
    case SignupChatSteps.InfoSummary:
      return ROUTER_PATHES.SIGNUP_STEP_2;
    case SignupChatSteps.OrderSummary:
      return ROUTER_PATHES.SIGNUP_SUMMARY;
    case SignupChatSteps.Payment:
      return ROUTER_PATHES.SIGNUP_CHECKOUT;
    case SignupChatSteps.Success:
      return ROUTER_PATHES.SIGNUP_SUCCESS;
    default:
      return "";
  }
};
