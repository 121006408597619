import { CustomButton } from "components/Buttons";
import { P } from "components/Fonts";
import { useAuthBanking } from "hooks/useAuthBanking";
import React, { useCallback } from "react";
import { useFormContext } from "react-hook-form";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useCreateCardForAccountMutation } from "services/banking-api";
import { parsePhoneNumber } from "react-phone-number-input";
import {
  useGetTokenWithVerificationMutation,
  useGetVerificationMutation,
} from "services/banking-extra-api";
import { buildDynamicRoute } from "views/DashboardApp/Layout/Sidebar/data";
import CardData from "./CardData";
import CustomizeCard from "./CustomizeCard";
import {
  BankingCardCreateACardWrapper,
  ButtonCreateACardWrapper,
} from "./styles";
import TwoFAModal from "../2FAmodal";
import { CustomButtonVariants } from "components/Buttons/types";
import { toast } from "toast";
import * as Sentry from "@sentry/react";
import { format } from "fecha";
import { BANKING_ROUTES } from "routes/routes";
import { useAuth } from "hooks/useAuth";

type Props = { mFAData: any; loadingRequest2FA: boolean };

const FORM_INIT_VALUES = {
  firstName: "",
  lastName: "",
  address1: "",
  address2: "",
  city: "",
  state: "",
  postalCode: "",
  country: "",
  dateOfBirth: "",
  phone: "",
  email: "",
  // accountAssociatedNumber: "",
  // nickName: "",
  // limitType: "",
  // dailySpendingLimit: 0,
};

const CreateCard: React.FC<Props> = ({ mFAData, loadingRequest2FA }) => {
  const { currentCompany } = useAuth();
  const { currentAccountId: accountId } = useAuthBanking();
  const [createCard, { isLoading: loadingCreatingCard }] =
    useCreateCardForAccountMutation();
  const { getValues } = useFormContext();

  const [getTokenWithVerification, { isLoading: loadingGetToken }] =
    useGetTokenWithVerificationMutation();

  const navigate = useNavigate();

  const handleCreateCard = useCallback(
    async (twoFAValue?: string) => {
      if (!mFAData || !twoFAValue) {
        return;
      }

      const tokenWithVerificationResponse = await getTokenWithVerification({
        token: mFAData.verificationToken,
        code: twoFAValue,
        companyId: currentCompany.id,
      }).unwrap();

      const {
        firstName,
        lastName,
        address1,
        address2,
        city,
        state,
        postalCode,
        country,
        dateOfBirth,
        phone,
        email,
        // accountAssociatedNumber,
        // nickName,
        // limitType,
        // dailySpendingLimit,
      } = getValues();

      const parsedPhoneNumber = parsePhoneNumber(phone);

      const createACardParams = {
        token: tokenWithVerificationResponse.token,
        accountId: accountId,
        fullName: {
          first: firstName,
          last: lastName,
        },
        address: {
          street: `${address1}, ${address2}`,
          city: city,
          state: state, //"CA" || state,
          postalCode: postalCode, //"94303" || postalCode,
          country: country, //"US" || country,
        },
        phone: {
          countryCode: parsedPhoneNumber!.countryCallingCode,
          number: parsedPhoneNumber!.nationalNumber,
        },
        dateOfBirth: format(new Date(dateOfBirth), "YYYY-MM-DD"), //"2001-08-10" || dateOfBirth, should be YYYY-MM-DD
        email: email,
      };

      try {
        const response = await createCard(createACardParams).unwrap();
        navigate(buildDynamicRoute.cardSuccess(response.id));
      } catch (error: any) {
        toast.error("Error occured while creating the card");
        toast.error(error.message);
        Sentry.captureException(error, {
          tags: {
            section: "Virtual Card Creation",
          },
          extra: {
            createACardParams,
            errors: JSON.stringify(error),
          },
        });
      }
    },
    [accountId, mFAData] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const _handleBack = () => navigate(BANKING_ROUTES.cards);

  return (
    <BankingCardCreateACardWrapper>
      <CardData />
      <br />
      {/* <AssociatedAccount /> */}
      <br />
      <CustomizeCard />

      {mFAData && <TwoFAModal onVerify={handleCreateCard} />}

      <ButtonCreateACardWrapper>
        <CustomButton onClick={_handleBack} variant={CustomButtonVariants.link}>
          <P size="small" emphasis>
            ← Back
          </P>
        </CustomButton>

        <CustomButton
          type="submit"
          variant={CustomButtonVariants.black}
          borderRadius="rounded"
          loading={loadingRequest2FA || loadingCreatingCard || loadingGetToken}
        >
          <P size="small">Create Card →</P>
        </CustomButton>
      </ButtonCreateACardWrapper>
    </BankingCardCreateACardWrapper>
  );
};

const CreateCardContainer: React.FC = () => {
  const { currentCompany } = useAuth();
  const methods = useForm<any>({ defaultValues: FORM_INIT_VALUES });

  const [requestMFA, { data: mFAData, isLoading: requestingMFA }] =
    useGetVerificationMutation();

  return (
    <FormProvider {...methods}>
      <form
        noValidate
        onSubmit={async (e) => {
          const isValid = await methods.trigger();
          if (isValid) {
            if (!requestingMFA) {
              requestMFA(currentCompany.id);
            }
          }
          e.preventDefault();
        }}
      >
        <CreateCard mFAData={mFAData} loadingRequest2FA={requestingMFA} />
      </form>
    </FormProvider>
  );
};

export default CreateCardContainer;
