import { useState, useEffect } from "react";
import { CustomButton } from "components/Buttons";
import { P } from "components/Fonts";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { logOutUser } from "state/authSlice";
import { useAppDispatch } from "state/hooks";
import { toast } from "toast";
import * as Sentry from "@sentry/react";
import { ROUTER_PATHES } from "routes/routes";
import { CustomButtonVariants } from "components/Buttons/types";
import { Auth } from "aws-amplify";
import {
  Checkbox,
  FormControlLabel,
  Grid,
  InputAdornment,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import { FieldValues, useForm } from "react-hook-form";
import IconButton from "components/@extended/IconButton";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import { ICurrentUserData } from "models/CustomerModel";
import { AuthControlledTextField } from "../styles";
import { EmailRegex } from "utils/regexValidations";
import { AUTH_USER_TOKEN_KEY } from "utils/auth";
import { useLazyQuery } from "@apollo/client";
import { CURRENT_USER } from "graphql/queries/CurrentUser";

export const AuthLogin: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [checked, setChecked] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const [getCurrentUser, { data, loading, called }] =
    useLazyQuery<ICurrentUserData>(CURRENT_USER);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
  } = useForm();
  const COMMON_PROPS = { control: control, errors: errors, register: register };

  useEffect(() => {
    if (!loading && called) {
      validateUser();
    }
  }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const validateUser = () => {
    if (!data) {
      return;
    }

    const user = data.currentUser;

    const noCompanies = !user.companyIds || user.companyIds.length === 0;

    setIsLoading(false);

    if (noCompanies) {
      dispatch(logOutUser());
      handleLogout();
    } else {
      navigate(ROUTER_PATHES.DASHBOARD);
    }
  };

  const handleLogout = async () => {
    try {
      await Auth.signOut({ global: true }).then(() => {
        localStorage.removeItem(AUTH_USER_TOKEN_KEY);
      });
    } finally {
      toast.error(
        "It seems that there's not company associated to your account. Please contact our team via live chat."
      );
    }
  };

  const onSubmitHandler = async ({ username, password }: FieldValues) => {
    setIsLoading(true);

    try {
      const cognitoUser = await Auth.signIn(username, password);
      localStorage.setItem(
        AUTH_USER_TOKEN_KEY,
        cognitoUser.signInUserSession.idToken.jwtToken
      );

      getCurrentUser();
    } catch (error: any) {
      Sentry.captureException(error);
      toast.error(error?.message as string);

      setIsLoading(false);
    }
  };

  return (
    <form noValidate onSubmit={handleSubmit(onSubmitHandler)}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <P size="large" emphasis>
            Welcome to doola!
          </P>
        </Grid>
        <Grid item xs={12}>
          <Stack spacing={1}>
            <AuthControlledTextField
              {...COMMON_PROPS}
              required
              fullWidth
              id="username"
              label="Email Address"
              name="username"
              autoComplete="email"
              type="email"
              autoFocus
              variant="outlined"
              rules={{
                validate: (value) =>
                  EmailRegex.test(value.trim()) ||
                  "Please use a valid email address",
              }}
            />
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Stack spacing={1}>
            <AuthControlledTextField
              {...COMMON_PROPS}
              required
              fullWidth
              name="password"
              label="Enter password"
              autoComplete="current-password"
              id="password"
              variant="outlined"
              type={showPassword ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      edge="end"
                      color="secondary"
                    >
                      {showPassword ? (
                        <EyeOutlined />
                      ) : (
                        <EyeInvisibleOutlined />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Stack>
        </Grid>
        <Grid item xs={12} sx={{ mt: -1 }}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={checked}
                  onChange={(event) => setChecked(event.target.checked)}
                  name="checked"
                  color="primary"
                  size="small"
                />
              }
              label={<Typography variant="h6">Keep me signed in</Typography>}
            />
            <Link
              variant="h6"
              component={RouterLink}
              to={ROUTER_PATHES.RECOVER_PASSWORD}
              color="text.primary"
            >
              Recover my password
            </Link>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <CustomButton
            type="submit"
            variant={CustomButtonVariants.black}
            loading={isLoading}
            width="100%"
          >
            <P size="small">Login</P>
          </CustomButton>
        </Grid>
      </Grid>
    </form>
  );
};
