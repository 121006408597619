import { darken, styled } from "@mui/material/styles";
import IconButton from "components/@extended/IconButton";

export const IconButtonStyled = styled(IconButton)(
  ({ theme }) => `
  width: 100%;
  display: block;
  border-radius: 12px;
  background-color: ${
    theme.palette.mode === "dark"
      ? darken(theme.palette.grey[600], 0.3)
      : theme.palette.grey[200]
  };
  color: ${theme.palette.text.primary};
  padding: 10px;

  &:hover {
    background-color: ${
      theme.palette.mode === "dark"
        ? theme.palette.grey[600]
        : theme.palette.grey[300]
    };
  }
`
);
