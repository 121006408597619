import { useState } from "react";
import { Widget } from "@typeform/embed-react";
import { LoadingComponent } from "components/Loading";
import { useSubmitTypeformFormMutation } from "services/forms-api";
import { isProduction } from "config";
import { BusinessTypeNames } from "resources/entityTypes";

const TYPEFORM_FORM_ID = isProduction ? "UViixDuR" : "kBAFX3nL";

type Props = {
  callbackSuccess: () => void;
  extraHiddenVar: {
    customer_id_hidden: string;
    email_hidden: string;
    fullname_hidden: string;
    phone_hidden?: string;
    company_name_hidden: string;
    company_state_abbreviated_hidden?: string;
    llc_name_hidden: BusinessTypeNames;
    llc_style_hidden: string;
  };
};

const CompanyFormationTypeform: React.FC<Props> = ({
  callbackSuccess,
  extraHiddenVar,
}) => {
  const [submited, setSubmited] = useState(false);
  const [submitTypeformResponse] = useSubmitTypeformFormMutation();

  const submitResponseSent = async (responseId: string) => {
    await submitTypeformResponse({ typeformId: TYPEFORM_FORM_ID, responseId });
    callbackSuccess();
  };

  const hiddenVars = {
    ...extraHiddenVar,
  };

  if (submited) return <LoadingComponent />;

  return (
    <Widget
      id={TYPEFORM_FORM_ID}
      style={{ width: "100%", height: "calc(100vh - 190px)" }}
      hidden={hiddenVars}
      onSubmit={async (event: any) => {
        submitResponseSent(event.responseId);
        setSubmited(true);
      }}
      keepSession
    />
  );
};

export default CompanyFormationTypeform;
