import styled, { css } from "styled-components/macro";

export const RightWrapper = styled.div`
  margin-left: 15px;
`;

export const IconWrapper = css`
  background-color: #fcf5e6;
  padding: 15px;
  color: #1e1e1e;
  display: flex;
`;

export const CustomWrapper = css`
  display: flex;
  &:hover {
    background-color: #eab94e;
    cursor: pointer;
    > div > .content-text {
      color: #fff;
    }
  }
`;
