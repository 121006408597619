const constantList = {
  units: {
    main: 12,
  },
  borders: {
    radius: 4,
  },
};

const colorList = {
  grass: "#477C32",
  green: "#589C3D",
  peach: "#F3815B",
  neonPink: "#FE5652",
  pink: "#F8839F",
  gold: "#F3B62F",
  charcoal: "#1E1E1E",
  fog: "#F6F6F6",
  white: "#FFFFFF",
  middleGray: "#8E8E8E",
  yellow: "#EAB94E",
};

const screenSize = {
  mobile: "600px",
  tablet: "900px",
  pc: "1200px",
};

const isBankingRoute = () => {
  try {
    const url_string = window.location.href.toLowerCase();
    const { pathname } = new URL(url_string);
    return pathname.includes("banking");
  } catch (err) {
    console.error(err);
    return false;
  }
};

const utils = {
  constants: constantList,
  colors: colorList,
  screenSize: screenSize,
  isBankingRoute: isBankingRoute,
};

export default utils;
