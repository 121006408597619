import { CustomCard } from "components/Cards";
import { P } from "components/Fonts";
import { IconWrapper, LastPaymentRowWrapper, NoDataYetWrapper } from "./styles";
import {
  IconInCommingTransfer,
  IconOutCommingTransfer,
} from "components/Banking/TransactionIcon";
import { moneyFormatter } from "utils/formatters";
import { format as formatDate } from "fecha";
import utils from "utils";
import { useMediaQuery } from "@mui/material";

/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */

export type asType = "incoming" | "outgoing";

type Props = {
  tableData: LastPaymentRowProps[];
  title: string;
};

export default ({ tableData, title }: Props): JSX.Element => {
  const isMobile = useMediaQuery(`(max-width:${utils.screenSize.mobile})`);

  return (
    <CustomCard
      shadow="xstrong"
      borderRadius="strong"
      styles={{ width: "inherit", height: isMobile ? "auto" : "384px" }}
      padding={isMobile ? 1 : 2}
    >
      <P size="medium" colorOpacity="50%" margin="10px 0px">
        {title}
      </P>

      {tableData.map((e, index) => (
        <LastPaymentRow key={index} {...e} />
      ))}
      {tableData.length === 0 && <NoDataYet />}
    </CustomCard>
  );
};

export type LastPaymentRowProps = {
  name: string;
  date: string;
  amount: number;
} & IconProps;

const LastPaymentRow: React.FC<LastPaymentRowProps> = ({
  name,
  date,
  amount,
  as,
}) => {
  return (
    <LastPaymentRowWrapper>
      <IconWrapper>
        <Icon as={as} />
      </IconWrapper>
      <div style={{ flex: "auto" }}>
        <P size="small" emphasis>
          {name}
        </P>
        <P size="small" colorOpacity="30%">
          {formatDate(new Date(date), "DD MMMM YYYY")}
        </P>
      </div>
      <P size="small" emphasis>
        ${moneyFormatter(amount, false)}
      </P>
    </LastPaymentRowWrapper>
  );
};

type IconProps = {
  as: asType;
};

const Icon: React.FC<IconProps> = ({ as }) =>
  as === "incoming" ? <IconInCommingTransfer /> : <IconOutCommingTransfer />;

const NoDataYet: React.FC = () => (
  <NoDataYetWrapper>
    <P size="small" colorOpacity="50%" margin="auto">
      No data in the last 7 days
    </P>
  </NoDataYetWrapper>
);
