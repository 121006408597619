import { DialogTitle } from "@mui/material";
import { HoverPointer } from "assets/GlobalStyled";
import styled, { css } from "styled-components/macro";

export const StyledCloseButton = styled.img`
  position: absolute;
  top: 16px;
  right: 0;
  width: 24px;
  height: 24px;
  ${HoverPointer}
`;

export const StyledDialogTitle = styled(DialogTitle)<{
  $modalTitle: JSX.Element | string;
}>`
  position: relative;
  padding: 16px 0 12px;
  margin: 0 24px 16px;
  ${({ $modalTitle }) =>
    $modalTitle &&
    css`
      border-bottom: 1px solid #98cc83;
    `}
`;
