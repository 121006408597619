import styled from "styled-components/macro";
import PhoneInputWithCountry from "react-phone-number-input/react-hook-form";
import DatePicker from "react-datepicker";

export const StyledPhoneInputWithCountry = styled(PhoneInputWithCountry)`
  min-height: 25px;
  margin-top: 8px;

  &.PhoneInput--focus {
    border: none;
    outline: none;
  }

  input {
    font-size: 18px;
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.42);
    background: none;
    transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

    &:focus,
    &:hover {
      outline: none;
      border-bottom: 2px solid rgba(0, 0, 0, 0.87);
    }
  }
`;

export const PhoneInputLabel = styled.div`
  font-size: 11px;
  color: rgba(0, 0, 0, 0.5);
`;

export const PhoneInputError = styled.div`
  font-size: 12px;
  color: #d32f2f;
  letter-spacing: 0.6px;
`;

export const StyledDatePicker = styled(DatePicker)`
  width: 100%;
  font-size: 18px;
  margin-top: 8px;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
  background: none;
  transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  &:focus,
  &:hover {
    outline: none;
    border-bottom: 2px solid rgba(0, 0, 0, 0.87);
  }

  &::placeholder {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.5);
    letter-spacing: 0.4px;
  }
`;
