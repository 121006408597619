import { CustomCard } from "components/Cards";
import { P } from "components/Fonts";
import {
  IconWrapper,
  PercentageWrapper,
  RevenueWrapper,
  TitleWrapper,
} from "./styles";
import {
  IconInCommingTransfer,
  IconOutCommingTransfer,
} from "components/Banking/TransactionIcon";
import { moneyFormatter } from "utils/formatters";
import { IconProps, Props, PropsPercentaje, RevenueProps } from "./types";
import { useMediaQuery } from "@mui/material";
import utils from "utils";

/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */

export default ({ data }: Props): JSX.Element => {
  const isMobile = useMediaQuery(`(max-width:${utils.screenSize.mobile})`);

  return (
    <CustomCard
      shadow="xstrong"
      borderRadius="strong"
      styles={{ width: "inherit", height: isMobile ? "auto" : "384px" }}
      padding={isMobile ? 1 : 2}
    >
      <TitleWrapper>
        <P size="large">Overview</P>
        <P size="small" colorOpacity="50%">
          This Week
        </P>
      </TitleWrapper>

      <Revenue {...data.incoming} as="incoming" />
      <Revenue {...data.outgoing} as="outgoing" />
    </CustomCard>
  );
};

const getPlusOrMinus = (as: "incoming" | "outgoing") => {
  if (as === "incoming") return "+";
  if (as === "outgoing") return "-";
};

const Revenue: React.FC<RevenueProps> = ({ revenue, percentaje, as }) => {
  // const isMobile = useMediaQuery(`(max-width:${utils.screenSize.mobile})`);
  const isPositive = percentaje >= 0;

  return (
    <RevenueWrapper>
      <IconWrapper>
        <Icon as={as} />
      </IconWrapper>
      <div style={{ flex: "auto" }}>
        <P size="small" colorOpacity="30%" emphasis>
          {as.charAt(0).toUpperCase() + as.slice(1)}
        </P>
        <P size="large">
          {getPlusOrMinus(as)} ${moneyFormatter(revenue, false)}
        </P>
      </div>

      <PercentageWrapper isPositive={isPositive}>
        {getPlusOrMinus(as)}{" "}
        {new Intl.NumberFormat("en-US", { maximumFractionDigits: 2 }).format(
          percentaje
        )}{" "}
        %
        <SVGPercentaje isPositive={isPositive} />
      </PercentageWrapper>
    </RevenueWrapper>
  );
};

const Icon: React.FC<IconProps> = ({ as }) =>
  as === "incoming" ? <IconInCommingTransfer /> : <IconOutCommingTransfer />;

const SVGPercentaje: React.FC<PropsPercentaje> = ({ isPositive }) => {
  if (isPositive) return <SVGpercentajePositive />;
  return <SVGpercentajeNegative />;
};

const SVGpercentajePositive = () => (
  <svg width="12" height="8" viewBox="0 0 12 8">
    <path
      d="M11.4495 0.532296C11.4265 0.470281 11.3932 0.414829 11.3518 0.368423C11.3491 0.3654 11.3464 0.364885 11.3441 0.362377C11.3035 0.31951 11.2571 0.283722 11.2049 0.259511C11.1621 0.239847 11.117 0.233801 11.072 0.229254C11.0585 0.228246 11.0472 0.221191 11.0337 0.221191H7.42937C7.18023 0.221191 6.97882 0.446563 6.97882 0.725395C6.97882 1.00423 7.18021 1.2296 7.42937 1.2296H9.95059L6.38411 5.24349L5.02709 3.29931C4.9487 3.18688 4.832 3.1173 4.70586 3.1067C4.57476 3.09611 4.454 3.14603 4.3621 3.24334L0.901969 6.90935C0.721294 7.10095 0.71365 7.4201 0.884398 7.62229C0.973615 7.72665 1.09255 7.77908 1.21195 7.77908C1.32324 7.77908 1.43451 7.73319 1.52146 7.64144L4.62161 4.35661L5.9881 6.31438C6.06785 6.42882 6.18724 6.49892 6.31654 6.50748C6.44585 6.51554 6.57155 6.4621 6.663 6.35975L10.5831 1.94755V4.75895C10.5831 5.03776 10.7845 5.26315 11.0337 5.26315C11.2828 5.26315 11.4842 5.03778 11.4842 4.75895V0.725418V0.724903C11.4838 0.658857 11.472 0.593819 11.4495 0.532296Z"
      fill={utils.colors.gold}
    />
  </svg>
);
const SVGpercentajeNegative = () => (
  <svg width="11" height="8" viewBox="0 0 11 8">
    <path
      d="M0.0765733 7.46771C0.0995478 7.52972 0.132889 7.58517 0.174335 7.63158C0.177037 7.6346 0.179739 7.63512 0.182 7.63762C0.222546 7.68049 0.268956 7.71628 0.321209 7.74049C0.364016 7.76015 0.409064 7.7662 0.454113 7.77075C0.467621 7.77175 0.478889 7.77881 0.492418 7.77881H4.09673C4.34587 7.77881 4.54728 7.55344 4.54728 7.27461C4.54728 6.99578 4.34589 6.77041 4.09673 6.77041H1.57551L5.142 2.75653L6.49903 4.7007C6.57742 4.81313 6.69411 4.88272 6.82025 4.89331C6.95135 4.9039 7.07211 4.85398 7.16401 4.75667L10.6242 1.09067C10.8048 0.899075 10.8125 0.579932 10.6417 0.377741C10.5525 0.273376 10.4336 0.220947 10.3142 0.220947C10.2029 0.220947 10.0916 0.266837 10.0047 0.358593L6.9045 3.64341L5.53801 1.68565C5.45826 1.5712 5.33887 1.5011 5.20956 1.49255C5.08026 1.48449 4.95456 1.53792 4.8631 1.64027L0.942965 6.05246V3.24107C0.942965 2.96226 0.741577 2.73687 0.492418 2.73687C0.24328 2.73687 0.0418704 2.96224 0.0418704 3.24107V7.27458V7.2751C0.0423311 7.34115 0.0540595 7.40618 0.0765733 7.46771Z"
      fill="#F8839F"
    />
  </svg>
);
