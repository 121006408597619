import {
  configureStore,
  ThunkAction,
  Action,
  combineReducers,
} from "@reduxjs/toolkit";
import { formSignUpStepsReducer, formSubmitReducer } from "./formSlice";
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import apiReducer from "./apiSlice";
import authReducer from "./authSlice";
import authBankingReducer from "./authBankingSlice";
import autoMergeLevel2 from "redux-persist/es/stateReconciler/autoMergeLevel2";
import { emptySplitAuthApi } from "services/empty-auth-api";
import { bankingApi, doolaBankingApi } from "services/banking-api";

const rootReducer = combineReducers({
  formSignUpSteps: formSignUpStepsReducer,
  formSubmit: formSubmitReducer,
  auth: authReducer,
  formApi: apiReducer,
  authBanking: authBankingReducer,
  [bankingApi.reducerPath]: bankingApi.reducer,
  [doolaBankingApi.reducerPath]: doolaBankingApi.reducer,
  [emptySplitAuthApi.reducerPath]: emptySplitAuthApi.reducer,
});

const persistConfig = {
  key: "root",
  version: 1,
  storage,
  stateReconciler: autoMergeLevel2,
};

const persistedReducer = persistReducer<ReturnType<typeof rootReducer>>(
  persistConfig,
  rootReducer
);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat([
      emptySplitAuthApi.middleware,
      bankingApi.middleware,
      doolaBankingApi.middleware,
    ]),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
