import { Box, CircularProgress } from "@mui/material";
import { P } from "components/Fonts";

type Props = {
  value: number;
};

export const ChartProgress = ({ value }: Props) => {
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress
        variant="determinate"
        value={100}
        sx={{ color: "#1E1E1E15" }}
        size={60}
        thickness={4}
      />
      <CircularProgress
        variant="determinate"
        value={value}
        sx={{
          color: "#477C32",
          position: "absolute",
          left: 0,
        }}
        size={60}
        thickness={4}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <P size="xsmall" emphasis={true}>
          {`${Math.round(value)}%`}
        </P>
      </Box>
    </Box>
  );
};
