import { PersistedCompany } from "models/CompanyModel";
import { ICurrentUser } from "models/CustomerModel";
import { formatAddresForYouLLC } from "utils/formatters";

type Props = {
  user: ICurrentUser;
  company: PersistedCompany;
};

export const buildProfileData = ({ user, company }: Props) => {
  return {
    contactDetails: [
      {
        label: "Full Name",
        value: user.fullName,
      },
      {
        label: "Email address",
        value: user.email,
      },
      {
        label: "Phone number",
        value: user.phone || "",
      },
    ],
    companyInfo: [
      {
        label: "Company Name",
        value: company?.companyDetails?.name || "",
      },
      {
        label: "Entity type",
        value: company?.companyDetails?.businessType?.style || "",
      },
      {
        label: "Activity",
        value: company?.companyDetails?.activity || "",
      },
      {
        label: "Description",
        value: company?.companyDetails?.description || "",
      },
      {
        label: "Website",
        value: company?.companyDetails?.url || "",
      },
    ],
    mailingAddress: [
      {
        label: "Address line 1",
        value: company?.applicantAddress?.address1 || "",
      },
      {
        label: "Address line 2",
        value: company?.applicantAddress?.address2 || "",
      },
      {
        label: "City",
        value: company?.applicantAddress?.city || "",
      },
      {
        label: "State",
        value: company?.applicantAddress?.state || "",
      },
      {
        label: "Zip / Postal code",
        value: company?.applicantAddress?.postalCode || "",
      },
      {
        label: "Country",
        value: company?.applicantAddress?.country || "",
      },
      {
        label: "Address for your new company",
        value: formatAddresForYouLLC(
          company?.companyDetails?.addressType || ""
        ),
      },
    ],
    extraData: [
      {
        label: "SSN or ITIN",
        value:
          company?.companyDetails?.SSN ||
          company?.companyDetails?.ITIN ||
          "None",
      },
    ],
    memberData: (company?.companyDetails?.owners || []).map(
      (e: any, i: number) => {
        const data = [
          {
            label: "Full name",
            value: e.fullName,
          },
          {
            label: "Ownership",
            value: `${e.ownership}%`,
          },
          {
            label: "Is a company",
            value: e.isCompany ? "yes" : "no",
          },
        ];
        return data;
      },
      []
    ),
  };
};
