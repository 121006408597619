import Grid from "@mui/material/Grid";
import { useEffect } from "react";
import {
  useGetAccountByIdAndTokenMutation,
  useGetAccountEndOfDayByIdAndTokenMutation,
  useGetTransactionsSinceByIdAndTokenMutation,
} from "services/banking-api";
import { ActivityChart } from "./ActivityChart";
import LatestPayments, { asType } from "./LatestPayments";
import { LoadingComponent } from "components/Loading";
import OverviewRevenue from "./OverviewRevenue";
import Balance from "./Balance";
import { useAuthBanking } from "../../../../hooks/useAuthBanking";
import { ApplicationStatus } from "./SignupModals";
import { TransactionObject } from "models/Transactions";
import { transformTransactions } from "../../../../utils/transactions";
import utils from "utils";
import { useMediaQuery } from "@mui/material";
import { BANKING_API_FIXTUTES } from "../SandBoxData/banking-api-fixtures";
import { DepositAccountResponse, TransactionsResponse } from "models/Requests";
/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */

type Props = {
  mockData?: {
    transactions: TransactionsResponse;
    account: DepositAccountResponse;
  };
  transactions: TransactionsResponse;
  account: DepositAccountResponse;
};

const BankingHome: React.FC<Props> = ({ transactions, account }) => {
  const isTablet = useMediaQuery(`(max-width:${utils.screenSize.tablet})`);
  const rowsData = transformTransactions(transactions.data).reverse();

  const mapTransactionsForHome = (data: TransactionObject[], asValue: string) =>
    data.map((item) => ({
      name: item.toFrom,
      amount: item.amount,
      date: item.date,
      as: asValue as asType,
    }));

  const incomingTransactions = rowsData.filter((item) => item.type === "in");
  const incoming = mapTransactionsForHome(incomingTransactions, "incoming");

  const outgoingTransactions = rowsData.filter((item) => item.type === "out");
  const outgoing = mapTransactionsForHome(outgoingTransactions, "outgoing");

  const revenueReducer = (acc: number, value: TransactionObject) =>
    acc + value.amount;
  const revenueIncoming = incomingTransactions.reduce(revenueReducer, 0);
  const revenueOutgoing = outgoingTransactions.reduce(revenueReducer, 0);

  const balance = account.attributes.available / 100;

  const revenueData = {
    incoming: {
      revenue: revenueIncoming,
      percentaje: (revenueIncoming / balance) * 100 || 0,
    },
    outgoing: {
      revenue: revenueOutgoing,
      percentaje: (revenueOutgoing / balance) * 100 || 0,
    },
  };

  return (
    <Grid container spacing={3} mt={5}>
      {/* <Countdown end={tokenExpiration} /> */}
      <Grid item lg={5} xs={12} mt={isTablet ? 4 : 0}>
        <Balance
          balance={balance}
          routingNumber={account.attributes.routingNumber}
          accountNumber={account.attributes.accountNumber}
        />
      </Grid>
      <Grid item lg={7} xs={12}>
        <ActivityChart dates={transactions.data} />
      </Grid>
      <Grid item lg={5} xs={12}>
        <OverviewRevenue data={revenueData} />
      </Grid>
      <Grid item lg={7} xs={12}>
        <Grid container spacing={3}>
          <Grid item lg={6} xs={12}>
            <LatestPayments
              tableData={incoming.slice(0, 4)}
              title="Latest incoming payments"
            />
          </Grid>

          <Grid item lg={6} xs={12}>
            <LatestPayments
              tableData={outgoing.slice(0, 4)}
              title="Latest outgoing payments"
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const MOCK_DATA = {
  transactions: BANKING_API_FIXTUTES.getTransactionsSinceByIdAndToken as any,
  account: BANKING_API_FIXTUTES.getAccountByIdAndToken.data as any,
};

export default (): JSX.Element => {
  const { currentAccountId, currentForm, currentState, currentSandboxMode } =
    useAuthBanking();

  if (currentSandboxMode)
    return (
      <>
        <BankingHome
          transactions={MOCK_DATA.transactions}
          account={MOCK_DATA.account}
        />
        <ApplicationStatus signUpURL={currentForm} status={currentState} />
      </>
    );

  const [getAccountByIdAndToken, { data: accountData }] =
    useGetAccountByIdAndTokenMutation();

  const [getAccountEndOfDayByIdAndToken] =
    useGetAccountEndOfDayByIdAndTokenMutation();

  const [getTransactionsSinceByIdAndToken, { data: transactionsListData }] =
    useGetTransactionsSinceByIdAndTokenMutation();

  useEffect(() => {
    const sevenDaysAgo = new Date(new Date().setDate(new Date().getDate() - 7));

    getAccountByIdAndToken({ accountId: currentAccountId });
    getAccountEndOfDayByIdAndToken({
      accountId: currentAccountId,
      sinceDate: sevenDaysAgo,
    });
    getTransactionsSinceByIdAndToken({
      accountId: currentAccountId,
      sinceDate: sevenDaysAgo,
      includeAccount: true,
    });
  }, [
    currentAccountId,
    getAccountByIdAndToken,
    getAccountEndOfDayByIdAndToken,
    getTransactionsSinceByIdAndToken,
  ]);

  if (!transactionsListData || !accountData) {
    return <LoadingComponent />;
  }

  return (
    <BankingHome transactions={transactionsListData} account={accountData} />
  );
};
