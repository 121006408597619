import { Box, Drawer, useMediaQuery, useTheme } from "@mui/material";
import { LoadingComponent } from "components/Loading";
import { useAuth } from "hooks/useAuth";
import utils from "utils";
import MiniDrawerStyled from "./MiniDrawerStyled";
import { useEffect, useMemo } from "react";
import useConfig from "hooks/useConfig";
import { ThemeMode } from "models/ThemeConfig";
import { sidebarWidth } from "config";
import SidebarHeader from "./SidebarHeader";
import SidebarContent from "./SidebarContent";

interface Props {
  open: boolean;
  window?: () => Window;
  handleDrawerToggle?: () => void;
}

export const SidebarWebApp = ({ open, handleDrawerToggle, window }: Props) => {
  const { currentCompany } = useAuth();
  const { onChangeMode } = useConfig();
  const theme = useTheme();
  const isBankingSelected = utils.isBankingRoute();
  const isDarkMode = isBankingSelected;

  const matchDownMD = useMediaQuery(theme.breakpoints.down("lg"));

  // responsive drawer container
  const container =
    window !== undefined ? () => window().document.body : undefined;

  useEffect(() => {
    onChangeMode((isBankingSelected ? "dark" : "light") as ThemeMode);
  }, [isBankingSelected]); // eslint-disable-line react-hooks/exhaustive-deps

  // header content
  const sidebarHeader = useMemo(
    () => <SidebarHeader isDarkMode={isDarkMode} />,
    [isDarkMode]
  );
  const sidebarContent = useMemo(
    () => <SidebarContent isBankingSelected={isBankingSelected} />,
    [isBankingSelected]
  );

  if (!currentCompany) return <LoadingComponent />;

  return (
    <Box component="nav" sx={{ flexShrink: { md: 0 }, zIndex: 1200 }}>
      {!matchDownMD ? (
        <MiniDrawerStyled variant="permanent" open={true}>
          {sidebarHeader}
          {sidebarContent}
        </MiniDrawerStyled>
      ) : (
        <Drawer
          container={container}
          variant="temporary"
          open={open}
          onClose={handleDrawerToggle}
          ModalProps={{ keepMounted: true }}
          sx={{
            display: { xs: "block", lg: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: sidebarWidth,
              borderRight: `1px solid ${theme.palette.divider}`,
              backgroundImage: "none",
              boxShadow: "inherit",
              background: isDarkMode ? "#1e1e1e" : "#fff",
            },
          }}
        >
          {sidebarHeader}
          {sidebarContent}
        </Drawer>
      )}
    </Box>
  );
};
