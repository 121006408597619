import {
  GetStateResponse,
  GetTokenResponse,
  GetVerificationResponse,
  Payment,
  PaymentResponse,
} from "models/Requests";
import { DBBaseItem } from "models/TableModel";
import { emptySplitAuthApi } from "./empty-auth-api";

export interface TokenRequest {
  scope: string;
}

export interface TokenVerification {
  token: string;
  code: string;
  companyId: DBBaseItem["id"];
}

export interface PaymentRequestInternationalCompany {
  accountId: string;
  amount: number;
  beneficiary: BeneficiaryIndividual | BeneficiaryCompany;
  description: string;
  token: string;
  companyId: DBBaseItem["id"];
}

interface Beneficiary {
  beneficiaryAddress: string;
  beneficiaryCity: string;
  beneficiaryCountry: string;
  bicSwift: string;
  account_number: string;
}

export interface BeneficiaryIndividual extends Beneficiary {
  beneficiaryEntityType: "individual";
  beneficiaryFirstName: string;
  beneficiaryLastName: string;
}

export interface BeneficiaryCompany extends Beneficiary {
  beneficiaryEntityType: "company";
  beneficiaryCompanyName: string;
}

// const BANKING_DEV_URL =
// "https://07olfs41sg.execute-api.eu-central-1.amazonaws.com/dev/banking-extra";

const BANKING_URL = `/banking/banking-extra/`;

export const bankingExtraApi = emptySplitAuthApi.injectEndpoints({
  endpoints: (builder) => ({
    getVerification: builder.mutation<
      GetVerificationResponse,
      DBBaseItem["id"]
    >({
      query: (companyId) => ({
        url: `${BANKING_URL}verification/${companyId}`,
        method: "GET",
      }),
      transformResponse: (res) => res as any,
    }),
    getTokenWithVerification: builder.mutation<
      GetTokenResponse,
      TokenVerification
    >({
      query: ({ code, token, companyId }) => ({
        url: `${BANKING_URL}token`,
        method: "POST",
        body: {
          token,
          code,
          companyId,
        },
      }),
      transformResponse: (res) => res as any,
    }),
    getCompanyState: builder.mutation<GetStateResponse, DBBaseItem["id"]>({
      query: (companyId) => ({
        url: `${BANKING_URL}state`,
        method: "POST",
        body: {
          companyId,
        },
      }),
      transformResponse: (res) => res as any,
    }),
    createNewApplication: builder.mutation<GetStateResponse, DBBaseItem["id"]>({
      query: (companyId) => ({
        url: `${BANKING_URL}createNewApplication`,
        method: "POST",
        body: {
          companyId,
        },
      }),
      transformResponse: (res) => res as any,
    }),
    sendPaymentInternationalTransferToCompany: builder.mutation<
      Payment,
      PaymentRequestInternationalCompany
    >({
      query: ({
        accountId,
        amount,
        beneficiary,
        description,
        token,
        companyId,
      }) => ({
        url: `${BANKING_URL}international-payment`,
        method: "POST",
        body: JSON.stringify({
          accountId,
          amount: amount.toString(),
          beneficiary,
          description,
          token,
          companyId,
        }),
      }),
      transformResponse: (res: PaymentResponse) => res.data,
    }),
  }),
});

export const {
  useGetVerificationMutation,
  useGetTokenWithVerificationMutation,
  useGetCompanyStateMutation,
  useSendPaymentInternationalTransferToCompanyMutation,
  useCreateNewApplicationMutation,
} = bankingExtraApi;
