import styled, { css } from "styled-components/macro";

export const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
  padding-bottom: 70%;

  img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: 50% 50%;
  }
`;

export const CustomWrapper = css`
  width: 210px;
  height: 250px;
  margin: auto;
  &:hover {
    cursor: pointer;
    > div > .content-text {
      color: white;
    }
  }
`;
