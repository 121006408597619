import styled, { css } from "styled-components/macro";
import { TableCellProps } from "./types";

export const TableHeaderWrapper = styled.div`
  display: flex;
  border-bottom: 1px solid #dddddd;
  // justify-content: space-between;
  background-color: white;
  padding: 15px;
  // width: 100%;
`;

type TableRowWrapperProps = {
  hoverColor?: string;
};
export const TableRowWrapper = styled.div<TableRowWrapperProps>`
  display: flex;
  border-bottom: 1px solid #dddddd;
  // justify-content: space-between;
  background-color: white;
  // width: 100%;
  padding: 15px;
  align-items: center;
  position: relative;

  ${(props) => {
    if (props.hoverColor) {
      return `:hover {
        background: ${props.hoverColor};
      }`;
    }
  }};
`;

export const TableCellWrapper = styled.div<TableCellProps>`
  display: flex;
  // min-width: ${(props) => props.minWidth};

  ${(props) => {
    if (props.onClick) {
      return `cursor: pointer`;
    }
  }};

  ${(props) => {
    if (props.maxWidth) {
      return css`
        max-width: ${props.maxWidth};
      `;
    }
  }};
  ${(props) => {
    if (props.justifyContent) {
      return css`
        justify-content: ${props.justifyContent};
      `;
    }
  }};

  width: -webkit-fill-available;
`;

export const SVGCardMiniWrapper = styled.div`
  margin-right: 10px;
`;

export const NoDataYetWrapper = styled.div`
  height: 90%;
  display: flex;
  padding-top: 20px;
`;
