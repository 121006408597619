import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { formsApi } from "services/forms-api";
import type { RootState } from "./store";

type ApiState = {
  customerId: string;
  companyId: string;
  cognitoTempPass: string;
};

const initialState: ApiState = {
  customerId: "",
  companyId: "",
  cognitoTempPass: "",
};

const apiSlice = createSlice({
  name: "formApi",
  initialState: initialState,
  reducers: {
    removeIds: (state) => initialState,
    setCognitoTempPass: (state, action: PayloadAction<string>) => {
      state.cognitoTempPass = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      formsApi.endpoints.createOrUpdateCustomerFromForm.matchFulfilled,
      (state, { payload }) => {
        state.customerId = payload.id;
      }
    );
    builder.addMatcher(
      formsApi.endpoints.createOrUpdateCompanyFromForm.matchFulfilled,
      (state, { payload }) => {
        state.companyId = payload.id;
      }
    );
  },
});

export const { removeIds, setCognitoTempPass } = apiSlice.actions;

export default apiSlice.reducer;

export const selectCompanyCustomerIds = (state: RootState) => [
  state.formApi.companyId,
  state.formApi.customerId,
];
export const selectCognitoTempPass = (state: RootState) =>
  state.formApi.cognitoTempPass;
