import styled, { css } from "styled-components/macro";

export const LabelWrapper = styled.div<{
  color: string;
  fontColor?: string;
  size: string;
  bold?: boolean;
  margin?: string;
}>`
  background-color: ${({ color }) => color};
  display: inline;
  border-radius: 4px;
  font-weight: ${({ bold }) => (bold ? "bold" : 500)};
  color: ${({ fontColor }) => fontColor ?? "black"};
  ${({ size }) => {
    switch (size) {
      case "large":
        return largeStyled;
      case "medium":
        return mediumStyled;
      case "small":
        return smallStyled;
    }
  }};
`;

const smallStyled = css`
  font-size: 11px;
  padding: 5px 10px;
`;

const mediumStyled = css`
  font-size: 12px;
  padding: 5px 15px;
`;
const largeStyled = css`
  font-size: 14px;
  padding: 5px 15px;
`;
