/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */

import {
  IconInCommingTransfer,
  IconOutCommingTransfer,
} from "components/Banking/TransactionIcon";
import { P } from "components/Fonts";
import { Label } from "components/Label";
import { useAuthBanking } from "hooks/useAuthBanking";
import React from "react";
import { useState } from "react";
import utils from "utils";
import { formatDate, moneyFormatter } from "utils/formatters";
import { AccountSelector } from "../../SharedComponents/AccountSelector";
import { TransactionTableProps } from "../types";
import {
  AcordionIconSVGWrapper,
  BalanceWrapper,
  MoreDetailsWrapper,
  NoDataYetWrapper,
  TableCellWrapper,
  TableHeaderWrapper,
  TableRowWrapper,
  TableSettingWrapper,
} from "./styles";
import {
  RowType,
  TableCellProps,
  TableItem,
  TableItemMoreDetails,
} from "./types";

export default ({
  rowsData,
  accountData,
}: TransactionTableProps): JSX.Element => {
  const [filteredData, setFilteredData] = useState(rowsData);
  const { currentSandboxMode } = useAuthBanking();

  const accountNumberLast4Digits = accountData?.number
    ? accountData.number.substr(accountData.number.length - 4)
    : "";

  return (
    <>
      <TableSetting
        accountData={accountData}
        handlefilter={(data) => setFilteredData(data)}
        rowsData={rowsData}
      />
      <TableHeader />
      {filteredData.map((rowData) => (
        <TableRow
          key={rowData.id}
          {...rowData}
          account={`Checking ••••${accountNumberLast4Digits}`}
          isSandbox={currentSandboxMode}
        />
      ))}

      {filteredData.length === 0 && <NoDataYet />}
    </>
  );
};

type TableSettingProps = {
  handlefilter: (data: TableItem[]) => void;
} & TransactionTableProps;

const TableSetting: React.FC<TableSettingProps> = ({
  accountData,
  handlefilter,
  rowsData,
}) => {
  const { number, balance } = accountData;
  const [selected, setSelected] = useState<"in" | "out" | undefined>(undefined);

  const handleFilterClickIcons = (type: "in" | "out") => {
    if (type === selected) {
      setSelected(undefined);
      handlefilter(rowsData);
    } else {
      setSelected(type);
      handlefilter(rowsData.filter((row) => type === row.type));
    }
  };

  return (
    <TableSettingWrapper>
      <div style={{ display: "flex" }}>
        <BalanceWrapper>
          <P
            size={"xsmall"}
            colorOpacity="50%"
            style={{ paddingBottom: "5px" }}
          >
            Available Balance
          </P>
          <P size="large" emphasis>
            ${moneyFormatter(balance, false)}
          </P>
        </BalanceWrapper>
        <BalanceWrapper>
          <AccountSelector number={number} />
        </BalanceWrapper>
      </div>
      <div>
        <span
          onClick={() => handleFilterClickIcons("out")}
          style={{ cursor: "pointer" }}
        >
          <IconOutCommingTransfer selected={selected === "out"} />
        </span>
        &nbsp;
        <span
          onClick={() => handleFilterClickIcons("in")}
          style={{ cursor: "pointer" }}
        >
          <IconInCommingTransfer selected={selected === "in"} />
        </span>
      </div>
    </TableSettingWrapper>
  );
};

const TableHeader: React.FC = () => {
  return (
    <TableHeaderWrapper>
      <TableCell maxWidth="50px">
        <P size={"xsmall"} colorOpacity="50%">
          Type
        </P>
      </TableCell>
      <TableCell minWidth="250px">
        <P size={"xsmall"} colorOpacity="50%">
          To/From
        </P>
      </TableCell>
      <TableCell maxWidth="250px">
        <P size={"xsmall"} colorOpacity="50%">
          Id
        </P>
      </TableCell>
      <TableCell maxWidth="100px">
        <P size={"xsmall"} colorOpacity="50%">
          Date
        </P>
      </TableCell>
      <TableCell>
        <P size={"xsmall"} colorOpacity="50%">
          Account
        </P>
      </TableCell>
      <TableCell>
        <P size={"xsmall"} colorOpacity="50%">
          Summary
        </P>
      </TableCell>
      <TableCell>
        <P size={"xsmall"} colorOpacity="50%">
          Payment Method
        </P>
      </TableCell>
      <TableCell maxWidth="100px" justifyContent={"right"}>
        <P size={"xsmall"} emphasis>
          Amount
        </P>
      </TableCell>

      <TableCell maxWidth="100px" justifyContent={"center"}>
        <P size={"xsmall"} colorOpacity="50%">
          Status
        </P>
      </TableCell>
    </TableHeaderWrapper>
  );
};

const formatAmount = (type: RowType, amount: number) => {
  const prefix = type === "in" ? "$" : "-$";
  return `${prefix}${moneyFormatter(amount, false)}`;
};

export interface TableRowProps {
  id: string;
  type: RowType;
  toFrom: string;
  date: string;
  account: string;
  paymentMethod: string;
  summary: string;
  amount: number;
  status: string;
  moreDetails: TableItemMoreDetails;
  isSandbox: boolean;
}

const TableRow: React.FC<TableRowProps> = ({
  type,
  id,
  toFrom,
  date,
  account,
  paymentMethod,
  amount,
  status,
  summary,
  moreDetails,
  isSandbox,
}) => {
  const [opened, setOpened] = useState(false);

  return (
    <>
      <TableRowWrapper onClick={() => isSandbox && setOpened(!opened)}>
        {isSandbox && (
          <AcordionIconSVGWrapper rotated={opened}>
            <AcordionIconSVG />
          </AcordionIconSVGWrapper>
        )}

        <TableCell maxWidth="50px">
          <P size={"small"}>
            <TransactionLogo type={type} />
          </P>
        </TableCell>
        <TableCell minWidth="250px">
          <P size={"small"} emphasis>
            {toFrom}
          </P>
        </TableCell>
        <TableCell maxWidth="200px">
          <P size={"small"} emphasis>
            {id}
          </P>
        </TableCell>
        <TableCell maxWidth="100px">
          <P size={"small"} colorOpacity="50%">
            {formatDate({ date })}
          </P>
        </TableCell>
        <TableCell>
          <P size={"small"} colorOpacity="50%">
            {account}
          </P>
        </TableCell>
        <TableCell>
          <P size={"small"} colorOpacity="50%">
            {summary}
          </P>
        </TableCell>
        <TableCell>
          <P size={"small"} colorOpacity="50%">
            {paymentMethod}
          </P>
        </TableCell>
        <TableCell maxWidth="100px" justifyContent={"right"}>
          <P size={"small"} emphasis>
            {formatAmount(type, amount)}
          </P>
        </TableCell>
        <TableCell maxWidth="100px" justifyContent={"center"}>
          <P size={"small"}>
            <Label color={`${utils.colors.gold}50`} text={status} />
          </P>
        </TableCell>
      </TableRowWrapper>
      {opened && <MoreDetails {...moreDetails} />}
    </>
  );
};

const MoreDetails: React.FC<TableItemMoreDetails> = ({
  bankName,
  IBAN,
  reference1,
  reason,
  reference2,
  pdfDocument,
}) => {
  return (
    <MoreDetailsWrapper>
      <P size={"small"} colorOpacity="50%">
        {bankName}
      </P>
      <P size={"small"} colorOpacity="50%">
        {IBAN}
      </P>
      <P size={"small"} colorOpacity="50%">
        {reference1}
      </P>
      <P size={"small"} colorOpacity="50%">
        {reason}
      </P>
      <P size={"small"} colorOpacity="50%">
        {reference2}
      </P>
    </MoreDetailsWrapper>
  );
};

const TransactionLogo = ({ type }: { type: "in" | "out" | string }) => {
  if (type === "in") return <IconInCommingTransfer />;
  return <IconOutCommingTransfer />;
};

const TableCell: React.FC<TableCellProps> = (props) => {
  return <TableCellWrapper {...props}>{props.children}</TableCellWrapper>;
};

const AcordionIconSVG = () => (
  <svg width="8" height="8" viewBox="0 0 6 4" fill="none">
    <path
      d="M5.91988 0.56051L3.21119 3.89299C3.101 4.03567 2.899 4.03567 2.78881 3.89299L0.0801207 0.56051C-0.0943374 0.346497 0.0342106 0 0.291307 0L5.70869 0C5.96579 0 6.09434 0.346497 5.91988 0.56051Z"
      fill="black"
    />
  </svg>
);

const NoDataYet: React.FC = () => (
  <NoDataYetWrapper>
    <P size="small" colorOpacity="50%" margin="auto">
      No transactions yet
    </P>
  </NoDataYetWrapper>
);
