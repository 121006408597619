import { DBBaseItem } from "models/TableModel";
import { MailStates } from "views/DashboardApp/Mail/types";
import { emptySplitAuthApi } from "./empty-auth-api";

const PAYMENTS_URL = `/payments`;

interface GenerateMailRoomStripeLinkPayload {
  companyId: DBBaseItem["id"];
  state?: MailStates;
  monthly: boolean;
}

interface GenerateMailRoomStripeLinkResponse {
  url: string;
}

interface GenerateTaxComplianceBundleLinkResponse {
  url: string;
}

interface GetStripeCustomerPortalLinkPayload {
  customerId: string;
  returnUrl: string;
}

interface GetStripeCustomerPortalLinkResponse {
  returnUrl: string;
  url: string;
}

interface LookupPayPalProductPayload {
  data: {
    basePlan: Array<string>;
    addOns: Array<string>;
    stateFee: null | string;
  };
}

interface LookupPayPalProductResponse {
  payPalPlanId: string;
}

export const paymentsApi = emptySplitAuthApi.injectEndpoints({
  endpoints: (builder) => ({
    generateMailRoomStripeLink: builder.mutation<
      GenerateMailRoomStripeLinkResponse,
      GenerateMailRoomStripeLinkPayload
    >({
      query: ({ companyId, state, monthly }) => ({
        url: `${PAYMENTS_URL}/vendors/stripe/link/mailroom/?companyId=${companyId}&state=${state}&monthly=${monthly}`,
        method: "GET",
      }),
      transformResponse: (res) => res as any,
    }),
    generateTaxComplianceBundleLink: builder.mutation<
      GenerateTaxComplianceBundleLinkResponse,
      string
    >({
      query: (companyId) => ({
        url: `${PAYMENTS_URL}/vendors/stripe/taxcompliancebundle/${companyId}`,
        method: "GET",
      }),
      transformResponse: (res) => res as any,
    }),
    getStripeCustomerPortalLink: builder.mutation<
      GetStripeCustomerPortalLinkResponse,
      GetStripeCustomerPortalLinkPayload
    >({
      query: ({ customerId, returnUrl }) => ({
        url: `${PAYMENTS_URL}/vendors/stripe/portal`,
        method: "POST",
        body: { customerId, returnUrl },
      }),
    }),
    lookupPayPalProduct: builder.mutation<
      LookupPayPalProductResponse,
      LookupPayPalProductPayload
    >({
      query: (plan) => ({
        url: `${PAYMENTS_URL}/v1/vendors/paypal/lookup-product`,
        method: "POST",
        body: plan,
      }),
    }),
  }),
});

export const {
  useGenerateMailRoomStripeLinkMutation,
  useGenerateTaxComplianceBundleLinkMutation,
  useGetStripeCustomerPortalLinkMutation,
  useLookupPayPalProductMutation,
} = paymentsApi;
