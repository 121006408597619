import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "./store";

export type ApplicationStatuses =
  | "READY"
  | "APPLICATION_MISSING"
  | "APPLICATION_PENDING"
  | "AWAITING_DOCUMENTS"
  | "APPLICATION_CANCELED"
  | "APPLICATION_DENIED";

type InitialStateTypes = {
  accountId: string;
  customerId: string;
  token: string;
  initialized: boolean;
  state: ApplicationStatuses;
  form?: string;
  sandboxMode: boolean;
};

const initialState: InitialStateTypes = {
  accountId: "",
  customerId: "",
  token: "",
  initialized: false,
  form: "",
  state: "APPLICATION_PENDING",
  sandboxMode: true,
};

const authBankingSlice = createSlice({
  name: "authBanking",
  initialState: initialState,
  reducers: {
    setInit: (
      state,
      action: PayloadAction<{
        state: ApplicationStatuses;
        accountId: string;
        customerId: string;
        token: string;
        form?: string;
      }>
    ) => {
      state.accountId = action.payload.accountId;
      state.customerId = action.payload.customerId;
      state.token = action.payload.token;
      state.initialized = true;
      state.state = action.payload.state;
      state.form = action.payload.form;
      state.sandboxMode = isInSandboxMode(action.payload.state);
    },
    setForm: (
      state,
      action: PayloadAction<{
        state: ApplicationStatuses;
        form?: string;
      }>
    ) => {
      state.accountId = "";
      state.state = action.payload.state;
      state.form = action.payload.form;
      state.sandboxMode = isInSandboxMode(action.payload.state);
    },
  },
});

export const { setInit, setForm } = authBankingSlice.actions;

export default authBankingSlice.reducer;

export const selectAccountId = (state: RootState) =>
  state.authBanking.accountId;
export const selectCustomerId = (state: RootState) =>
  state.authBanking.customerId;
export const selectToken = (state: RootState) => state.authBanking.token;
export const selectForm = (state: RootState) => state.authBanking.form;
export const selectState = (state: RootState) => state.authBanking.state;
export const selectSandboxMode = (state: RootState) =>
  state.authBanking.sandboxMode;
export const selectInitialized = (state: RootState) =>
  state.authBanking.initialized;

const isInSandboxMode = (state: ApplicationStatuses) => {
  if (state === "READY") return false;
  return true;
};
