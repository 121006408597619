import DoolaShortLogo from "./DoolaShort";
import { StyledLogo } from "./styles";

export { DoolaShortLogo };

export const Logo: React.FC<{
  width?: number;
  $darkMode?: boolean;
  $saveWidth?: boolean;
}> = ({ width, $darkMode, $saveWidth = false }) => {
  return (
    <StyledLogo width={width} $darkMode={$darkMode} $saveWidth={$saveWidth} />
  );
};
