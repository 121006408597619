import React, { FunctionComponent, useEffect } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import { StepIconProps } from "@mui/material";
import {
  StyledComplete,
  StyledCurrent,
  StyledPending,
  StyledFooterWrapper,
} from "./styles";
import { VerticalLinearStepperProps } from "./types";
import { P } from "components/Fonts";
import { WrappFadeAnimation } from "assets/GlobalStyled";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import UpdateIcon from "@mui/icons-material/Update";
import VerifiedIcon from "@mui/icons-material/Verified";
import SpeedIcon from "@mui/icons-material/Speed";

function ColorlibStepIcon(props: StepIconProps) {
  const { active, completed } = props;

  const icons: { [state: string]: React.ReactElement } = {
    completed: (
      <StyledComplete>
        <VerifiedIcon />
      </StyledComplete>
    ),
    current: (
      <StyledCurrent>
        <UpdateIcon />
      </StyledCurrent>
    ),
    pending: (
      <StyledPending>
        <WatchLaterIcon />
      </StyledPending>
    ),
  };

  return completed
    ? icons["completed"]
    : active
    ? icons["current"]
    : icons["pending"];
}

export const VerticalLinearStepper: FunctionComponent<
  VerticalLinearStepperProps
> = ({ stepData }) => {
  const calculateCurrentStepIndex = () => {
    const firstNotDoneIndex = stepData
      .map(({ isDone }) => isDone)
      .findIndex((isDone) => !isDone);
    return firstNotDoneIndex !== -1 ? firstNotDoneIndex : stepData.length;
  };

  const currentStepIndex = calculateCurrentStepIndex();

  const [activeStep, setActiveStep] = React.useState(currentStepIndex);

  useEffect(() => {
    setActiveStep(currentStepIndex);
  }, [currentStepIndex]);

  return (
    <Box>
      <Stepper activeStep={activeStep} orientation="vertical">
        {stepData.map((step) => (
          <Step key={step.label}>
            <StepLabel StepIconComponent={ColorlibStepIcon}>
              <P size="small" emphasis>
                {step.label}
              </P>
            </StepLabel>
            <StepContent>
              <WrappFadeAnimation>
                <P size="small">{step.description}</P>
              </WrappFadeAnimation>
              <br />
              {step.footer && (
                <StyledFooterWrapper color={step.footer.color}>
                  <SpeedIcon />
                  <P size="xsmall" emphasis display="inline">
                    {step.footer.text}
                  </P>
                </StyledFooterWrapper>
              )}
            </StepContent>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};

export default VerticalLinearStepper;
