interface Props {
  isBlurred?: boolean;
}

const MainBackground = ({ isBlurred = false }: Props) => {
  return (
    <svg
      viewBox="0 0 1440 717"
      preserveAspectRatio="none"
      style={{
        position: "fixed",
        zIndex: -1,
        height: "100%",
        width: "100%",
        filter: isBlurred ? "blur(18px)" : "none",
      }}
    >
      <path d="M0 0H1440V717H0V0Z" fill="#FFF9EB" />
      <path
        d="M0 618.5C236.662 490.857 249 328.099 738 288C1227 247.901 1122.9 39.7332 1440 0V717H0V618.5Z"
        fill="url(#paint0_linear)"
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="720"
          y1="0"
          x2="720"
          y2="717"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFF6E3" />
          <stop offset="1" stopColor="#EBD3A0" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default MainBackground;
