import { gql } from "@apollo/client/core";

export const CURRENT_USER = gql`
  query currentUser {
    currentUser @rest(type: "User", method: GET, path: "/customers/account") {
      id @export(as: "customerId")
      email
      phone
      fullName
      companyIds
      typeforms {
        membersAndAddress
      }
      referralData
        @rest(
          type: "ReferralData"
          method: GET
          path: "/customers/referral/{exportVariables.customerId}"
        ) {
        link
      }
    }
  }
`;
