import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState, AppThunk } from "./store";
import stateKeys from "resources/stateKeys";
import { FormSubmitState } from "models/FormFields";
import { SignupChatSteps } from "models/SignUpSteps";
import entityTypes, { BusinessTypeNames } from "resources/entityTypes";
import { PricingPackagesData } from "resources/pricePackages";
import { IPricingPackage, PricingPackagesIds } from "models/PricePackages";

// A mock function to mimic making an async request for data
export function fetchCount(amount = 1) {
  return new Promise<{ data: number }>((resolve) =>
    setTimeout(() => resolve({ data: amount }), 500)
  );
}

export interface FormStepState {
  step: number;
  lastValidStep: number;
  highestStepReached: number;
  status: "idle" | "loading" | "failed" | "paying";
}

const initialStateFormSteps: FormStepState = {
  step: 0,
  lastValidStep: 1,
  highestStepReached: 0,
  status: "idle",
};

export const initialFormSubmitState: FormSubmitState = {
  step: 0,
  status: "idle",
  valid: false,
  fullName: "",
  email: "",
  phone: "",
  companyState: "Wyoming",
  companyStateAbbreviated: "WY",
  companyName: "",
  entityType: "",
  entityTypeStyle: "",
  referredBy: "",
  companyActivity: "",
  companyActivityOther: "",
  companyURL: "",
  planSelected: PricingPackagesData.companyFormationPackage,
  plansSelected: {
    [PricingPackagesIds.companyFormationPackage]:
      PricingPackagesData.companyFormationPackage,
  },
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(nextStepAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const nextStepAsync = createAsyncThunk(
  "form/fetchCount",
  async (amount: number) => {
    const response = await fetchCount(amount);
    // The value we return becomes the `fulfilled` action payload
    return response.data;
  }
);

export const formSignUpStepsSlice = createSlice({
  name: "formSignUpSteps",
  initialState: initialStateFormSteps,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    nextStep: (state) => {
      state.lastValidStep = state.step > 0 ? state.step : 1;
      state.step += 1;
    },
    previousStep: (state) => {
      state.step -= 1;
    },
    // Use the PayloadAction type to declare the contents of `action.payload`
    setStep: (state, action: PayloadAction<number>) => {
      if (action.payload === SignupChatSteps.Success) {
        state.step = SignupChatSteps.Success;
      } else if (state.lastValidStep + 1 >= action.payload) {
        state.step = action.payload;
      }
    },
    setHighestReachedStep: (state, action: PayloadAction<number>) => {
      if (state.highestStepReached < action.payload) {
        state.highestStepReached = action.payload;
      }
    },
    resetStep: (state) => initialStateFormSteps,
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(nextStepAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(nextStepAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.step += action.payload;
      });
  },
});

export const formSubmitSlice = createSlice({
  name: "formSubmit",
  initialState: { ...initialFormSubmitState },
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setStepOne: (state, action: PayloadAction<{ email: string }>) => {
      state.email = action.payload.email;
    },
    setStepTwo: (
      state,
      action: PayloadAction<{
        fullName: string;
        phone?: string;
        companyName: string;
        companyActivity: string;
        companyURL?: string;
        companyActivityOther?: string;
      }>
    ) => {
      state.fullName = action.payload.fullName;
      state.phone = action.payload.phone || "";
      state.companyName = action.payload.companyName;
      state.companyActivity = action.payload.companyActivity;
      if (action.payload.companyActivity === "Other") {
        state.companyActivityOther = action.payload.companyActivityOther || "";
      }
      state.companyURL = action.payload.companyURL || "";
    },
    setPlanSelected: (state, action: PayloadAction<any>) => {
      state.planSelected = action.payload;
    },
    setPlansSelected: (state, action: PayloadAction<IPricingPackage>) => {
      Object.keys(state.plansSelected).forEach((key) => {
        if (key === action.payload.id) {
          state.plansSelected[key] = action.payload;
        } else {
          state.plansSelected[action.payload.id] = action.payload;
        }
      });
    },
    removePlansSelected: (state, action: PayloadAction<IPricingPackage>) => {
      delete state.plansSelected[action.payload.id];
    },
    setCompanyStateSelected: (state, action: PayloadAction<string>) => {
      state.companyStateAbbreviated = action.payload;
      state.companyState = stateKeys[action.payload];
    },
    setCompanyEntityType: (state, action: PayloadAction<string>) => {
      state.entityType = action.payload;
      state.entityTypeStyle =
        entityTypes[action.payload as BusinessTypeNames].value;
    },
    setFormValidStatus: (state, action: PayloadAction<boolean>) => {
      state.valid = action.payload;
    },
    resetForm: (state) => ({ ...initialFormSubmitState }),
  },
});

export const {
  nextStep,
  previousStep,
  setStep,
  setHighestReachedStep,
  resetStep,
} = formSignUpStepsSlice.actions;

export const {
  setStepOne,
  setPlanSelected,
  setPlansSelected,
  removePlansSelected,
  setCompanyStateSelected,
  setCompanyEntityType,
  setStepTwo,
  resetForm,
  setFormValidStatus,
} = formSubmitSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.form.value)`
export const selectStateStatus = (state: RootState) =>
  state.formSignUpSteps.status;
export const selectStep = (state: RootState) => state.formSignUpSteps.step;
export const selectHighestReachedStep = (state: RootState) =>
  state.formSignUpSteps.highestStepReached;
export const selectLastValidStep = (state: RootState) =>
  state.formSignUpSteps.lastValidStep;
export const selectFormSubmit = (state: RootState) => state.formSubmit;
export const selectPlanSelected = (state: RootState) =>
  state.formSubmit.planSelected;
export const selectPlansSelected = (state: RootState) =>
  state.formSubmit.plansSelected;

export const selectIsAtCheckout = (state: RootState) =>
  state.formSignUpSteps.lastValidStep === SignupChatSteps.OrderSummary;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
export const previousStepIfValid = (): AppThunk => (dispatch, getState) => {
  const currentValue = selectStep(getState());
  if (currentValue !== 0) {
    dispatch(previousStep());
  }
};

export const formSignUpStepsReducer = formSignUpStepsSlice.reducer;
export const formSubmitReducer = formSubmitSlice.reducer;
