import { CustomCard } from "components/Cards";
import { FunctionComponent } from "react";
import { P } from "components/Fonts";
import { IconWrapper, RightWrapper, CustomWrapper } from "./styles";

type Props = {
  icon: JSX.Element;
  title: string;
  number: string | number;
};

export const RateCard: FunctionComponent<Props> = ({ icon, title, number }) => {
  return (
    <CustomCard styles={CustomWrapper} shadow="light" borderRadius="strong">
      <CustomCard styles={IconWrapper} borderRadius="light" padding={0}>
        {icon}
      </CustomCard>
      <RightWrapper>
        <P size="small">{title}</P>
        <P size="large" emphasis={true}>
          {number}
        </P>
      </RightWrapper>
    </CustomCard>
  );
};
