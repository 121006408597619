import { Navigate } from "react-router-dom";
import { ROUTER_PATHES } from "routes/routes";
import { AUTH_USER_TOKEN_KEY, validateToken } from "utils/auth";
import { GuardProps } from "./types";

// ==============================|| GUEST GUARD ||============================== //

const GuestGuard = ({ children }: GuardProps) => {
  const isTokenValid = validateToken(localStorage.getItem(AUTH_USER_TOKEN_KEY));

  if (isTokenValid) {
    return <Navigate to={ROUTER_PATHES.DASHBOARD} />;
  }

  return children;
};

export default GuestGuard;
