import Box from "@mui/material/Box";
import MainBackground from "assets/MainBackground";
import { SidebarWebApp } from "./Sidebar";
import { useEffect, useState } from "react";
import { setInit } from "state/authSlice";
import { useAuth } from "hooks/useAuth";
import { useAppDispatch } from "state/hooks";
import { useCurrentCompanyMutation } from "services/companies-api";
import { LoadingComponent } from "components/Loading";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { MakeAPayment } from "../Banking/SharedComponents/MakeAPayment";
import { BANKING_ROUTES, ROUTER_PATHES } from "routes/routes";
import { Container, Toolbar, useMediaQuery, useTheme } from "@mui/material";
import Header from "./Header";
import useConfig from "hooks/useConfig";
import { MakeAPaymentWrapper } from "./styles";
import useAppWithoutBackground from "hooks/useAppWithoutBackground";
import useCurrentUser from "graphql/hooks/useCurrentUser";

export const DashboardLayout: React.FC<{}> = () => {
  const { currentCompany } = useAuth();
  const navigate = useNavigate();
  const { miniDrawer } = useConfig();
  const theme = useTheme();
  const matchDownXL = useMediaQuery(theme.breakpoints.down("xl"));
  const matchDownLG = useMediaQuery(theme.breakpoints.down("lg"));
  const matchDownMD = useMediaQuery(theme.breakpoints.down("md"));
  const dispatch = useAppDispatch();
  const [fetchCompany] = useCurrentCompanyMutation();

  const { currentUser } = useCurrentUser();

  const [dataInitialized, setDataInitialized] = useState(false);

  const [open, setOpen] = useState(!miniDrawer);
  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  useEffect(() => {
    if (!miniDrawer) {
      setOpen(!matchDownXL);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchDownXL]);

  const PopulateCustomerAppStore = async () => {
    if (!currentUser) {
      return;
    }

    try {
      const companySelectedId = currentUser.companyIds.includes(
        currentCompany?.id
      )
        ? currentCompany?.id
        : currentUser.companyIds[0];

      const { data: companySelected } = (await fetchCompany(
        companySelectedId
      )) as any;

      dispatch(
        setInit({
          company: companySelected,
        })
      );

      setDataInitialized(true);
    } catch (error: any) {
      console.error(error);
      navigate(ROUTER_PATHES.LOGIN);
    }
  };

  useEffect(() => {
    if (currentUser) {
      PopulateCustomerAppStore();
    }
  }, [currentUser]); // eslint-disable-line react-hooks/exhaustive-deps

  const isABankingView = Object.values(BANKING_ROUTES).includes(
    useLocation().pathname
  );

  if (!dataInitialized)
    return (
      <Wrapper>
        <LoadingComponent />
      </Wrapper>
    );

  if (currentUser?.companyIds.length === 0)
    return (
      <Wrapper>
        <LoadingComponent text="Unfortunately the user does not have a company associated" />
      </Wrapper>
    );

  return (
    <Wrapper>
      {matchDownLG && (
        <Header open={open} handleDrawerToggle={handleDrawerToggle} />
      )}
      <SidebarWebApp open={open} handleDrawerToggle={handleDrawerToggle} />
      <Box
        component="main"
        sx={{ width: "calc(100% - 260px)", flexGrow: 1, p: { xs: 2, sm: 3 } }}
      >
        {!matchDownMD && isABankingView && (
          <MakeAPaymentWrapper>
            <MakeAPayment />
          </MakeAPaymentWrapper>
        )}
        <Container
          maxWidth="xl"
          sx={{
            px: { xs: 0, sm: 2 },
            position: "relative",
            minHeight: "calc(100vh - 110px)",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Outlet />
        </Container>
      </Box>
    </Wrapper>
  );
};

const Wrapper: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const theme = useTheme();
  const appWithoutBg = useAppWithoutBackground();
  const matchDownLG = useMediaQuery(theme.breakpoints.down("lg"));

  return (
    <>
      {!appWithoutBg && <MainBackground />}
      {matchDownLG && <Toolbar />}
      <Box sx={{ display: "flex", width: "100%" }}>{children}</Box>
    </>
  );
};
