import { FunctionComponent } from "react";
import { LabelWrapper } from "./styles";

type Props = {
  color: string;
  fontColor?: string;
  text: string | JSX.Element;
  bold?: boolean;
  size?: "large" | "medium" | "small";
};

export const Label: FunctionComponent<Props> = ({
  color,
  text,
  size = "medium",
  fontColor,
  bold,
}) => {
  return (
    <LabelWrapper color={color} size={size} fontColor={fontColor} bold={bold}>
      {text}
    </LabelWrapper>
  );
};
