import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PersistedCompany } from "models/CompanyModel";
import { emptyCoupon } from "resources/coupons";
import type { RootState } from "./store";

type PromotionCodeType = {
  code: string;
  valid: boolean;
  value: number;
  type: string;
  recurring: boolean;
  limitedOffer: {
    isLimited: boolean;
    validUntil: string;
  };
  stateFeeOff: boolean;
};

type EverflowPartner = {
  transactionId: string;
  offerId: string;
  partnerId: string;
};

type InitialStateTypes = {
  company: PersistedCompany | undefined;
  testABVariant: string;
  promotionCode: PromotionCodeType;
  everflowPartner: EverflowPartner;
};

const initialState: InitialStateTypes = {
  company: undefined,
  testABVariant: "control",
  promotionCode: emptyCoupon,
  everflowPartner: { transactionId: "", offerId: "", partnerId: "" },
};

const authSlice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    setTestABVariant: (state, action: PayloadAction<string>) => {
      state.testABVariant = action.payload;
    },
    setEverflowPartner: (
      state,
      action: PayloadAction<{
        transactionId: string;
        offerId: string;
        partnerId: string;
      }>
    ) => {
      state.everflowPartner = {
        transactionId: action.payload.transactionId,
        offerId: action.payload.offerId,
        partnerId: action.payload.partnerId,
      };
    },
    setPromotionCode: (state, action: PayloadAction<PromotionCodeType>) => {
      state.promotionCode = action.payload;
    },
    setCurrentCompany: (
      state,
      action: PayloadAction<{
        company: PersistedCompany;
      }>
    ) => {
      state.company = action.payload.company;
    },
    setInit: (
      state,
      action: PayloadAction<{
        company: PersistedCompany | undefined;
      }>
    ) => {
      state.company = action.payload.company;
    },
    logOutUser: (state) => initialState,
  },
});

export const {
  logOutUser,
  setCurrentCompany,
  setInit,
  setTestABVariant,
  setEverflowPartner,
  setPromotionCode,
} = authSlice.actions;

export default authSlice.reducer;

export const selectCurrentCompany = (state: RootState) => state.auth.company;
export const selectCurrentTestABVariant = (state: RootState) =>
  state.auth.testABVariant;
export const selectEverflowPartner = (state: RootState) =>
  state.auth.everflowPartner;
export const selectPromotionCode = (state: RootState) =>
  state.auth.promotionCode;
