import config from "config";
import TagManager from "react-gtm-module";

type CommonProps = {
  planData: any;
  entityType: string;
  everflowPartnerName: string;
};

type AddPaymentInfoProps = { paymentType: "Stripe" | "Paypal" } & CommonProps;

type PurchaseEventProps = {
  customerId: string;
  companyId: string;
  promotionCode: string;
} & CommonProps;

export class TrackerGA4 {
  private static instance: TrackerGA4;
  private constructor() {
    TagManager.initialize(config.GTM);
  }

  public static getInstance(): TrackerGA4 {
    if (!TrackerGA4.instance) {
      TrackerGA4.instance = new TrackerGA4();
    }
    return TrackerGA4.instance;
  }

  public beginCheckout = (data: CommonProps) => {
    TagManager.dataLayer({
      dataLayer: {
        event: "begin_checkout",
        ecommerce: {
          currency: "USD",
          value: this._conversionValue(data.planData),
          items: this._buildItems(data),
          partners: data.everflowPartnerName || "",
        },
      },
    });
  };

  public aboutCompany = (data: CommonProps) => {
    TagManager.dataLayer({
      dataLayer: {
        event: "about_company",
        ecommerce: {
          items: this._buildItems(data),
          partners: data.everflowPartnerName || "",
        },
      },
    });
  };

  public summary = (data: CommonProps) => {
    TagManager.dataLayer({
      dataLayer: {
        event: "summary",
        ecommerce: {
          items: this._buildItems(data),
          partners: data.everflowPartnerName || "",
        },
      },
    });
  };

  public addPaymentInfo = (data: AddPaymentInfoProps) => {
    const { paymentType, planData, everflowPartnerName } = data;
    TagManager.dataLayer({
      dataLayer: {
        event: "add_payment_info",
        ecommerce: {
          payment_type: paymentType,
          currency: "USD",
          value: this._conversionValue(planData),
          items: this._buildItems(data),
          partners: everflowPartnerName || "",
        },
      },
    });
  };

  public purchase = (data: PurchaseEventProps) => {
    const {
      customerId,
      companyId,
      promotionCode,
      planData,
      everflowPartnerName,
    } = data;
    TagManager.dataLayer({
      dataLayer: {
        event: "purchase_success",
        customerId: customerId,
        ecommerce: {
          transaction_id: companyId,
          affiliation: "Doola.com",
          tax: 0,
          shipping: 0,
          coupon: promotionCode,
          currency: "USD",
          value: this._conversionValue(planData),
          items: this._buildItems(data),
          partners: everflowPartnerName || "",
        },
      },
    });
  };

  public enteredEmail = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "lead",
      },
    });
  };

  public login = (customerId: string) => {
    // fired once user login or once user go to dashboard from success.
    TagManager.dataLayer({
      dataLayer: {
        event: "customerDataReady",
        user_id: customerId,
      },
    });
  };

  /* PRIVATE */
  _buildItems = (data: CommonProps) => {
    const { planData, entityType } = data;

    return [
      {
        item_id: planData.stripePriceId as string,
        item_name: planData.label as string,
        price: this._conversionValue(planData),
        quantity: 1,
        currency: "USD",
        item_category: entityType,
      },
    ];
  };

  _conversionValue = (planData: any): number => {
    return planData.price; // no need to include extra logic here. Discounts, normalize values will be done by GA.
  };
}
