import { TransactionsResponse } from "models/Requests";
import {
  PaymentMethod,
  TransactionDirection,
  TransactionItem,
  TransactionLabel,
  TransactionObject,
  TransactionType,
  TransactionTags,
} from "models/Transactions";

export const getAccountFromData = (data: TransactionsResponse) => {
  if (!data.included) {
    return;
  }
  return data.included.find((item) => item.type === "depositAccount")!;
};

export const getAccountInformationFromData = (data: TransactionsResponse) => {
  const account = getAccountFromData(data);

  if (!account) {
    return;
  }

  return {
    number: account.attributes.accountNumber,
    balance: account.attributes.available / 100,
  };
};

export const getTransactionsFromData = (data: TransactionsResponse) => {
  return transformTransactions(data.data);
};

const mapTransactionDirection = (
  direction: TransactionDirection
): TransactionLabel => {
  return direction === TransactionDirection.Debit
    ? TransactionLabel.Outgoing
    : TransactionLabel.Incoming;
};

const mapTransactionMethod = (
  type: TransactionType,
  tags?: TransactionTags
): PaymentMethod => {
  switch (type) {
    case TransactionType.OriginatedACH:
    case TransactionType.ReceivedACH:
      if (tags && tags.type && tags.type === "international") {
        return PaymentMethod.International;
      }
      return PaymentMethod.ACH;
    case TransactionType.Book:
      return PaymentMethod.Book;
    case TransactionType.Card:
      return PaymentMethod.Card;
  }
};

export const transformTransactions = (
  data: TransactionItem[]
): TransactionObject[] =>
  data.map(({ id, attributes, type }) => {
    const {
      direction,
      createdAt,
      tags,
      amount,
      description,
      summary,
      companyName = "",
      counterparty,
    } = attributes;

    return {
      id,
      type: mapTransactionDirection(direction),
      toFrom: counterparty?.name || companyName,
      date: createdAt,
      paymentMethod: mapTransactionMethod(type, tags),
      amount: amount / 100,
      status: "Paid",
      summary: summary,
      moreDetails: {
        bankName: "DEUTDEHHXXX",
        IBAN: "DEXXXXXXXXXXXXXXXX",
        reference1: "End-toEnd Ref.: CB.9127.2187",
        reason: description,
        reference2: "End-to-end reference",
        pdfDocument: "some route",
      },
    };
  });
