import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { CustomButton } from "components/Buttons";
import { P } from "components/Fonts";
import { useState } from "react";
import { ApplicationStatusModalWrapper, StyledCloseIcon } from "./styles";
import { useNavigate } from "react-router-dom";
import utils from "utils";
import { ApplicationStatuses } from "state/authBankingSlice";
import { useCallback } from "react";
import { CustomButtonVariants } from "components/Buttons/types";
import { ROUTER_PATHES } from "routes/routes";
import { Logo } from "components/Logos";
import { bankingExtraApi } from "services/banking-extra-api";
import { useAuth } from "hooks/useAuth";
import { toast } from "toast";
import * as Sentry from "@sentry/react";

/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */

type Props = {
  status: ApplicationStatuses;
  signUpURL?: String;
};

const DOOLA_BANKING_URL = "https://www.doola.com/banking";
const isPendingDocuments = (status: string) => status === "AWAITING_DOCUMENTS";

export default ({ signUpURL, status }: Props): JSX.Element => {
  const [open, setOpen] = useState(true);

  const navigate = useNavigate();

  const handleClose = () => {
    navigate(ROUTER_PATHES.DASHBOARD);
    setOpen(false);
  };

  const getModalContent = useCallback((): JSX.Element => {
    switch (status) {
      case "APPLICATION_PENDING":
        return <PendingReviewContent closeModal={() => setOpen(false)} />;
      case "APPLICATION_DENIED":
        return <ApplicationDeniedContent closeModal={() => setOpen(false)} />;
      case "APPLICATION_CANCELED":
        return <ApplicationCanceledContent closeModal={() => setOpen(false)} />;
      default:
        return (
          <NotAppliedYetContent
            signUpURL={signUpURL}
            status={status}
            closeModal={() => setOpen(false)}
          />
        );
    }
  }, [status, signUpURL]);

  return (
    <Dialog
      open={open}
      maxWidth="sm"
      onClick={(event) => {
        event.stopPropagation();
      }}
      onClose={handleClose}
    >
      <ApplicationStatusModalWrapper>
        <StyledCloseIcon onClick={handleClose} />
        <DialogTitle>
          <P
            size="large"
            display="flex"
            style={{
              alignItems: "baseline",
              justifyContent: "center",
              flexWrap: "wrap",
            }}
          >
            <Logo />
            &nbsp; Banking
          </P>
        </DialogTitle>
        {getModalContent()}
      </ApplicationStatusModalWrapper>
    </Dialog>
  );
};

const getButtonName = (status: string, hasBankingEarlyAccess: boolean) => {
  if (isPendingDocuments(status)) return "Finish your application →";
  return hasBankingEarlyAccess ? "Sign Up Now →" : "Join the Waitlist →";
};

const NotAppliedYetContent = ({
  signUpURL,
  status,
  closeModal,
}: Props & { closeModal: () => void }) => {
  const handleSignMeUp = () => {
    if (signUpURL) {
      window.open(signUpURL as string, "_blank");
    }
  };

  const handleOpenWaitlist = () => {
    window.open(DOOLA_BANKING_URL, "_blank");
  };

  const hasBankingEarlyAccess = true;

  return (
    <>
      <DialogContent>
        <DialogContentText pt={2}>
          We are launching banking services.
          <br />
          <br />
          Create your account with us, and receive bank transfers from any
          account, get a physical or virtual MasterCard debit, send ACH
          payments, wire transfers in the US and internationally.
          <br />
          Your business-in-a-box.
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center" }}>
        <CustomButton
          variant={CustomButtonVariants.primary}
          borderRadius="rounded"
          onClick={hasBankingEarlyAccess ? handleSignMeUp : handleOpenWaitlist}
        >
          <P size="small">{getButtonName(status, hasBankingEarlyAccess)}</P>
        </CustomButton>
        <CustomButton variant={CustomButtonVariants.link} onClick={closeModal}>
          <P size="small" color={utils.colors.grass}>
            View product demo →
          </P>
        </CustomButton>
      </DialogActions>
    </>
  );
};

const PendingReviewContent = ({ closeModal }: { closeModal: () => void }) => (
  <>
    <DialogContent>
      <DialogContentText pt={2}>
        <P size="medium" paddingBottom={1}>
          You’re application is <b>pending review</b>.
        </P>
        <P size="medium" paddingBottom={2}>
          We will let you know as soon as we can.
        </P>
        <P size="medium">The doola team.</P>
      </DialogContentText>
    </DialogContent>
    <DialogActions sx={{ justifyContent: "center" }}>
      <CustomButton variant={CustomButtonVariants.link} onClick={closeModal}>
        <P size="small" color={utils.colors.grass}>
          View product demo →
        </P>
      </CustomButton>
    </DialogActions>
  </>
);

const ApplicationDeniedContent = ({
  closeModal,
}: {
  closeModal: () => void;
}) => (
  <>
    <DialogContent>
      <DialogContentText pt={2}>
        <P size="medium" paddingBottom={1}>
          Your bank application is currently under manual review. Our team is
          verifying the information you have shared to ensure we can onboard
          you.
        </P>
        <P size="medium" paddingBottom={2}>
          Our team will reach out to you as soon as possible to inform you of
          the next steps. Usual processing times are 4-5 business days.
        </P>
        <P size="medium" paddingBottom={2}>
          Thank you for your patience and understanding!
        </P>
        <P size="medium">The doola team.</P>
      </DialogContentText>
    </DialogContent>
    <DialogActions sx={{ justifyContent: "center" }}>
      <CustomButton variant={CustomButtonVariants.link} onClick={closeModal}>
        <P size="small" color={utils.colors.grass}>
          View product demo →
        </P>
      </CustomButton>
    </DialogActions>
  </>
);

const ApplicationCanceledContent = ({
  closeModal,
}: {
  closeModal: () => void;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const { currentCompany } = useAuth();
  const [createNewApplication] =
    bankingExtraApi.useCreateNewApplicationMutation();

  const handleStartNewApplication = async () => {
    try {
      setIsLoading(true);
      const { data } = (await createNewApplication(currentCompany.id)) as any;
      setIsLoading(false);
      const signUpURL = data?.form;
      window.open(signUpURL as string);
    } catch (error: any) {
      toast.error(
        "There was an error creating a new Application. Please contact customer service"
      );
      Sentry.captureException(error);
    }
  };

  return (
    <>
      <DialogContent>
        <DialogContentText pt={2}>
          <P size="medium" paddingBottom={1}>
            Your bank application has been canceled
          </P>
          <P size="medium" paddingBottom={2}>
            Thank you for your patience and understanding!
          </P>
          <P size="medium">The doola team.</P>
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center" }}>
        <CustomButton
          loading={isLoading}
          variant={CustomButtonVariants.primary}
          borderRadius="rounded"
          onClick={handleStartNewApplication}
        >
          <P size="small">Start new application</P>
        </CustomButton>
        <CustomButton variant={CustomButtonVariants.link} onClick={closeModal}>
          <P size="small" color={utils.colors.grass}>
            View product demo →
          </P>
        </CustomButton>
      </DialogActions>
    </>
  );
};
