import styled from "styled-components/macro";

export const CardProfileWrapper = styled.div`
  width: 400px;
  display: grid;
  margin: auto;
`;

export const BackToCardsWrapper = styled.div`
  margin-top: 40px;
`;

export const SVGCheckWrapper = styled.div`
  svg {
    height: 40px;
    width: auto;
    margin-right: 20px;
  }
`;

export const SpentRowWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10px;
`;

export const ProfileTitleWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
