import { P } from "components/Fonts";
import { moneyFormatter } from "utils/formatters";
import { BalanceWrapper } from "./styles";
import { format as formatDate } from "fecha";
/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */

type Props = {
  balance: number;
  accountNumber: string;
  routingNumber: string;
};

export default ({
  balance,
  accountNumber,
  routingNumber,
}: Props): JSX.Element => {
  return (
    <BalanceWrapper>
      <div>
        <P size="large" emphasis>
          Balance
        </P>
      </div>
      <div>
        <P size="large" emphasis style={{ fontSize: "44px" }}>
          $ {moneyFormatter(balance, false)}
        </P>
      </div>
      <div>
        <P size="small">{formatDate(new Date(), "dddd, DD MMMM YYYY")}</P>
        <P size="small">
          <b>Account num:</b> {accountNumber}
        </P>
        <P size="small">
          <b>Routing num:</b> {routingNumber}{" "}
          <b>(For US ACH Transactions Only)</b>
        </P>
      </div>
    </BalanceWrapper>
  );
};
