export enum CustomButtonVariants {
  primary = "primary",
  inversedPrimary = "inversed-primary",
  inversedPink = "inversed-pink",
  black = "black",
  inversedBlack = "inversed-black",
  link = "link",
  neonPink = "neon-pink",
  green = "green",
}

export type CustomButtonProps = {
  borderRadius?: "rounded";
  variant: CustomButtonVariants;
  width?: "100%" | string;
  onClick?: (() => void) | ((e: any) => Promise<void>);
  disabled?: boolean;
  loading?: boolean;
  $loading?: boolean;
  type?: "button" | "submit" | "reset" | undefined;
  style?: any;
  children?: React.ReactNode;
};
