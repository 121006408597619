import { GAClickTrackerWrapper } from "components/GoogleAnalytics";
import { useState } from "react";
import { useIntercom } from "react-use-intercom";

type Props = {
  children: any;
};

// documentation: https://www.intercom.com/help/en/articles/2894-customize-the-intercom-messenger-technical
//DONE: Include the class open-intercom-chat in google tag manager where we load intercom.
export const IntercomTriggerWrapper: React.FC<Props> = ({ children }) => {
  const { show, hide } = useIntercom();
  const [isShown, setIsShown] = useState(false);

  const onClickHandler = () => {
    if (isShown) {
      setIsShown(false);
      hide();
    } else {
      setIsShown(true);
      show();
    }
  };

  return (
    <GAClickTrackerWrapper event="chat_live">
      <span onClick={onClickHandler} style={{ cursor: "pointer" }}>
        {children}
      </span>
    </GAClickTrackerWrapper>
  );
};
