import { Box, useMediaQuery, useTheme } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { BankingSectionItems, TabsData } from "../data";
import Guidance from "./Guidance";
import Item from "./Item";
import SubItem from "./SubItem";
import { SidebarItemsWrapper } from "./styles";
import BankOutlined from "@ant-design/icons/lib/icons/BankOutlined";
import { ROUTER_PATHES } from "routes/routes";

interface SidebarContentProps {
  isBankingSelected: boolean;
}

const SidebarContent = ({ isBankingSelected }: SidebarContentProps) => {
  const theme = useTheme();
  const currentPathName = useLocation().pathname;
  const matchDownSM = useMediaQuery(theme.breakpoints.down("sm"));
  const heightShorterAs = useMediaQuery("(max-height: 750px)");

  const renderTabs = () =>
    TabsData.map((item, index) => (
      <Link key={index} to={item.route} style={{ textDecoration: "none" }}>
        <Item isSelected={currentPathName === item.route} sidebarItem={item} />
      </Link>
    ));

  const renderBankingTabs = () => (
    <>
      <Link to={ROUTER_PATHES.BANKING} style={{ textDecoration: "none" }}>
        <Item
          isSelected={isBankingSelected}
          sidebarItem={{
            icon: <BankOutlined />,
            label: "Banking",
          }}
        />
      </Link>

      {isBankingSelected &&
        BankingSectionItems.map((item, index) => (
          <Link key={index} to={item.route} style={{ textDecoration: "none" }}>
            <SubItem
              isSelected={currentPathName === item.route}
              sidebarItem={item}
            />
          </Link>
        ))}
    </>
  );

  return (
    <>
      <SidebarItemsWrapper
        hiddenGuidance={heightShorterAs}
        mobileView={matchDownSM}
      >
        {renderTabs()}
        {renderBankingTabs()}
      </SidebarItemsWrapper>
      <Box
        style={{
          position: "absolute",
          bottom: "0",
          width: "100%",
        }}
      >
        <Box m={2}>{!heightShorterAs && <Guidance />}</Box>
      </Box>
    </>
  );
};

export default SidebarContent;
