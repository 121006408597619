import { Grid } from "@mui/material";
import { WrappFadeAnimation } from "assets/GlobalStyled";
import { CustomButton } from "components/Buttons";
import { CustomButtonVariants } from "components/Buttons/types";
import { CustomCard } from "components/Cards";
import { P } from "components/Fonts";
import { ControlledTextField } from "components/FormElements/FormControllers";
import { useFormContext } from "react-hook-form";
import utils from "utils";
import { SVGCheck } from "../../SharedComponents/SVGs";
import { BackToDashboardWrapper, SVGPlaneWrapper } from "./styles";

/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */

export default (): JSX.Element => {
  const {
    register,
    control,
    formState: { errors, isSubmitting },
  } = useFormContext();

  const COMMON_PROPS = { control: control, errors: errors, register: register };

  return (
    <>
      <WrappFadeAnimation>
        <CustomCard borderRadius="strong" shadow="strong">
          <Grid container spacing={1} mb={2} textAlign="center">
            <Grid item xs={12} sm={12}>
              <SVGPlaneWrapper>
                <SVGCheck color={utils.colors.gold} />
              </SVGPlaneWrapper>
              <P size="large" emphasis margin="20px 0px">
                Verify your Identity
              </P>
              <P size="small" colorOpacity="50%" margin="20px 0px">
                We sent you an SMS verification.
              </P>
              <ControlledTextField
                required
                {...COMMON_PROPS}
                name="verificationCode"
                label="Verification Code"
              />
            </Grid>
          </Grid>
        </CustomCard>
        <BackToDashboardWrapper>
          <CustomButton
            type="submit"
            variant={CustomButtonVariants.black}
            borderRadius="rounded"
            loading={isSubmitting}
          >
            <P size="small">Verify →</P>
          </CustomButton>
        </BackToDashboardWrapper>
      </WrappFadeAnimation>
    </>
  );
};
