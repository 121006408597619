import React, { FunctionComponent } from "react";
import styled, { css } from "styled-components/macro";
import {
  ShadowLight,
  ShadowStrong,
  ShadowSuperStrong,
} from "assets/GlobalStyled";
import utils from "utils";

type Props = {
  borderRadius?: "light" | "medium" | "strong";
  shadow?: "light" | "strong" | "xstrong" | "none";
  styles?: any;
  padding?: 0 | 1 | 2 | 3 | 4 | 5 | 6;
  onClick?: () => void;
  children?: React.ReactNode;
};

export const CustomCard: FunctionComponent<Props> = (props) => (
  <StyledBuilder {...props}>{props.children}</StyledBuilder>
);

const StyledBuilder = styled.div<Props>`
  background-color: white;
  padding: ${(props) =>
    (props.padding ? props.padding : 2) * utils.constants.units.main}px;
  ${(props) => {
    switch (props.borderRadius) {
      case "light":
        return StyledRadiusLight;
      case "medium":
        return StyledRadiusMedium;
      case "strong":
        return StyledRadiusHard;
      default:
        return "";
    }
  }};
  ${(props) => {
    switch (props.shadow) {
      case "light":
        return ShadowLight;
      case "strong":
        return ShadowStrong;
      case "xstrong":
        return ShadowSuperStrong;
      case "none":
        return "";
      default:
        return "";
    }
  }}
  ${(props) => props.styles ?? {}};
`;

const StyledRadiusLight = css`
  border-radius: 6px;
`;

const StyledRadiusMedium = css`
  border-radius: 10px;
`;

const StyledRadiusHard = css`
  border-radius: 16px;
`;
