import styled, { css } from "styled-components/macro";
import utils from "utils";

export const ShadowStrong = css`
  box-shadow: 0px 3px 2px rgba(0, 0, 0, 0.05);
`;

export const ShadowSuperStrong = css`
  box-shadow: 0px 20px 50px rgba(0, 0, 0, 0.07);
`;

export const ShadowLight = css`
  box-shadow: 0px 3px 2px rgba(0, 0, 0, 0.02);
`;

export const HoverPointer = css`
  &:hover {
    cursor: pointer;
  }
`;

type Props = {
  when: "mobile" | "no-mobile" | "no-pc";
};

export const Hide = styled.span<Props>`
  display: contents;
  ${(props) => {
    switch (props.when) {
      case "mobile":
        return `@media only screen and (max-width: ${utils.screenSize.mobile}) {
        display: none;
      }`;

      case "no-pc":
        return `@media only screen and (max-width: ${utils.screenSize.pc}) {
        display: none;
      }`;

      case "no-mobile":
        return `@media only screen and (min-width: ${utils.screenSize.mobile}) {
        display: none;
      }`;

      default:
        break;
    }
  }}
`;

export const WrappFadeAnimation = styled.div`
  opacity: 1;
  animation: fade 1s linear;

  @keyframes fade {
    0% {
      opacity: 0;
    }
    1000% {
      opacity: 1;
    }
  }
`;
