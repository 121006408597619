export const SVGPlaneIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24">
    <path d="M2 21L23 12L2 3V10L17 12L2 14V21Z" fill="#EAB94E" />
  </svg>
);

type Props = {
  color?: string;
};
export const SVGCheck: React.FC<Props> = ({ color }) => (
  <svg
    width="17"
    height="17"
    viewBox="0 0 17 17"
    style={{ marginRight: "15px" }}
  >
    <path
      d="M8.5 0C3.825 0 0 3.825 0 8.5C0 13.175 3.825 17 8.5 17C13.175 17 17 13.175 17 8.5C17 3.825 13.175 0 8.5 0ZM6.8 12.75L2.55 8.5L3.7485 7.3015L6.8 10.3445L13.2515 3.893L14.45 5.1L6.8 12.75Z"
      fill={color ? color : "#477C32"}
    />
  </svg>
);

export const SVGVirtualCardMini = () => (
  <svg width="52" height="30" viewBox="0 0 52 30">
    <rect width="52" height="30" rx="6" fill="#EAB94E" />
    <rect
      opacity="0.75"
      y="7.14099"
      width="52"
      height="22.859"
      rx="6"
      fill="#1E1E1E"
    />
    <path
      d="M13.5432 20.1666C13.5898 19.7816 13.6248 19.3966 13.6248 19C13.6248 18.6033 13.5898 18.2183 13.5432 17.8333H15.5148C15.6082 18.2066 15.6665 18.5975 15.6665 19C15.6665 19.4025 15.6082 19.7933 15.5148 20.1666H13.5432ZM12.5107 23.41C12.8607 22.7625 13.129 22.0625 13.3157 21.3333H15.0365C14.4765 22.2958 13.584 23.0425 12.5107 23.41ZM12.3648 20.1666H9.63484C9.5765 19.7816 9.5415 19.3966 9.5415 19C9.5415 18.6033 9.5765 18.2125 9.63484 17.8333H12.3648C12.4173 18.2125 12.4582 18.6033 12.4582 19C12.4582 19.3966 12.4173 19.7816 12.3648 20.1666ZM10.9998 23.6433C10.5157 22.9433 10.1248 22.1675 9.88567 21.3333H12.114C11.8748 22.1675 11.484 22.9433 10.9998 23.6433ZM8.6665 16.6666H6.96317C7.51734 15.6983 8.41567 14.9516 9.48317 14.59C9.13317 15.2375 8.87067 15.9375 8.6665 16.6666ZM6.96317 21.3333H8.6665C8.87067 22.0625 9.13317 22.7625 9.48317 23.41C8.41567 23.0425 7.51734 22.2958 6.96317 21.3333ZM6.48484 20.1666C6.3915 19.7933 6.33317 19.4025 6.33317 19C6.33317 18.5975 6.3915 18.2066 6.48484 17.8333H8.4565C8.40984 18.2183 8.37484 18.6033 8.37484 19C8.37484 19.3966 8.40984 19.7816 8.4565 20.1666H6.48484ZM10.9998 14.3508C11.484 15.0508 11.8748 15.8325 12.114 16.6666H9.88567C10.1248 15.8325 10.5157 15.0508 10.9998 14.3508ZM15.0365 16.6666H13.3157C13.129 15.9375 12.8607 15.2375 12.5107 14.59C13.584 14.9575 14.4765 15.6983 15.0365 16.6666ZM10.9998 13.1666C7.774 13.1666 5.1665 15.7916 5.1665 19C5.1665 20.5471 5.78109 22.0308 6.87505 23.1247C7.41672 23.6664 8.05978 24.0961 8.76752 24.3893C9.47525 24.6824 10.2338 24.8333 10.9998 24.8333C12.5469 24.8333 14.0307 24.2187 15.1246 23.1247C16.2186 22.0308 16.8332 20.5471 16.8332 19C16.8332 18.2339 16.6823 17.4754 16.3891 16.7676C16.096 16.0599 15.6663 15.4168 15.1246 14.8752C14.583 14.3335 13.9399 13.9038 13.2322 13.6107C12.5244 13.3175 11.7659 13.1666 10.9998 13.1666Z"
      fill="#EAEAEA"
    />
    <g clipPath="url(#clip0_5468_12012)">
      <path
        d="M42.0856 20.1512C40.8271 20.1512 39.7024 20.8104 39.7024 22.0284C39.7024 23.4251 41.6971 23.5216 41.6971 24.2232C41.6971 24.5187 41.362 24.7832 40.7898 24.7832C39.9776 24.7832 39.3706 24.4136 39.3706 24.4136L39.1109 25.6426C39.1109 25.6426 39.8101 25.9548 40.7385 25.9548C42.1145 25.9548 43.1973 25.2632 43.1973 24.0245C43.1973 22.5486 41.1942 22.455 41.1942 21.8037C41.1942 21.5723 41.4693 21.3187 42.0399 21.3187C42.6837 21.3187 43.209 21.5874 43.209 21.5874L43.4632 20.4004C43.4632 20.4004 42.8917 20.1512 42.0856 20.1512ZM30.571 20.2408L30.5405 20.42C30.5405 20.42 31.07 20.5179 31.5469 20.7133C32.1609 20.9373 32.2047 21.0676 32.3081 21.4727L33.4349 25.8624H34.9455L37.2727 20.2408H35.7656L34.2702 24.063L33.66 20.8231C33.6041 20.4524 33.3206 20.2408 32.9737 20.2408H30.571ZM37.8787 20.2408L36.6964 25.8624H38.1336L39.3117 20.2408H37.8787ZM45.8943 20.2408C45.5477 20.2408 45.3641 20.4283 45.2294 20.756L43.1238 25.8624H44.631L44.9225 25.0113H46.7587L46.936 25.8624H48.2658L47.1057 20.2408H45.8943ZM46.0903 21.7596L46.537 23.8691H45.3402L46.0903 21.7596Z"
        fill="#EAEAEA"
      />
    </g>
    <defs>
      <clipPath id="clip0_5468_12012">
        <rect
          width="17.743"
          height="5.82147"
          fill="white"
          transform="translate(30.5317 20.1423)"
        />
      </clipPath>
    </defs>
  </svg>
);

export const SVGPhysicalCardMini = () => (
  <svg width="52" height="30" viewBox="0 0 52 30">
    <rect width="52" height="30" rx="6" fill="#EAB94E" />
    <rect y="7.14111" width="52" height="22.859" rx="6" fill="#1E1E1E" />
    <rect
      opacity="0.5"
      x="3.95557"
      y="11.1772"
      width="10.3693"
      height="5.97671"
      rx="2"
      fill="#EAB94E"
    />
    <g clipPath="url(#clip0_5468_8256)">
      <path
        d="M42.0856 20.1514C40.8271 20.1514 39.7024 20.8106 39.7024 22.0285C39.7024 23.4252 41.6971 23.5217 41.6971 24.2234C41.6971 24.5188 41.362 24.7833 40.7898 24.7833C39.9776 24.7833 39.3706 24.4137 39.3706 24.4137L39.1109 25.6428C39.1109 25.6428 39.8101 25.9549 40.7385 25.9549C42.1145 25.9549 43.1973 25.2633 43.1973 24.0246C43.1973 22.5487 41.1942 22.4551 41.1942 21.8038C41.1942 21.5724 41.4693 21.3188 42.0399 21.3188C42.6837 21.3188 43.209 21.5876 43.209 21.5876L43.4632 20.4005C43.4632 20.4005 42.8917 20.1514 42.0856 20.1514ZM30.571 20.241L30.5405 20.4201C30.5405 20.4201 31.07 20.5181 31.5469 20.7134C32.1609 20.9374 32.2047 21.0678 32.3081 21.4728L33.4349 25.8625H34.9455L37.2727 20.241H35.7656L34.2702 24.0631L33.66 20.8233C33.6041 20.4525 33.3206 20.241 32.9737 20.241H30.571ZM37.8787 20.241L36.6964 25.8625H38.1336L39.3117 20.241H37.8787ZM45.8943 20.241C45.5477 20.241 45.3641 20.4284 45.2294 20.7561L43.1238 25.8625H44.631L44.9225 25.0115H46.7587L46.936 25.8625H48.2658L47.1057 20.241H45.8943ZM46.0903 21.7597L46.537 23.8692H45.3402L46.0903 21.7597Z"
        fill="#EAEAEA"
      />
    </g>
    <defs>
      <clipPath id="clip0_5468_8256">
        <rect
          width="17.743"
          height="5.82147"
          fill="white"
          transform="translate(30.5317 20.1423)"
        />
      </clipPath>
    </defs>
  </svg>
);
