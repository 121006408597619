import { CardContent } from "@mui/material";
import { P } from "components/Fonts";
import { IntercomTriggerWrapper } from "components/IntercomTriggerWrapper";
import { PersistedCompany } from "models/CompanyModel";
import { setCurrentCompany } from "state/authSlice";
import { useAppDispatch } from "state/hooks";
import { WrapperAddAnother, WrapperCompanyItem } from "./styles";
import { CompanySelectorProps } from "./types";

export const CompanySelectorContent: React.FC<CompanySelectorProps> = ({
  companies,
  currentCompany,
  user,
}) => {
  const dispatch = useAppDispatch();

  const isSelected = (id: string) => id === currentCompany?.id;
  const handleClickOnCompany = (companyToSet: PersistedCompany) => {
    dispatch(setCurrentCompany({ company: companyToSet }));
  };

  return (
    <CardContent sx={{ px: 2.5, pt: 3 }}>
      <P size="small" colorOpacity="50%">
        {user.email}
      </P>

      {companies.map((company, index) => (
        <WrapperCompanyItem
          key={index}
          onClick={() => handleClickOnCompany(company)}
        >
          <P size="small" emphasis={isSelected(company.id)}>
            {company.companyDetails.name}
          </P>
          {isSelected(company.id) && (
            <svg
              width="18"
              height="14"
              viewBox="0 0 18 14"
              style={{ marginTop: "5px" }}
            >
              <path
                d="M17.5 2.2098L5.5 13.2841L0 8.20837L1.41 6.90714L5.5 10.6724L16.09 0.908569L17.5 2.2098Z"
                fill="black"
              />
            </svg>
          )}
        </WrapperCompanyItem>
      ))}

      <IntercomTriggerWrapper>
        <WrapperAddAnother>
          <P size="small">Add another company</P>
        </WrapperAddAnother>
      </IntercomTriggerWrapper>
    </CardContent>
  );
};
