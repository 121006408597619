const FIXTURE_getTransactionsSinceByIdAndToken = {
  data: [
    {
      type: "receivedAchTransaction",
      id: "1177874",
      attributes: {
        createdAt: "2022-04-01T14:06:09.169Z",
        amount: 100000,
        direction: "Credit",
        balance: 100000,
        description: "Sandbox Transaction",
        summary: "SANDBOX  |  Sandbox Transaction",
        companyName: "SANDBOX",
        counterpartyRoutingNumber: "051402372",
        traceNumber: "123456789123456",
        secCode: "WEB",
        counterparty: {
          name: "SANDBOX",
        },
      },
      relationships: {
        account: {
          data: {
            type: "account",
            id: "397389",
          },
        },
        customer: {
          data: {
            type: "customer",
            id: "281968",
          },
        },
        customers: {
          data: [
            {
              type: "customer",
              id: "281968",
            },
          ],
        },
        org: {
          data: {
            type: "org",
            id: "1107",
          },
        },
      },
    },
    {
      type: "receivedAchTransaction",
      id: "1178008",
      attributes: {
        createdAt: "2022-04-01T15:24:25.860Z",
        amount: 100000,
        direction: "Credit",
        balance: 200000,
        description: "Sandbox Transaction",
        summary: "SANDBOX  |  Sandbox Transaction",
        companyName: "SANDBOX",
        counterpartyRoutingNumber: "051402372",
        traceNumber: "123456789123456",
        secCode: "WEB",
        counterparty: {
          name: "SANDBOX",
        },
      },
      relationships: {
        account: {
          data: {
            type: "account",
            id: "397389",
          },
        },
        customer: {
          data: {
            type: "customer",
            id: "281968",
          },
        },
        customers: {
          data: [
            {
              type: "customer",
              id: "281968",
            },
          ],
        },
        org: {
          data: {
            type: "org",
            id: "1107",
          },
        },
      },
    },
    {
      type: "cardTransaction",
      id: "1186045",
      attributes: {
        createdAt: "2022-04-04T08:57:05.479Z",
        amount: 20,
        direction: "Debit",
        balance: 199980,
        summary: "summary text  |  **7646",
        cardLast4Digits: "7646",
        interchange: null,
        internationalServiceFee: null,
        merchant: {
          name: "Apple Inc.",
          type: 1000,
          category: "",
          location: "Cupertino, CA",
        },
        recurring: false,
        paymentMethod: "Swipe",
        cardNetwork: "Visa",
        counterparty: {
          name: "Apple Inc.",
        },
      },
      relationships: {
        account: {
          data: {
            type: "account",
            id: "397389",
          },
        },
        customer: {
          data: {
            type: "customer",
            id: "281968",
          },
        },
        customers: {
          data: [
            {
              type: "customer",
              id: "281968",
            },
          ],
        },
        org: {
          data: {
            type: "org",
            id: "1107",
          },
        },
        card: {
          data: {
            type: "card",
            id: "180582",
          },
        },
      },
    },
    {
      type: "receivedAchTransaction",
      id: "1186063",
      attributes: {
        createdAt: "2022-04-04T09:21:42.695Z",
        amount: 7000,
        direction: "Credit",
        balance: 206980,
        description: "New description here",
        summary: "SANDBOX  |  New description here",
        companyName: "SANDBOX",
        counterpartyRoutingNumber: "051402372",
        traceNumber: "123456789123456",
        secCode: "WEB",
        counterparty: {
          name: "SANDBOX",
        },
      },
      relationships: {
        account: {
          data: {
            type: "account",
            id: "397389",
          },
        },
        customer: {
          data: {
            type: "customer",
            id: "281968",
          },
        },
        customers: {
          data: [
            {
              type: "customer",
              id: "281968",
            },
          ],
        },
        org: {
          data: {
            type: "org",
            id: "1107",
          },
        },
      },
    },
    {
      type: "receivedAchTransaction",
      id: "1186067",
      attributes: {
        createdAt: "2022-04-04T09:28:18.379Z",
        amount: 7000,
        direction: "Credit",
        balance: 213980,
        description: "New description here",
        summary: "SANDBOX  |  New description here",
        companyName: "SANDBOX",
        counterpartyRoutingNumber: "051402372",
        traceNumber: "123456789123456",
        secCode: "WEB",
        counterparty: {
          name: "SANDBOX",
        },
      },
      relationships: {
        account: {
          data: {
            type: "account",
            id: "397389",
          },
        },
        customer: {
          data: {
            type: "customer",
            id: "281968",
          },
        },
        customers: {
          data: [
            {
              type: "customer",
              id: "281968",
            },
          ],
        },
        org: {
          data: {
            type: "org",
            id: "1107",
          },
        },
      },
    },
    {
      type: "cardTransaction",
      id: "1191111",
      attributes: {
        createdAt: "2022-04-05T13:00:05.630Z",
        amount: 36,
        direction: "Debit",
        balance: 213944,
        summary: "summary text  |  **7646",
        cardLast4Digits: "7646",
        interchange: null,
        internationalServiceFee: null,
        merchant: {
          name: "Wallmart Inc.",
          type: 1000,
          category: "",
          location: "Washinton, WDC",
        },
        recurring: false,
        paymentMethod: "Swipe",
        cardNetwork: "Visa",
        counterparty: {
          name: "Wallmart Inc.",
        },
      },
      relationships: {
        account: {
          data: {
            type: "account",
            id: "397389",
          },
        },
        customer: {
          data: {
            type: "customer",
            id: "281968",
          },
        },
        customers: {
          data: [
            {
              type: "customer",
              id: "281968",
            },
          ],
        },
        org: {
          data: {
            type: "org",
            id: "1107",
          },
        },
        card: {
          data: {
            type: "card",
            id: "180582",
          },
        },
      },
    },
    {
      type: "cardTransaction",
      id: "1191112",
      attributes: {
        createdAt: "2022-04-05T13:00:37.314Z",
        amount: 17,
        direction: "Debit",
        balance: 213927,
        summary: "summary text  |  **7646",
        cardLast4Digits: "7646",
        interchange: null,
        internationalServiceFee: null,
        merchant: {
          name: "Starbucks Inc.",
          type: 1000,
          category: "",
          location: "New York, NYC",
        },
        recurring: false,
        paymentMethod: "Swipe",
        cardNetwork: "Visa",
        counterparty: {
          name: "Wallmart Inc.",
        },
      },
      relationships: {
        account: {
          data: {
            type: "account",
            id: "397389",
          },
        },
        customer: {
          data: {
            type: "customer",
            id: "281968",
          },
        },
        customers: {
          data: [
            {
              type: "customer",
              id: "281968",
            },
          ],
        },
        org: {
          data: {
            type: "org",
            id: "1107",
          },
        },
        card: {
          data: {
            type: "card",
            id: "180582",
          },
        },
      },
    },
    {
      type: "cardTransaction",
      id: "1191115",
      attributes: {
        createdAt: "2022-04-05T13:01:40.079Z",
        amount: 9,
        direction: "Debit",
        balance: 213918,
        summary: "summary text  |  **7646",
        cardLast4Digits: "7646",
        interchange: null,
        internationalServiceFee: null,
        merchant: {
          name: "Starbucks Inc.",
          type: 1000,
          category: "",
          location: "New York, NYC",
        },
        recurring: false,
        paymentMethod: "Swipe",
        cardNetwork: "Visa",
        counterparty: {
          name: "Starbucks Inc.",
        },
      },
      relationships: {
        account: {
          data: {
            type: "account",
            id: "397389",
          },
        },
        customer: {
          data: {
            type: "customer",
            id: "281968",
          },
        },
        customers: {
          data: [
            {
              type: "customer",
              id: "281968",
            },
          ],
        },
        org: {
          data: {
            type: "org",
            id: "1107",
          },
        },
        card: {
          data: {
            type: "card",
            id: "180582",
          },
        },
      },
    },
    {
      type: "cardTransaction",
      id: "1191118",
      attributes: {
        createdAt: "2022-04-05T13:01:50.261Z",
        amount: 15,
        direction: "Debit",
        balance: 213903,
        summary: "summary text  |  **7646",
        cardLast4Digits: "7646",
        interchange: null,
        internationalServiceFee: null,
        merchant: {
          name: "Macdonals Inc.",
          type: 1000,
          category: "",
          location: "New York, NYC",
        },
        recurring: false,
        paymentMethod: "Swipe",
        cardNetwork: "Visa",
        counterparty: {
          name: "Starbucks Inc.",
        },
      },
      relationships: {
        account: {
          data: {
            type: "account",
            id: "397389",
          },
        },
        customer: {
          data: {
            type: "customer",
            id: "281968",
          },
        },
        customers: {
          data: [
            {
              type: "customer",
              id: "281968",
            },
          ],
        },
        org: {
          data: {
            type: "org",
            id: "1107",
          },
        },
        card: {
          data: {
            type: "card",
            id: "180582",
          },
        },
      },
    },
    {
      type: "cardTransaction",
      id: "1191120",
      attributes: {
        createdAt: "2022-04-05T13:02:02.457Z",
        amount: 144,
        direction: "Debit",
        balance: 213759,
        summary: "summary text  |  **7646",
        cardLast4Digits: "7646",
        interchange: null,
        internationalServiceFee: null,
        merchant: {
          name: "Macdonals Inc.",
          type: 1000,
          category: "",
          location: "ZARA, NYC",
        },
        recurring: false,
        paymentMethod: "Swipe",
        cardNetwork: "Visa",
        counterparty: {
          name: "Starbucks Inc.",
        },
      },
      relationships: {
        account: {
          data: {
            type: "account",
            id: "397389",
          },
        },
        customer: {
          data: {
            type: "customer",
            id: "281968",
          },
        },
        customers: {
          data: [
            {
              type: "customer",
              id: "281968",
            },
          ],
        },
        org: {
          data: {
            type: "org",
            id: "1107",
          },
        },
        card: {
          data: {
            type: "card",
            id: "180582",
          },
        },
      },
    },
  ],
  included: [
    {
      type: "depositAccount",
      id: "397389",
      attributes: {
        name: "some anme",
        createdAt: "2022-04-01T14:01:54.727Z",
        routingNumber: "812345678",
        accountNumber: "1000383719",
        depositProduct: "checking",
        balance: 213759,
        hold: 0,
        available: 213759,
        tags: {
          purpose: "checking",
          doolaCompanyId: "27BeySOOpLxvXdmtnHM45P2L40w",
        },
        currency: "USD",
        status: "Open",
        updatedAt: "2022-04-05T13:02:02.457Z",
      },
      relationships: {
        customer: {
          data: {
            type: "customer",
            id: "281968",
          },
        },
        org: {
          data: {
            type: "org",
            id: "1107",
          },
        },
      },
    },
  ],
  meta: {
    pagination: {
      total: 10,
      limit: 100,
      offset: 0,
    },
  },
};

const FIXTURE_getAccountByIdAndToken = {
  data: {
    type: "depositAccount",
    id: "397389",
    attributes: {
      name: "Test User",
      createdAt: "2022-04-01T14:01:54.727Z",
      routingNumber: "812345678",
      accountNumber: "1000383719",
      depositProduct: "checking",
      balance: 213759,
      hold: 0,
      available: 213759,
      tags: {
        purpose: "checking",
        doolaCompanyId: "27BeySOOpLxvXdmtnHM45P2L40w",
      },
      currency: "USD",
      status: "Open",
      updatedAt: "2022-04-05T13:02:02.457Z",
    },
    relationships: {
      customer: {
        data: {
          type: "customer",
          id: "281968",
        },
      },
      org: {
        data: {
          type: "org",
          id: "1107",
        },
      },
    },
  },
};

const FIXTURE_getListStatements = [
  {
    id: "2993551",
    period: "2022-02",
    type: "UNIT",
  },
  {
    id: "3316455",
    period: "2022-03",
    type: "UNIT",
  },
  {
    id: "3662304",
    period: "2022-04",
    type: "UNIT",
  },
  {
    id: "4135365",
    period: "2022-05",
    type: "UNIT",
  },
  {
    id: "4679484",
    period: "2022-06",
    type: "UNIT",
  },
]; /* {
  data: [
    {
      type: "statement",
      id: "1",
      attributes: {
        period: "2020-07",
      },
      relationships: {
        account: {
          data: {
            type: "account",
            id: "1000",
          },
        },
        customer: {
          data: {
            type: "customer",
            id: "1",
          },
        },
      },
    },
    {
      type: "statement",
      id: "2",
      attributes: {
        period: "2020-08",
      },
      relationships: {
        account: {
          data: {
            type: "account",
            id: "1000",
          },
        },
        customer: {
          data: {
            type: "customer",
            id: "1",
          },
        },
      },
    },
    {
      type: "statement",
      id: "3",
      attributes: {
        period: "2020-09",
      },
      relationships: {
        account: {
          data: {
            type: "account",
            id: "1000",
          },
        },
        customer: {
          data: {
            type: "customer",
            id: "1",
          },
        },
      },
    },
    {
      type: "statement",
      id: "4",
      attributes: {
        period: "2020-10",
      },
      relationships: {
        account: {
          data: {
            type: "account",
            id: "1000",
          },
        },
        customer: {
          data: {
            type: "customer",
            id: "1",
          },
        },
      },
    },
  ],
};*/

export const BANKING_API_FIXTUTES = {
  getTransactionsSinceByIdAndToken: FIXTURE_getTransactionsSinceByIdAndToken,
  getAccountByIdAndToken: FIXTURE_getAccountByIdAndToken,
  getListStatements: FIXTURE_getListStatements,
};
