import { HoverPointer } from "assets/GlobalStyled";
import styled from "styled-components/macro";

export const WrapperAddAnother = styled.div`
  background-color: rgb(255, 245, 223);
  padding: 15px 25px;
  margin-top: 15px;
  ${HoverPointer}
`;
export const WrapperCompanyItem = styled.div`
  margin: 10px 0px;
  display: flex;
  justify-content: space-between;
  border-radius: 10px;
`;
