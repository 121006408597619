import styled, { css } from "styled-components/macro";
import utils from "utils";
import { TableCellProps } from "./types";

export const TableHeaderWrapper = styled.div`
  display: flex;
  border-bottom: 1px solid #dddddd;
  // justify-content: space-between;
  background-color: white;
  padding: 15px;
  // width: 100%;
`;
export const TableRowWrapper = styled.div`
  display: flex;
  cursor: pointer;
  border-bottom: 1px solid #dddddd;
  // justify-content: space-between;
  background-color: white;
  // width: 100%;
  padding: 15px;
  align-items: center;
  position: relative;
`;

export const TableCellWrapper = styled.div<TableCellProps>`
  display: flex;
  // min-width: ${(props) => props.minWidth};

  ${(props) => {
    if (props.onClick) {
      return `cursor: pointer`;
    }
  }};

  ${(props) => {
    if (props.maxWidth) {
      return css`
        max-width: ${props.maxWidth};
      `;
    }
  }};
  ${(props) => {
    if (props.justifyContent) {
      return css`
        justify-content: ${props.justifyContent};
      `;
    }
  }};

  width: -webkit-fill-available;
`;

export const TableSettingWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  @media only screen and (max-width: ${utils.screenSize.mobile}) {
    justify-content: start;
  }
`;

export const BalanceWrapper = styled.div`
  margin-right: 30px;
`;

type AcordionIconSVGWrapperProps = {
  rotated: boolean;
};

export const AcordionIconSVGWrapper = styled.div<AcordionIconSVGWrapperProps>`
  margin: auto;
  position: absolute;
  margin-left: -13px;
  transform: ${(props) => (props.rotated ? "rotate(180deg)" : "rotate(0deg)")};
`;

export const MoreDetailsWrapper = styled.div`
  padding: 20px 65px;
`;

export const NoDataYetWrapper = styled.div`
  height: 90%;
  display: flex;
  padding-top: 20px;
`;
