import { useQuery } from "@apollo/client";
import { CURRENT_USER } from "graphql/queries/CurrentUser";
import { ICurrentUser } from "models/CustomerModel";
import { Tracker } from "services/tracking";

export interface ICurrentUserData {
  currentUser: ICurrentUser;
}

const useCurrentUser = () => {
  const { data } = useQuery<ICurrentUserData>(CURRENT_USER, {
    onCompleted: async ({ currentUser }) => {
      try {
        const tracker = Tracker.getInstance();
        await tracker.tagUser(currentUser.email);
      } catch (error) {
        console.log(error);
      }
    },
  });

  return {
    currentUser: data?.currentUser,
  };
};

export default useCurrentUser;
