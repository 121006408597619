import styled from "styled-components/macro";

export const StyledFaqFooter = styled.div`
  text-align: center;
  max-width: 400px;
  margin: 30px auto 10px auto;
`;

export const StyledReadMore = styled.div`
  float: right;
  margin-bottom: 15px;
  margin-top: 10px;
`;
