import { Grid } from "@mui/material";
import { CustomCard } from "components/Cards";
import { P } from "components/Fonts";
import { LoadingComponent } from "components/Loading";
import { useAuthBanking } from "hooks/useAuthBanking";
import { useEffect } from "react";
import {
  useGetAccountByIdAndTokenMutation,
  useGetTransactionsSinceByIdAndTokenMutation,
} from "services/banking-api";
import {
  getAccountInformationFromData,
  getTransactionsFromData,
} from "utils/transactions";
import { BANKING_API_FIXTUTES } from "../SandBoxData/banking-api-fixtures";
import CustomTable from "./Table";
import { TransactionTableProps } from "./types";
/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */

const Transactions: React.FC<TransactionTableProps> = ({
  rowsData,
  accountData,
}) => (
  <Grid container spacing={3} mt={5}>
    <Grid item lg={12} style={{ minWidth: "100%" }}>
      <CustomCard
        shadow="xstrong"
        borderRadius="strong"
        styles={{ minWidth: "700px", overflowX: "auto" }}
      >
        <P size="large" emphasis margin="10px 0px">
          Transactions
        </P>
        <CustomTable rowsData={rowsData} accountData={accountData} />
      </CustomCard>
    </Grid>
  </Grid>
);

export default (): JSX.Element => {
  const { currentAccountId, currentSandboxMode } = useAuthBanking();

  if (currentSandboxMode) {
    const rowsData = getTransactionsFromData(
      BANKING_API_FIXTUTES.getTransactionsSinceByIdAndToken as any
    );
    const accountData = getAccountInformationFromData(
      BANKING_API_FIXTUTES.getTransactionsSinceByIdAndToken as any
    );
    return (
      <Transactions rowsData={rowsData} accountData={accountData as any} />
    );
  }

  const [getTransactionsSinceByIdAndToken, { data: transactionData }] =
    useGetTransactionsSinceByIdAndTokenMutation();

  const [getAccountByIdAndToken, { data: accountDataRaw }] =
    useGetAccountByIdAndTokenMutation();

  useEffect(() => {
    getAccountByIdAndToken({ accountId: currentAccountId });
    getTransactionsSinceByIdAndToken({
      accountId: currentAccountId,
      includeAccount: true,
    });
  }, [
    currentAccountId,
    getAccountByIdAndToken,
    getTransactionsSinceByIdAndToken,
  ]);

  if (!transactionData || !accountDataRaw) {
    return <LoadingComponent />;
  }

  const rowsData = getTransactionsFromData(transactionData);

  const accountData = getAccountInformationFromData({
    included: [accountDataRaw],
  } as any);

  return <Transactions rowsData={rowsData} accountData={accountData as any} />;
};
