import { useMemo } from "react";
import { useSelector } from "react-redux";
import {
  selectAccountId,
  selectToken,
  selectCustomerId,
  selectForm,
  selectState,
  selectSandboxMode,
} from "state/authBankingSlice";

export const useAuthBanking = () => {
  const currentAccountId = useSelector(selectAccountId);
  const currentCustomerId = useSelector(selectCustomerId);
  const currentToken = useSelector(selectToken);
  const currentForm = useSelector(selectForm);
  const currentState = useSelector(selectState);
  const currentSandboxMode = useSelector(selectSandboxMode);

  return useMemo(
    () => ({
      currentAccountId,
      currentCustomerId,
      currentToken,
      currentForm,
      currentState,
      currentSandboxMode,
    }),
    [
      currentAccountId,
      currentToken,
      currentCustomerId,
      currentForm,
      currentState,
      currentSandboxMode,
    ]
  );
};
