import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  NormalizedCacheObject,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { RestLink } from "apollo-link-rest";
import { cache } from "cache";
import config from "config";
import { AUTH_USER_TOKEN_KEY } from "utils/auth";

const getGraphQLEndpoint = (url: string) =>
  url.replace("api.", "graphql.") + "/graphql";

const httpLink = createHttpLink({
  uri: getGraphQLEndpoint(config.api.URL),
});

const restLink = new RestLink({
  uri: config.api.URL,
});

const authLink = setContext((_, { headers }) => {
  let token = localStorage.getItem(AUTH_USER_TOKEN_KEY) || ""; // "" == access to public endpoints via graphql

  return {
    headers: {
      ...headers,
      authorization: `Bearer ${token}`,
    },
  };
});

const restAuthLink = authLink.concat(restLink);
const httpAuthLink = authLink.concat(httpLink);

export const client: ApolloClient<NormalizedCacheObject> = new ApolloClient({
  cache,
  link: ApolloLink.from([restAuthLink, httpAuthLink]),
});
