import { Card } from "services/banking-api";
import { SAND_BOX_DATA } from "../../SandBoxData";
import COUNTRIES from "resources/countries";

export const getCountryNameByCode = (countryCode: string) => {
  const countryFound = COUNTRIES.find(({ code }) => code === countryCode);
  if (!countryFound) {
    console.error(
      `getCountryNameByCode: country not defined for: ${countryCode}`
    );
    return `Not defined for: ${countryCode}`;
  }
  return countryFound.label;
};

export const buildCardDataMocked = (id: string) => {
  const tableElementSelected = SAND_BOX_DATA.cardsView.table.find(
    (e) => e.id === id
  );
  return {
    details: {
      name: tableElementSelected?.cardHolder || "",
      cardNumber:
        "4242 4242 4242 " +
        tableElementSelected?.cardNumber.replaceAll("•", ""),
      expiration: "02/26",
      cvc: "123",
      id: id,
    },
    spent: {
      available: 298000,
      spentThisMonth: tableElementSelected?.spentThisMonth || 0,
      limit: 3000000,
    },
    address: {
      address1: "660 Mission St",
      address2: "Floor 4",
      state: "San Francisco, CA",
      zip: "94105",
      country: "United States",
    },
  };
};

export const buildCardData = (cardData: Card) => {
  const { attributes, id } = cardData;
  const { fullName, last4Digits, expirationDate, address } = attributes;

  const [year, month] = expirationDate.split("-");
  return {
    details: {
      name: `${fullName.first} ${fullName.last}`,
      cardNumber: `•••• •••• •••• ${last4Digits}`,
      expiration: `${month}/${year.slice(-2)}`,
      cvc: "•••",
      id: id,
    },
    spent: {
      available: 298000,
      spentThisMonth: 0,
      limit: 3000000,
    },
    address: {
      address1: address.street,
      address2: address.street2 || "",
      state: address.city,
      zip: address.postalCode,
      country: address.country,
    },
  };
};
