const TABLE_DATA = [
  {
    id: "id1",
    cardHolder: "John Arnaud",
    cardNumber: "••1234",
    cardType: "Physical",
    spentThisMonth: 2000,
    status: "Active",
  },
  {
    id: "id2",
    cardHolder: "Aluna Tindyebwa",
    cardNumber: "••3745",
    cardType: "Virtual",
    spentThisMonth: 359,
    status: "Active",
  },
  {
    id: "id3",
    cardHolder: "Mary Merino",
    cardNumber: "••6984",
    cardType: "Physical",
    spentThisMonth: 589,
    status: "Frozen",
  },
  {
    id: "id4",
    cardHolder: "Jessica Awad",
    cardNumber: "••9571",
    cardType: "Physical",
    spentThisMonth: 1296,
    status: "Unactivated",
  },
];

const PROFILE_CARD_DATA = {
  details: {
    name: "John Arnaud",
    cardNumber: "5268 7600 0000 1234",
    expiration: "26.02.2026",
    cvc: 123,
  },
  spent: {
    available: 298000,
    spentThisMonth: 2000,
    limit: 3000000,
  },
  address: {
    address1: "660 Mission St",
    address2: "Floor 4",
    state: "San Francisco, CA",
    zip: "94105",
    country: "United States",
  },
};

export const CARDS_DATA = {
  table: TABLE_DATA,
  profileCard: PROFILE_CARD_DATA,
};
