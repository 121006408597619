import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const MAKE_BASE_URL = `https://hook.us1.make.com/`;
const RewardsDataFromAirtable = `y61y8oskwbzuco2i9i7ry7csn3vqwd7h`;

export const S3_REWARDS_IMAGES_BUCKET_URL =
  "https://customer-app-static-assets.s3.eu-central-1.amazonaws.com/Rewards/";

export interface GetRewardsListRequest {}

interface RewardItem {
  Benefit: string;
  "Company URL": string;
  Name: string;
}

interface RecordItem {
  fields: RewardItem;
}

export interface GetRewardsListResponse {
  records: [RecordItem];
}

export const makeHookAPI = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: MAKE_BASE_URL,
  }),
  endpoints: (builder) => ({
    getRewardsList: builder.mutation<
      GetRewardsListResponse,
      GetRewardsListRequest
    >({
      query: () => ({
        url: RewardsDataFromAirtable,
        method: "GET",
      }),
      transformResponse: (res: any) => res,
    }),
  }),
});

export const { useGetRewardsListMutation } = makeHookAPI;
