export interface CompanyService {
  id: string;
  createdAt: string;
  updatedAt: string;
  validUntil: string;
  standalone: boolean;
  renewalCount: number;
  name: ServiceNames;
  serviceState: string;
  status: ServiceStatus;
  options: ServiceOption[];
}

export interface ServiceOption {
  key: ServiceOptionsNames; //for example speed filing true
  value: boolean;
}

export enum ServiceNames {
  llcFormation = "llcFormation",
  einCreation = "einCreation",
  banking = "banking",
  paymentGateway = "paymentGateway",
  iTin = "iTin",
  phoneLine = "phoneLine",
  taxFiling = "taxFiling",
  annualComplianceReminder = "annualComplianceReminder",
  annualReportFiling = "annualReportFiling",
  taxComplianceBundle = "taxComplianceBundle",
  bookkeepingEssential = "Bookkeeping",
  bookkeepingPremium = "Bookkeeping Premium",
  mailRoom = "MailRoom",
}

export enum ServiceStatus {
  inactive = "inactive", // Service is in initial state, has not been started
  requiresAction = "requiresAction", // Service is waiting on an internal action
  processing = "processing", // Service is waiting on an external action
  completed = "completed", // Service is in terminal state, completed
}

export enum EINServiceState {
  einPaused = "EIN Paused",
  companyInformationNeeded = "Company Information Needed",
  waitingForCompanyFormation = "Waiting for Company Formation",
  formationDocsReceived = "Formation Docs Received",
  waitingForSS4Signature = "Waiting for SS-4 Signature",
  applyingForEINOnline = "Applying for EIN Online (Has SSN)",
  applyingForEINFaxToIRS = "Applying for EIN via Fax to IRS (No SSN)",
  waitingOnEINFromIRS = "Waiting on EIN From IRS",
  einResubmissionRequired = "EIN Resubmission Required (21+ days since latest submission)",
  // callIRStoGetEIN = "Call IRS to get EIN",
  einReceived = "EIN Received",
}

export enum ServiceOptionsNames {
  speedFilingRush = "speedFilingRush",
  speedFilingExpedite = "speedFilingExpedite",
  speedFilingAccelerated = "speedFilingAccelerated",
  speedFilingPriority = "speedFilingPriority",
}

export class Service {
  BEservices: CompanyService[];
  PlanServicesNames: string[];
  AddonServicesNames: string[];

  constructor(services: CompanyService[]) {
    this.BEservices = services;
    this.PlanServicesNames = [
      ServiceNames.llcFormation,
      ServiceNames.einCreation,
      ServiceNames.banking,
    ];
    this.AddonServicesNames = [
      ServiceNames.annualReportFiling,
      ServiceNames.phoneLine,
      // ServiceNames.iTin,
      ServiceNames.taxFiling,
      ServiceNames.taxComplianceBundle,
      ServiceNames.mailRoom,
    ];
  }

  hasAPlan = () =>
    this.BEservices.filter(({ name }) => this.PlanServicesNames.includes(name))
      .length >= this.PlanServicesNames.length;

  addonsServices = () => {
    const AddonsFromServices = this.BEservices.filter(({ name }) =>
      this.AddonServicesNames.includes(name)
    );
    const AddonsFromOptions = this._getAddonsFromOptions();
    return [...AddonsFromServices, ...AddonsFromOptions];
  };

  getService = (serviceName: string) => {
    return this.BEservices.find(({ name }) => name === serviceName);
  };

  _getAddonsFromOptions = () => {
    const LlcFormation = this.getService(ServiceNames.llcFormation);
    if (!LlcFormation) return [];

    const hasPriorityService = LlcFormation.options.find(
      (e) => e.key === ServiceOptionsNames.speedFilingPriority && e.value
    );

    if (!hasPriorityService) return [];
    return [{ name: ServiceOptionsNames.speedFilingPriority }];
  };
}
