import { Grid } from "@mui/material";
import { WrappFadeAnimation } from "assets/GlobalStyled";
import { P } from "components/Fonts";
import { ControlledTextField } from "components/FormElements/FormControllers";
import { useFormContext } from "react-hook-form";

/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default (): JSX.Element => {
  const {
    register,
    control,
    formState: { errors },
  } = useFormContext();

  const COMMON_PROPS = { control: control, errors: errors, register: register };

  return (
    <>
      <WrappFadeAnimation>
        <P size="Large" emphasis>
          Who are you sending money to?
        </P>

        <Grid container spacing={1}>
          <Grid item xs={12} sm={12}>
            <ControlledTextField
              required
              {...COMMON_PROPS}
              name="recipientName"
              label="Recipient's Legal Name"
              helperText="Recipients name can not contain accented letters"
            />
            {/* <ControlledCheckbox
              {...COMMON_PROPS}
              name="notifyRecipient"
              label="Notify them when payment is sent"
            /> */}
            <ControlledTextField
              required
              {...COMMON_PROPS}
              name="recipientEmail"
              label="Recipient’s E-mail address"
              rules={{
                minLength: { value: 3, message: "Invalid email address" },
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: "Invalid email address",
                },
              }}
            />
          </Grid>
        </Grid>
      </WrappFadeAnimation>
    </>
  );
};
