import styled from "styled-components/macro";
import utils from "utils";

export const StyledComplete = styled.div`
  color: ${utils.colors.grass};
  display: contents;
`;

export const StyledCurrent = styled.div`
  color: ${utils.colors.gold};
  display: contents;
`;

export const StyledPending = styled.div`
  color: #1e1e1e50;
  display: contents;
`;

type FooterProps = {
  color: string | undefined;
};

export const StyledFooterWrapper = styled.div<FooterProps>`
  display: flex;
  color: ${(props) => props.color ?? "inherit"};
  > svg {
    width: 54px !important;
    margin-right: 8px;
    margin-top: -4px;
  }
`;
