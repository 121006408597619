import { Grid } from "@mui/material";
import { P } from "components/Fonts";

type Props = {
  data: {
    label: string;
    value: string;
  }[];
};

export const DataSection: React.FC<Props> = ({ data }) => (
  <>
    {data.map(({ label, value }, index) => (
      <Grid key={index} item xs={12} md={6}>
        <P size="xsmall" colorOpacity="50%">
          {label}
        </P>
        <P size="small">{value}</P>
        <br />
      </Grid>
    ))}
  </>
);
