import { Grid } from "@mui/material";
import { WrappFadeAnimation } from "assets/GlobalStyled";
import { CustomButton } from "components/Buttons";
import { CustomButtonVariants } from "components/Buttons/types";
import { CustomCard } from "components/Cards";
import { P } from "components/Fonts";
import { useNavigate } from "react-router-dom";
import { ROUTER_PATHES } from "routes/routes";
import utils from "utils";
import { BackToDashboardWrapper, SVGPlaneWrapper } from "./styles";

/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
type Props = {
  errors?: {
    detail?: string;
    title: string;
    pointer: string;
  }[];
};

const defaultErrors = [
  { details: "An error has happened", title: "please try again", pointer: "" },
];

export default ({ errors = defaultErrors }: Props): JSX.Element => {
  const navigate = useNavigate();

  const _handleGoDashboard = () => {
    navigate(ROUTER_PATHES.BANKING);
  };

  return (
    <>
      <WrappFadeAnimation>
        <CustomCard borderRadius="strong" shadow="strong">
          <Grid container spacing={1} mb={2} textAlign="center">
            <Grid item xs={12} sm={12}>
              <SVGPlaneWrapper>
                <SVGerror />
              </SVGPlaneWrapper>
              <P size="large" emphasis margin="20px 0px">
                Your Payment Failed
              </P>

              {errors.map(({ detail = "An error has happened", title }) => (
                <P
                  size="small"
                  colorOpacity="50%"
                  margin="20px 0px"
                  color={utils.colors.pink}
                >
                  {`${detail}, ${title}`}
                </P>
              ))}
            </Grid>
          </Grid>
        </CustomCard>
        <BackToDashboardWrapper>
          <CustomButton
            onClick={_handleGoDashboard}
            variant={CustomButtonVariants.black}
            borderRadius="rounded"
          >
            <P size="small">Back To Dashboard</P>
          </CustomButton>
        </BackToDashboardWrapper>
      </WrappFadeAnimation>
    </>
  );
};

const SVGerror = () => (
  <svg width="53" height="53" viewBox="0 0 53 53" fill="none">
    <path
      d="M26.5 0C41.1545 0 53 11.8455 53 26.5C53 41.1545 41.1545 53 26.5 53C11.8455 53 0 41.1545 0 26.5C0 11.8455 11.8455 0 26.5 0ZM36.0135 13.25L26.5 22.7635L16.9865 13.25L13.25 16.9865L22.7635 26.5L13.25 36.0135L16.9865 39.75L26.5 30.2365L36.0135 39.75L39.75 36.0135L30.2365 26.5L39.75 16.9865L36.0135 13.25Z"
      fill="#F3815B"
    />
  </svg>
);
