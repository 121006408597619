import styled from "styled-components/macro";
import { EventAvailable } from "@mui/icons-material";
import utils from "utils";

export const PaymentOptionSubWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 10px;
`;

export const BackToDashboardWrapper = styled.div`
  margin: 40px;
  float: right;
`;

export const SVGPlaneWrapper = styled.div`
  svg {
    height: 40px;
    width: auto;
    margin-right: 20px;
  }
`;

export const EventAvailableStyled = styled(EventAvailable)`
  margin-right: 10px;
  color: ${utils.colors.gold};
`;
