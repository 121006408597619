import { Link } from "react-router-dom";
import { Grid, Stack, Typography } from "@mui/material";
import { ROUTER_PATHES } from "routes/routes";
import { AuthRecoverPassword } from "./AuthRecoverPassword";

const RecoverPassword = () => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="baseline"
          sx={{ mb: { xs: -0.5, sm: 0.5 } }}
        >
          <Typography variant="h3">Recover Password</Typography>
          <Typography
            component={Link}
            to={ROUTER_PATHES.LOGIN}
            variant="body1"
            sx={{ textDecoration: "none" }}
            color="primary"
          >
            Back to Login
          </Typography>
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <AuthRecoverPassword />
      </Grid>
    </Grid>
  );
};

export default RecoverPassword;
