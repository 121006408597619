type OfferKeys = keyof typeof EVERFLOW_OFFERS;
// type Offers = {
//   [K in OfferKeys]: typeof EVERFLOW_OFFERS[OfferKeys];
// };

const EVERFLOW_OFFERS = {
  "NON-US-LLC": "1",
  "DAO-LLC": "2",
};

type PartnersKeys = keyof typeof EVERFLOW_PARTNERS;
// type Partners = {
//   [K in PartnersKeys]: typeof EVERFLOW_PARTNERS[PartnersKeys];
// };

const EVERFLOW_PARTNERS = {
  "INTERNAL-TESTING": "1",
  "LLC-UNIVERSITY": "2",
  TRUIC: "3",
};

export class Everflow {
  partnerId: typeof EVERFLOW_PARTNERS[PartnersKeys] | string;
  offerId: typeof EVERFLOW_OFFERS[OfferKeys] | string;
  transactionId: string;
  goTo: string;

  constructor(urlParams?: URLSearchParams) {
    const { partnerId, offerId, transactionId } =
      this._getEverflowParams(urlParams);
    this.partnerId = partnerId;
    this.offerId = offerId;
    this.transactionId = transactionId;
    this.goTo = "https://www.doola.com";
    // this.goTo = this._getRedirectLink();
  }

  isEverflowPartner = () => {
    if (this.transactionId) return true;
    return false;
  };

  /* 
    PRIVATE METHODS
  */

  _getEverflowParams = (urlParams?: URLSearchParams) => ({
    partnerId: urlParams?.get("affid") || "",
    offerId: urlParams?.get("oid") || "",
    transactionId: urlParams?.get("_ef_transaction_id") || "",
    // goTo: urlParams?.get("goTo") || "",
  });

  _getRedirectLink = () => {
    if (EF_UTILS.isTruicPartner(this.partnerId)) {
      if (EF_UTILS.isDaoOffer(this.offerId))
        return "https://www.doola.com/truic-form-a-wyoming-dao-llc";
      return "https://www.doola.com/truic-llc-formation-service";
    } else {
      if (EF_UTILS.isDaoOffer(this.offerId))
        return "https://www.doola.com/llcu-wyoming-dao";
      return "https://www.doola.com/llcu-formation";
    }
  };
}

export const EF_UTILS = {
  isLLCUniversityPartner: (
    partnerId: typeof EVERFLOW_PARTNERS[PartnersKeys] | string
  ) => partnerId === EVERFLOW_PARTNERS["LLC-UNIVERSITY"],
  isTruicPartner: (
    partnerId: typeof EVERFLOW_PARTNERS[PartnersKeys] | string
  ) => partnerId === EVERFLOW_PARTNERS["TRUIC"],
  isDaoOffer: (offerId: typeof EVERFLOW_OFFERS[OfferKeys]) =>
    offerId === EVERFLOW_OFFERS["DAO-LLC"],
  patnerName: (
    partnerId: typeof EVERFLOW_PARTNERS[PartnersKeys] | undefined
  ) => {
    switch (partnerId) {
      case EVERFLOW_PARTNERS["LLC-UNIVERSITY"]:
        return "LLC-UNIVERSITY";
      case EVERFLOW_PARTNERS["TRUIC"]:
        return "TRUIC";
      case EVERFLOW_PARTNERS["INTERNAL-TESTING"]:
        return "INTERNAL-TESTING";
      default:
        return "";
    }
  },
  offerrName: (offerId: typeof EVERFLOW_OFFERS[OfferKeys] | undefined) => {
    switch (offerId) {
      case EVERFLOW_OFFERS["DAO-LLC"]:
        return "DAO-LLC";
      case EVERFLOW_OFFERS["NON-US-LLC"]:
        return "NON-US-LLC";
      default:
        return "";
    }
  },
};

/*

    TEST URLS:

    the DAO offer LLC UNI:
    http://localhost:3333/?_ef_transaction_id=somehash&affid=2&oid=2
    dev:  https://dev-app.doola.com/?_ef_transaction_id=somehash&affid=2&oid=2&goTo=dao-llc

    the DAO offer Testing:
    http://localhost:3333/?_ef_transaction_id=somehash&affid=1&oid=1&goTo=dao-llc

    The NON-US-LLC:
    http://localhost:3333/?_ef_transaction_id=somehash&affid=1&oid=1


*/
