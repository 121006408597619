export const ROUTER_PATHES = {
  LOGIN: "/login",
  RECOVER_PASSWORD: "/recover-password",
  RESET_PASSWORD: "/reset-password",
  SIGNUP_STEP_1: "/signup/step/1",
  SIGNUP_STEP_2: "/signup/step/2",
  SIGNUP_SUMMARY: "/signup/summary",
  SIGNUP_CHECKOUT: "/signup/checkout",
  SIGNUP_SUCCESS: "/success",
  DASHBOARD: "/dashboard",
  DOCUMENTS: "/documents",
  MAIL: "/mail",
  SERVICES: "/services",
  REFERRALS: "/referrals",
  REWARDS: "/rewards",
  BANKING: "/banking",
  SETTINGS: "/settings",
};

export const BANKING_ROUTES = {
  transaction: "/banking/transaction",
  cards: "/banking/cards",
  payments: "/banking/payments",
  accounts: "/banking/accounts",
  statements: "/banking/statements",
};

export const BANKING_NON_SIDEBAR_ROUTES = {
  card: "/banking/cards/:id",
  createACard: "/banking/cards/create-a-card",
  createACardSucess: "/banking/cards/create-a-card/success/:id",
};
