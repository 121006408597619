export const StepData = [
  {
    label: "Company Formation",
    description:
      "We have received your information and as soon as the state processes your application we will share your documents with you! Once this step is complete we will apply for your Tax ID (Employer Identification Number) which is required to apply for a US Bank Account.",
    footer: {
      text: "This part of the process is dependent on the state and how fast they process your forms.",
      color: "#FE5652",
    },
  },
  {
    label: "EIN Preparation",
    description:
      "Congratulations your company has been successfully formed! We sent you a signature request to sign form SS-4 which we need before we can send your EIN application to the IRS.",
    footer: {
      text: "We are waiting for your signature; please sign Form SS-4 as soon as possible.",
      color: "#FE5652",
    },
  },
  {
    label: "EIN Processing by the IRS",
    description:
      "Whoohoo, we have sent your signed SS-4 to the IRS 🎉👏! Now it is matter of time as this part of the process is fully in the IRS' hands. If you'd like to check on the status of your EIN, as the authorized owner of your company, only you are able to do so and you may do so by calling the IRS, following the instructions in our help center!",
    footer: {
      text: "This part of the process is dependent on the IRS and how fast they process your EIN.",
      color: "#FE5652",
    },
  },
  {
    label: "Banking and Payments",
    description:
      "Congratulations, you have been assigned an EIN 🎉👏 Next up is applying for your US Bank account 🏦 We sent you an email with a link to apply through our custom partner portal link. Congratulations again!",
    footer: undefined,
  },
];
