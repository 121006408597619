import { Card, CardLimits } from "services/banking-api";
import { SAND_BOX_DATA } from "../SandBoxData";
import { TableItem } from "./Table/types";

const mapCardType = (cardType: string) => {
  switch (cardType) {
    case "businessVirtualDebitCard":
      return "Virtual";
    default:
      return "Physical";
  }
};

export const buildCardTableDataMock = (): TableItem[] => {
  return SAND_BOX_DATA.cardsView.table;
};

export const buildCardTableData = async (
  cards: Card[],
  getCardLimitsById: any,
  callbackSucces: (value: any) => void
) => {
  const cardsIds = cards.map(({ id }) => id);

  const promises = cardsIds.map((id) => getCardLimitsById({ cardId: id }));
  const results = (await Promise.all(promises)) as { data: CardLimits }[];

  const spentThisMonth = results.map(({ data }) => {
    const { attributes } = data;
    const { monthlyTotals } = attributes;

    return monthlyTotals.purchases + monthlyTotals.withdrawals;
  });

  callbackSucces(
    cards.map(({ id, type, attributes }, index) => {
      const { fullName, last4Digits, status } = attributes;

      return {
        id: id,
        cardHolder: `${fullName.first} ${fullName.last}`,
        cardNumber: `••${last4Digits}`,
        cardType: mapCardType(type),
        spentThisMonth: spentThisMonth[index],
        status: status,
      };
    })
  );
};
