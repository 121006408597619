import { useRef, useState } from "react";
import { useTheme } from "@mui/material/styles";
import {
  Box,
  ButtonBase,
  CardContent,
  ClickAwayListener,
  Grid,
  Paper,
  Popper,
  PopperPlacementType,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import MainCard from "components/MainCard";
import Transitions from "components/@extended/Transitions";
import { useAuth } from "hooks/useAuth";
import { logOutUser } from "state/authSlice";
import { useAppDispatch } from "state/hooks";
import { useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";
import ProfileTab from "./ProfileTab";
import Avatar from "components/@extended/Avatar";
import { useGetStripeCustomerPortalLinkMutation } from "services/payments-api";
import { LoadingComponent } from "components/Loading";
import { getInitials } from "utils/formatters";
import { SizeProps } from "models/ThemeExtended";
import { AUTH_USER_TOKEN_KEY } from "utils/auth";
import { ROUTER_PATHES } from "routes/routes";
import useCurrentUser from "graphql/hooks/useCurrentUser";

interface Props {
  justAvatar?: boolean;
  popperPlacement?: PopperPlacementType;
  avatarSize?: SizeProps;
}

const Profile = ({
  justAvatar = false,
  popperPlacement = "bottom-end",
  avatarSize = "xs",
}: Props) => {
  const theme = useTheme();
  const { currentCompany } = useAuth();
  const { currentUser } = useCurrentUser();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const matchDownSM = useMediaQuery(theme.breakpoints.down("sm"));
  const anchorRef = useRef<any>(null);
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [stripeCustomerPortalLink, setStripeCustomerPortalLink] = useState<
    string | null
  >("");
  const [fetchStripePortalLink] = useGetStripeCustomerPortalLinkMutation();

  const handleLogout = () => {
    localStorage.removeItem(AUTH_USER_TOKEN_KEY);
    navigate(ROUTER_PATHES.LOGIN);
    dispatch(logOutUser());
    Auth.signOut({ global: true });
  };

  const handleToggle = () => {
    if (!open && !stripeCustomerPortalLink) {
      setIsLoading(true);

      getPortalLink();
    }

    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: MouseEvent | TouchEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const getPortalLink = async () => {
    try {
      const stripePortalData: any = await fetchStripePortalLink({
        customerId: currentUser?.id || "",
        returnUrl: window.location.href,
      }).unwrap();

      if (stripePortalData) {
        setStripeCustomerPortalLink(stripePortalData.url);
      }

      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
    }
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 0.75 }}>
      <ButtonBase
        sx={{
          p: 0.25,
          bgcolor: "transparent",
          borderRadius: 1,
          "&:hover": {
            bgcolor: "transparent",
          },
          "&:focus-visible": {
            outline: `2px solid ${theme.palette.secondary.dark}`,
            outlineOffset: 2,
          },
        }}
        aria-label="open profile"
        ref={anchorRef}
        aria-controls={open ? "profile-grow" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <Stack direction="row" spacing={2} alignItems="center" sx={{ p: 0.2 }}>
          <Avatar
            type="outlined"
            size={avatarSize}
            sx={{ bgcolor: "#FF7A45" }}
            {...getInitials(currentUser?.fullName || "")}
          />
          {!justAvatar && !matchDownSM && (
            <Typography variant="subtitle1">{currentUser?.fullName}</Typography>
          )}
        </Stack>
      </ButtonBase>
      <Popper
        placement={popperPlacement}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal={false}
        sx={{ zIndex: "1200" }}
        popperOptions={{
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, 9],
              },
            },
            {
              name: "preventOverflow",
              enabled: true,
            },
          ],
        }}
      >
        {({ TransitionProps }) => (
          <Transitions type="fade" in={open} {...TransitionProps}>
            {open && (
              <Paper
                sx={{
                  boxShadow: theme.customShadows.z1,
                  width: 290,
                  minWidth: 240,
                  maxWidth: 290,
                  [theme.breakpoints.down("md")]: {
                    maxWidth: 250,
                  },
                }}
              >
                <ClickAwayListener onClickAway={handleClose}>
                  <MainCard elevation={0} border={false} content={false}>
                    <CardContent sx={{ px: 2.5, pt: 3 }}>
                      <Grid
                        container
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Grid item>
                          <Stack
                            direction="row"
                            spacing={1.25}
                            alignItems="center"
                          >
                            <Avatar
                              type="outlined"
                              sx={{ bgcolor: "#FF7A45" }}
                              {...getInitials(currentUser?.fullName || "")}
                            />
                            <Stack>
                              <Typography variant="h6">
                                {currentUser?.fullName}
                              </Typography>
                              <Typography variant="body2" color="textSecondary">
                                {currentCompany.companyDetails.name}
                              </Typography>
                            </Stack>
                          </Stack>
                        </Grid>
                      </Grid>
                    </CardContent>
                    {isLoading ? (
                      <CardContent sx={{ px: 2.5, pt: 3 }}>
                        <LoadingComponent />
                      </CardContent>
                    ) : (
                      open && (
                        <ProfileTab
                          handleLogout={handleLogout}
                          stripeCustomerPortalLink={stripeCustomerPortalLink}
                        />
                      )
                    )}
                  </MainCard>
                </ClickAwayListener>
              </Paper>
            )}
          </Transitions>
        )}
      </Popper>
    </Box>
  );
};

export default Profile;
