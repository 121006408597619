import { Theme } from "@mui/material/styles";
import { Box, useMediaQuery } from "@mui/material";
import Profile from "./Profile";
import { MakeAPayment } from "views/DashboardApp/Banking/SharedComponents/MakeAPayment";
import { useLocation } from "react-router-dom";
import { MakeAPaymentWrapper } from "./styles";
import Notification from "components/Notification";
import { BANKING_ROUTES } from "routes/routes";

const HeaderContent = () => {
  const matchDownMD = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );

  const isABankingView = Object.values(BANKING_ROUTES).includes(
    useLocation().pathname
  );

  return (
    <>
      {matchDownMD && isABankingView && (
        <MakeAPaymentWrapper>
          <MakeAPayment />
        </MakeAPaymentWrapper>
      )}
      <Box sx={{ width: "100%", ml: { xs: 0, md: 1 } }} />
      <Notification />
      <Profile />
    </>
  );
};

export default HeaderContent;
