import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  DepositAccountResponse,
  AccountEndOfDayResponse,
  TransactionsResponse,
  GetAccountForCustomerResponse,
  Payment,
  PaymentResponse,
  PaymentAttributesCounterparty,
  Statement,
} from "models/Requests";
import { format } from "fecha";
import { selectToken } from "state/authBankingSlice";
import { RootState } from "state/store";
import config from "config";
import { Auth } from "aws-amplify";

export interface AccountEndOfDayRequest {
  accountId: string;
  sinceDate: Date;
}

interface TransactionsRequest {
  accountId: string;
  sinceDate?: Date;
  includeAccount?: boolean;
}

export interface GetAccountByIDRequest {
  accountId: string;
}

export interface GetCardsByAccountRequest {
  accountId: string;
}

export interface GetCardByIdRequest {
  cardId: string;
}

export interface GetCardLimitsByIdRequest {
  cardId: string;
}
export interface CardLimits {
  attributes: {
    limits: {
      dailyWithdrawal: number;
      dailyPurchase: number;
      monthlyWithdrawal: number;
      monthlyPurchase: number;
    };
    dailyTotals: {
      withdrawals: number;
      deposits: number;
      purchases: number;
    };
    monthlyTotals: {
      withdrawals: number;
      deposits: number;
      purchases: number;
    };
  };
}

export interface GetCardLimitsByIdRequest {
  cardId: string;
}

export interface GetAccountForCustomerRequest {
  customerId: string;
}

export interface GetTokenForCustomerRequest {
  customerId: string;
}

export interface GetStatementListRequest {
  customerId: string;
}

export interface PaymentRequest {
  amount: number;
  description: string;
  direction: string;
  token: string;
  accountId: string;
  idempotencyKey: string;
  counterparty: PaymentAttributesCounterparty;
}

export interface CardBasics {
  fullName: CardOwnerFullName;
  address: CardOwnerAddress;
  dateOfBirth: string;
  email: string;
  phone: CardOwnerPhone;
}

export interface CardAttributes extends CardBasics {
  createdAt: string;
  expirationDate: string;
  last4Digits: string;
  status: string;
}

export interface Card {
  type: string;
  id: string;
  attributes: CardAttributes;
}

export interface CardOwnerFullName {
  first: string;
  last: string;
}

export interface CardOwnerAddress {
  street: string;
  street2?: string;
  city: string;
  state: string;
  postalCode: string;
  country: string;
}

export interface CardOwnerPhone {
  countryCode: string;
  number: string;
}

export interface CreateCardRequest extends CardBasics {
  token: string;
  accountId: string;
}

const BANKING_URL = `${config.api.URL}/banking/banking`;
const BANKING_API_URL = `${config.api.URL}/banking/api`;

export const downloadStatement = async (
  period: string,
  statementId: string
) => {
  const url = `${BANKING_API_URL}/v1/account/statement?id=${statementId}`;
  try {
    const token = (await Auth.currentSession()).getIdToken().getJwtToken();
    const headers = { authorization: token };
    fetch(url, { headers: headers })
      .then((resp) => resp.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        // the filename you want
        a.download = `statement-${period}`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch(() => alert("oh no!"));
  } catch (e) {
    console.log(e);
  }
};

export const doolaBankingApi = createApi({
  reducerPath: "doolaBankingApi",
  baseQuery: fetchBaseQuery({
    baseUrl: BANKING_API_URL,
    prepareHeaders: async (headers, { getState }) => {
      try {
        const token = (await Auth.currentSession()).getIdToken().getJwtToken();
        headers.set("authorization", token);
      } catch (e) {
        console.log(e);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getAccountStatementList: builder.mutation<
      Statement[],
      GetStatementListRequest
    >({
      query: ({ customerId }) => ({
        url: `v1/account/${customerId}/statements`,
        method: "GET",
      }),
      transformResponse: (res) => res as Statement[],
    }),
  }),
});

export const bankingApi = createApi({
  reducerPath: "bankingApi",
  baseQuery: fetchBaseQuery({
    baseUrl: BANKING_URL,
    prepareHeaders: async (headers, { getState }) => {
      const authHeader = headers.get("authorization");

      if (!authHeader) {
        let token = selectToken(getState() as RootState);
        headers.set("authorization", `Bearer ${token}`);
      }

      return headers;
    },
  }),
  endpoints: (builder) => ({
    getAccountForCustomerAndToken: builder.mutation<
      GetAccountForCustomerResponse,
      GetAccountForCustomerRequest
    >({
      query: ({ customerId }) => ({
        url: `accounts?filter[customerId]=${customerId}`,
        method: "GET",
      }),
      transformResponse: (res) => ({ accountId: (res as any).data.pop().id }),
    }),

    getAccountByIdAndToken: builder.mutation<
      DepositAccountResponse,
      GetAccountByIDRequest
    >({
      query: ({ accountId }) => ({
        url: `accounts/${accountId}`,
        method: "GET",
      }),
      transformResponse: (res) => (res as any).data,
    }),

    getCardsByAccount: builder.mutation<Card[], GetCardsByAccountRequest>({
      query: ({ accountId }) => ({
        url: `cards?filter[accountId]=${accountId}`,
        method: "GET",
      }),
      transformResponse: (res: any) => res.data,
    }),

    getCardById: builder.mutation<Card, GetCardByIdRequest>({
      query: ({ cardId }) => ({
        url: `cards/${cardId}?include=customer`,
        method: "GET",
      }),
      transformResponse: (res: any) => res.data,
    }),

    getCardLimitsById: builder.mutation<CardLimits, GetCardLimitsByIdRequest>({
      query: ({ cardId }) => ({
        url: `cards/${cardId}/limits`,
        method: "GET",
      }),
      transformResponse: (res: any) => res.data,
    }),

    createCardForAccount: builder.mutation<Card, CreateCardRequest>({
      query: ({
        accountId,
        token,
        fullName,
        address,
        dateOfBirth,
        email,
        phone,
      }) => ({
        url: `cards`,
        method: "POST",
        headers: {
          "Content-Type": "application/vnd.api+json",
          authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          data: {
            type: "businessVirtualDebitCard",
            attributes: {
              fullName,
              address,
              dateOfBirth,
              email,
              phone,
            },
            relationships: {
              account: {
                data: {
                  type: "depositAccount",
                  id: accountId,
                },
              },
            },
          },
        }),
      }),
      transformResponse: (res: any) => res.data,
    }),

    getAccountEndOfDayByIdAndToken: builder.mutation<
      AccountEndOfDayResponse[],
      any
    >({
      query: ({ accountId, sinceDate }: AccountEndOfDayRequest) => ({
        url:
          `account-end-of-day` +
          `?filter[accountId]=${accountId}` +
          `&filter[since]=${format(sinceDate, "isoDate")}`,
        method: "GET",
      }),

      transformResponse: (res: any) => res.data.reverse(),
    }),

    getTransactionsSinceByIdAndToken: builder.mutation<
      TransactionsResponse,
      TransactionsRequest
    >({
      query: ({ accountId, sinceDate, includeAccount }) => {
        let url = `transactions/?filter[accountId]=${accountId}&page[limit]=1000`;
        if (sinceDate) {
          url =
            url +
            `&filter[since]=${format(sinceDate, "isoDate")}T00:00:00.316Z`;
        }

        if (includeAccount) {
          url = url + `&include=account,customer`;
        }

        return {
          url: url,
          method: "GET",
        };
      },
      // transformResponse: (res: any) => res.data,
    }),

    sendPayment: builder.mutation<Payment, any>({
      query: ({
        token,
        amount,
        description,
        direction,
        counterparty,
        accountId,
        idempotencyKey,
      }: PaymentRequest) => ({
        url: `payments`,
        method: "POST",
        headers: {
          "Content-Type": "application/vnd.api+json",
          authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          data: {
            type: "achPayment",
            attributes: {
              amount,
              description,
              direction,
              counterparty,
              idempotencyKey,
            },
            relationships: {
              account: {
                data: {
                  type: "depositAccount",
                  id: accountId,
                },
              },
            },
          },
        }),
      }),
      transformResponse: (res: PaymentResponse) => res.data,
    }),
  }),
});

export const {
  useGetAccountForCustomerAndTokenMutation,
  useGetTransactionsSinceByIdAndTokenMutation,
  useGetAccountByIdAndTokenMutation,
  useGetAccountEndOfDayByIdAndTokenMutation,
  useSendPaymentMutation,
  useGetCardsByAccountMutation,
  useCreateCardForAccountMutation,
  useGetCardByIdMutation,
  useGetCardLimitsByIdMutation,
} = bankingApi;

export const { useGetAccountStatementListMutation } = doolaBankingApi;
