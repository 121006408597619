import { ProgressStepper } from "components/ProgressStepper";
import { CompanyService } from "models/ServiceModel";
import { LoadingComponent } from "components/Loading";
import { StepData } from "./data";
import { getCurrentStepByServices } from "./services";
import { useAuth } from "hooks/useAuth";

type Props = {
  services: CompanyService[];
};

export const YourPlan: React.FC<Props> = ({ services }) => {
  const currentStepIndex = getCurrentStepByServices(services);
  const progressValue = (100 * (currentStepIndex + 1 / 2)) / StepData.length;

  return (
    <ProgressStepper
      percentajeValue={progressValue}
      title="Your Plan"
      stepData={StepData.map((e, i) => ({
        ...e,
        isDone: i < currentStepIndex,
      }))}
    />
  );
};

export const YourPlanFetcher: React.FC = () => {
  const { currentCompany } = useAuth();

  if (!currentCompany) return <LoadingComponent />;

  return <YourPlan services={currentCompany.services} />;
};

type YourPlanContainerProps = {
  services?: CompanyService[];
};

export const YourPlanContainer: React.FC<YourPlanContainerProps> = ({
  services,
}) => {
  if (services) {
    return <YourPlan services={services} />;
  }
  return <YourPlanFetcher />;
};

export default YourPlanContainer;
