import { Grid } from "@mui/material";
import { WrappFadeAnimation } from "assets/GlobalStyled";
import { CustomButton } from "components/Buttons";
import { CustomButtonVariants } from "components/Buttons/types";
import { CustomCard } from "components/Cards";
import { P } from "components/Fonts";
import { useFormContext } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { ROUTER_PATHES } from "routes/routes";
import utils from "utils";
import { moneyFormatter } from "utils/formatters";
import { SVGCheck } from "../../SharedComponents/SVGs";
import { BackToDashboardWrapper, SVGPlaneWrapper } from "./styles";

/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */

export default (): JSX.Element => {
  const { getValues } = useFormContext();
  const transactionValue = getValues("transactionValue");
  const recipientName = getValues("recipientName");

  const navigate = useNavigate();

  const _handleGoDashboard = () => {
    navigate(ROUTER_PATHES.BANKING);
  };

  return (
    <>
      <WrappFadeAnimation>
        <CustomCard borderRadius="strong" shadow="strong">
          <Grid container spacing={1} mb={2} textAlign="center">
            <Grid item xs={12} sm={12}>
              <SVGPlaneWrapper>
                <SVGCheck color={utils.colors.gold} />
              </SVGPlaneWrapper>
              <P size="large" emphasis margin="20px 0px">
                You’ve Sent ${moneyFormatter(transactionValue, false)} to{" "}
                {recipientName}
              </P>
              <P size="small" colorOpacity="50%" margin="20px 0px">
                The money should arrive within 3 business days.
              </P>
            </Grid>
          </Grid>
        </CustomCard>
        <BackToDashboardWrapper>
          <CustomButton
            onClick={_handleGoDashboard}
            variant={CustomButtonVariants.black}
            borderRadius="rounded"
          >
            <P size="small">Back To Dashboard</P>
          </CustomButton>
        </BackToDashboardWrapper>
      </WrappFadeAnimation>
    </>
  );
};
