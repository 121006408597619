import { isProduction } from "config";
import { PricingPackagesIds } from "models/PricePackages";

/*
 * Company Formation Package
 */
const companyFormationPackageData = {
  id: PricingPackagesIds.companyFormationPackage,
  label: "Formation",
  price: 197,
  stripePriceId: isProduction
    ? "price_1LVuVAGIhH9I2BWrWkosBpXC"
    : "price_1LY7fuGIhH9I2BWrMJSzAG5W",
  isMonthly: false,
  stateFeeIncluded: false,
  description: "Your US company formation.",
  name: "Formation",
  recurring: false,
  saveAmount: 623,
  services: [
    {
      label: "Operating Agreement",
      value: 99,
      discount: true,
    },
    {
      label: "EIN Number Application",
      value: 30,
      discount: true,
    },
    {
      label: "Registered Agent & State Compliance Filings*",
      value: 197,
      discount: true,
    },
    {
      label: "US Mailing Address",
      value: 49,
      discount: true,
    },
    {
      label: "Formation filing",
      value: 49,
      discount: true,
    },
    {
      label:
        "Assistance with setting up your US Bank Account + Stripe Payment Gateway",
      value: 199,
      discount: true,
    },
  ],
};

/*
 * Tax & Compliance Bundle
 */
const taxComplianceBundle = {
  id: PricingPackagesIds.totalComplianceBundle,
  label: "Formation + Total Compliance",
  name: "Formation + Total Compliance",
  recurring: true,
  stateFeeIncluded: false,
  description:
    "Form and fully cover your compliance, tax, & bookkeeping needs.",
  saveAmount: 820,
  services: [
    {
      label: "Operating Agreement",
      value: 99,
      discount: true,
    },
    {
      label: "EIN Number Application",
      value: 30,
      discount: true,
    },
    {
      label: "Free Registered Agent Service for 365 Days",
      value: 197,
      discount: true,
    },
    {
      label: "US Mailing Address",
      value: 49,
      discount: true,
    },
    {
      label: "Formation filing",
      value: 49,
      discount: true,
    },
    {
      label:
        "Assistance with setting up your US Bank Account + Stripe Payment Gateway",
      value: 199,
      discount: true,
    },
  ],
};

const taxComplianceBundleMonthlyData = {
  ...taxComplianceBundle,
  price: 300,
  stripePriceId: isProduction
    ? "price_1LwuCbGIhH9I2BWrHVBDCNsD"
    : "price_1MEDlYGIhH9I2BWraRfJTsdG",
  isMonthly: true,
};

const taxComplianceBundleAnnualData = {
  ...taxComplianceBundle,
  price: 2200,
  stripePriceId: isProduction
    ? "price_1LzVLWGIhH9I2BWr9QM0vCnC"
    : "price_1M1sUkGIhH9I2BWr5VChnUeJ",
  isMonthly: false,
  saveAmount: 2220,
};

export const PricingPackagesData = {
  companyFormationPackage: companyFormationPackageData,
  taxComplianceBundleMonthly: taxComplianceBundleMonthlyData,
  taxComplianceBundleAnnual: taxComplianceBundleAnnualData,
};
