import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  useTheme,
} from "@mui/material";
import {
  LogoutOutlined,
  ScheduleOutlined,
  UserOutlined,
  WalletOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { ROUTER_PATHES } from "routes/routes";
import { LinkExternalDomain } from "components/LinkExternalDomain";
import { GAClickTrackerWrapper } from "components/GoogleAnalytics";
import { LINK_SCHEDULE_A_CALL } from "resources/scheduleACall";
import { EF_UTILS } from "resources/everflow";
import { useAppSelector } from "state/hooks";
import { selectEverflowPartner } from "state/authSlice";

interface Props {
  stripeCustomerPortalLink: string | null;
  handleLogout: () => void;
}

const ProfileTab = ({ handleLogout, stripeCustomerPortalLink }: Props) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { partnerId } = useAppSelector(selectEverflowPartner);

  const menuItemClickHandler = (routeTo: string) => {
    navigate(routeTo);
  };

  return (
    <List
      component="nav"
      sx={{
        p: 0,
        "& .MuiListItemIcon-root": {
          minWidth: 32,
          color: theme.palette.secondary.dark,
        },
      }}
    >
      <ListItemButton
        onClick={(event: React.MouseEvent<HTMLDivElement>) =>
          menuItemClickHandler(ROUTER_PATHES.SETTINGS)
        }
      >
        <ListItemIcon>
          <UserOutlined />
        </ListItemIcon>
        <ListItemText primary="View Profile" />
      </ListItemButton>
      {stripeCustomerPortalLink && (
        <LinkExternalDomain to={stripeCustomerPortalLink}>
          <ListItemButton>
            <ListItemIcon>
              <WalletOutlined />
            </ListItemIcon>
            <ListItemText primary="Billing" />
          </ListItemButton>
        </LinkExternalDomain>
      )}
      <GAClickTrackerWrapper event="schedule_call" labelReference="banner">
        <LinkExternalDomain
          to={LINK_SCHEDULE_A_CALL(EF_UTILS.patnerName(partnerId))}
        >
          <ListItemButton>
            <ListItemIcon>
              <ScheduleOutlined />
            </ListItemIcon>
            <ListItemText primary="Book a free consultation!" />
          </ListItemButton>
        </LinkExternalDomain>
      </GAClickTrackerWrapper>
      <ListItemButton onClick={handleLogout}>
        <ListItemIcon>
          <LogoutOutlined />
        </ListItemIcon>
        <ListItemText primary="Logout" />
      </ListItemButton>
    </List>
  );
};

export default ProfileTab;
