import stateKeys from "./stateKeys";

export type BusinessTypeNames = "LLC" | "DAO" | "CCorp";

type BusinessTypeStylesLLC = "LLC" | "L.L.C" | "Limited Liability Company";
type BusinessTypeStylesDAO =
  | "DAO"
  | "DAO LLC"
  | "D.A.O L.L.C"
  | "Decentralized Autonomous Organization Limited Liability Company";
type BusinessTypeStylesCCORP = "C Corp" | "C Corporation";

type entityTypeProps = Record<
  BusinessTypeNames,
  {
    value:
      | BusinessTypeStylesLLC
      | BusinessTypeStylesDAO
      | BusinessTypeStylesCCORP;
    states: {
      name: string | JSX.Element;
      value: string;
    }[];
  }
>;

const entityTypes: entityTypeProps = {
  DAO: {
    value: "DAO LLC",
    states: [
      {
        name: "Wyoming",
        value: "WY",
      },
    ],
  },
  CCorp: {
    value: "C Corp",
    states: Object.entries(stateKeys).map(([key, value]) => ({
      name: value,
      value: key,
    })),
  },
  LLC: {
    value: "LLC",
    states: Object.entries(stateKeys).map(([key, value]) => ({
      name: value,
      value: key,
    })),
  },
};

export default entityTypes;
