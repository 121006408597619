import { Popover, useMediaQuery } from "@mui/material";
import { CustomCard } from "components/Cards";
import { P } from "components/Fonts";
import { useState } from "react";
import utils from "utils";
import { ItemWrapper, SVGThreeDotsStyled, WrapperAddAnother } from "./styles";

export const PopOver: React.FC = () => {
  const isMobile = useMediaQuery(`(max-width:${utils.screenSize.mobile})`);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const handleClick = (event: any) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event: any) => {
    event.stopPropagation();
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const _hanldeOnClick = (event: any) => {
    console.log("clicking");
    handleClose(event);
  };

  return (
    <P
      size={isMobile ? "xsmall" : "small"}
      style={{ textAlign: isMobile ? "center" : "left" }}
    >
      <SVGThreeDots onClick={handleClick} isMobile={isMobile} />

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        sx={{ boxShadow: "none", marginTop: "10px" }}
        PaperProps={{
          elevation: 0,
          style: {
            backgroundColor: "transparent",
            boxShadow: "0px 3px 2px rgba(0, 0, 0, 0.04)",
            borderRadius: "16px",
            minWidth: "200px",
            overflow: "hidden",
          },
        }}
      >
        <CustomCard borderRadius="strong" shadow="strong">
          <ItemWrapper onClick={_hanldeOnClick}>
            <SVGSnow />
            <P size="small">Freeze card</P>
          </ItemWrapper>
          <ItemWrapper onClick={_hanldeOnClick}>
            <SVGLock />
            <P size="small">Reset PIN</P>
          </ItemWrapper>

          <WrapperAddAnother>
            <ItemWrapper onClick={_hanldeOnClick}>
              <SVGTrash />
              <P size="small" color={utils.colors.neonPink}>
                Cancel card
              </P>
            </ItemWrapper>
          </WrapperAddAnother>
        </CustomCard>
      </Popover>
    </P>
  );
};

const SVGThreeDots = ({
  onClick,
  isMobile,
}: {
  onClick: any;
  isMobile: boolean;
}) => {
  return (
    <SVGThreeDotsStyled>
      <svg width="16" height="4" viewBox="0 0 16 4" onClick={onClick}>
        <path
          opacity="0.5"
          d="M12 2C12 1.46957 12.2107 0.96086 12.5858 0.585787C12.9609 0.210714 13.4696 0 14 0C14.5304 0 15.0391 0.210714 15.4142 0.585787C15.7893 0.96086 16 1.46957 16 2C16 2.53043 15.7893 3.03914 15.4142 3.41421C15.0391 3.78929 14.5304 4 14 4C13.4696 4 12.9609 3.78929 12.5858 3.41421C12.2107 3.03914 12 2.53043 12 2ZM6 2C6 1.46957 6.21071 0.96086 6.58579 0.585787C6.96086 0.210714 7.46957 0 8 0C8.53043 0 9.03914 0.210714 9.41421 0.585787C9.78929 0.96086 10 1.46957 10 2C10 2.53043 9.78929 3.03914 9.41421 3.41421C9.03914 3.78929 8.53043 4 8 4C7.46957 4 6.96086 3.78929 6.58579 3.41421C6.21071 3.03914 6 2.53043 6 2ZM0 2C0 1.46957 0.210714 0.96086 0.585786 0.585787C0.960859 0.210714 1.46957 0 2 0C2.53043 0 3.03914 0.210714 3.41421 0.585787C3.78929 0.96086 4 1.46957 4 2C4 2.53043 3.78929 3.03914 3.41421 3.41421C3.03914 3.78929 2.53043 4 2 4C1.46957 4 0.960859 3.78929 0.585786 3.41421C0.210714 3.03914 0 2.53043 0 2V2Z"
          fill="#1E1E1E"
        />
      </svg>
    </SVGThreeDotsStyled>
  );
};

const SVGSnow = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path
      opacity="0.5"
      d="M18.7902 11.95L16.4602 12.57L14.4602 11.44V8.56L16.4602 7.43L18.7902 8.05L19.3102 6.12L17.5402 5.65L18.0002 3.88L16.0702 3.36L15.4502 5.69L13.4502 6.82L11.0002 5.38V3.12L12.7102 1.41L11.2902 0L10.0002 1.29L8.7102 0L7.2902 1.41L9.0002 3.12V5.38L6.5002 6.82L4.5002 5.69L3.9202 3.36L2.0002 3.88L2.4702 5.65L0.700195 6.12L1.2202 8.05L3.5502 7.43L5.5502 8.56V11.45L3.5502 12.58L1.2202 11.96L0.700195 13.89L2.4702 14.36L2.0002 16.12L3.9302 16.64L4.5502 14.31L6.5502 13.18L9.0002 14.62V16.88L7.2902 18.59L8.7102 20L10.0002 18.71L11.2902 20L12.7002 18.59L11.0002 16.88V14.62L13.5002 13.17L15.5002 14.3L16.1202 16.63L18.0002 16.12L17.5302 14.35L19.3002 13.88L18.7902 11.95ZM7.5002 8.56L10.0002 7.11L12.5002 8.56V11.44L10.0002 12.89L7.5002 11.44V8.56Z"
      fill="#1E1E1E"
    />
  </svg>
);

const SVGLock = () => (
  <svg width="16" height="21" viewBox="0 0 16 21" fill="none">
    <path
      opacity="0.5"
      d="M8 16C8.53043 16 9.03914 15.7893 9.41421 15.4142C9.78929 15.0391 10 14.5304 10 14C10 12.89 9.1 12 8 12C7.46957 12 6.96086 12.2107 6.58579 12.5858C6.21071 12.9609 6 13.4696 6 14C6 14.5304 6.21071 15.0391 6.58579 15.4142C6.96086 15.7893 7.46957 16 8 16ZM14 7C14.5304 7 15.0391 7.21071 15.4142 7.58579C15.7893 7.96086 16 8.46957 16 9V19C16 19.5304 15.7893 20.0391 15.4142 20.4142C15.0391 20.7893 14.5304 21 14 21H2C1.46957 21 0.960859 20.7893 0.585786 20.4142C0.210714 20.0391 0 19.5304 0 19V9C0 7.89 0.9 7 2 7H3V5C3 3.67392 3.52678 2.40215 4.46447 1.46447C5.40215 0.526784 6.67392 0 8 0C8.65661 0 9.30679 0.129329 9.91342 0.380602C10.52 0.631876 11.0712 1.00017 11.5355 1.46447C11.9998 1.92876 12.3681 2.47995 12.6194 3.08658C12.8707 3.69321 13 4.34339 13 5V7H14ZM8 2C7.20435 2 6.44129 2.31607 5.87868 2.87868C5.31607 3.44129 5 4.20435 5 5V7H11V5C11 4.20435 10.6839 3.44129 10.1213 2.87868C9.55871 2.31607 8.79565 2 8 2Z"
      fill="#1E1E1E"
    />
  </svg>
);

const SVGTrash = () => (
  <svg width="14" height="18" viewBox="0 0 14 18" fill="none">
    <path
      d="M14 1H10.5L9.5 0H4.5L3.5 1H0V3H14V1ZM1 16C1 16.5304 1.21071 17.0391 1.58579 17.4142C1.96086 17.7893 2.46957 18 3 18H11C11.5304 18 12.0391 17.7893 12.4142 17.4142C12.7893 17.0391 13 16.5304 13 16V4H1V16Z"
      fill="#FE5652"
    />
  </svg>
);
