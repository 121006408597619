import { THRESHOLD_FOR_INCLUDED_TAX_COMPLIANCE_BUNDLE } from "configs";
import { PersistedCompany } from "models/CompanyModel";
import {
  CompanyService,
  EINServiceState,
  ServiceNames,
  ServiceStatus,
} from "models/ServiceModel";

const stepIndexes = {
  default: -1,
  companyFormation: 0,
  einPreparation: 1,
  einProcessingByIRS: 2,
  BankingAndPayments: 3,
  Completed: 4,
};

export const PlanServices = [
  ServiceNames.llcFormation,
  ServiceNames.einCreation,
  ServiceNames.banking,
];

const EinProcessingByIRSStatesnames = [
  EINServiceState.waitingOnEINFromIRS,
  EINServiceState.applyingForEINFaxToIRS,
  EINServiceState.applyingForEINOnline,
  EINServiceState.einResubmissionRequired,
] as string[];

export const isServiceComplete = (
  services: CompanyService[],
  serviceName: string
) => {
  const serviceState = services.find(
    ({ name }) => name === serviceName
  )?.status;

  return serviceState === ServiceStatus.completed;
};

export const isServiceInactive = (
  services: CompanyService[],
  serviceName: string
) => {
  const serviceState = services.find(
    ({ name }) => name === serviceName
  )?.status;

  return serviceState === ServiceStatus.inactive;
};

export const getCurrentStepByServices = (services: CompanyService[]) => {
  if (!isServiceComplete(services, ServiceNames.llcFormation)) {
    return stepIndexes.companyFormation;
  } else if (!isServiceComplete(services, ServiceNames.einCreation)) {
    const einServiceState = services.find(
      ({ name }) => name === ServiceNames.einCreation
    )?.serviceState;

    if (EinProcessingByIRSStatesnames.includes(einServiceState as string)) {
      return stepIndexes.einProcessingByIRS;
    } else {
      return stepIndexes.einPreparation;
    }
  } else if (!isServiceComplete(services, ServiceNames.banking)) {
    return stepIndexes.BankingAndPayments;
  } else {
    return stepIndexes.Completed;
  }
};

export const canPurchaseTaxComplianceBundle = (company: PersistedCompany) => {
  if (companyWasCreatedWhenTaxBundleWasStillIncluded(company)) {
    return false;
  }

  return (
    servicesIncludeCompletedBanking(company.services) &&
    !servicesIncludeCompletedTaxComplianceBundle(company.services)
  );
};

export const companyWasCreatedWhenTaxBundleWasStillIncluded = (
  company: PersistedCompany
) => {
  return (
    new Date(company.createdAt) <
    new Date(THRESHOLD_FOR_INCLUDED_TAX_COMPLIANCE_BUNDLE)
  );
};

export const servicesIncludeCompletedTaxComplianceBundle = (
  services: CompanyService[]
) => {
  return isServiceComplete(services, ServiceNames.taxComplianceBundle);
};

export const servicesIncludeCompletedBanking = (services: CompanyService[]) => {
  return isServiceComplete(services, ServiceNames.banking);
};
