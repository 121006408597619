import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { P } from "components/Fonts";
import CloseModalSVG from "./close_modal.svg";
import { StyledCloseButton, StyledDialogTitle } from "./styles";

type Props = {
  title: JSX.Element | string;
  description: JSX.Element | string;
  handleClose: () => void;
  fullWidth?: boolean;
  notShowCloseButton?: boolean;
  maxWidth?: "xs" | "sm" | "md" | "lg" | "xl";
};

export default function InfoModal({
  handleClose,
  description,
  title,
  fullWidth,
  notShowCloseButton,
  maxWidth = "md",
}: Props) {
  return (
    <Dialog
      style={{ zIndex: 2000 }}
      open={true}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      keepMounted
      onClick={(event) => {
        event.stopPropagation();
      }}
      // onClose={handleClose} -> activate in case modal should close clicking the background.
    >
      <StyledDialogTitle $modalTitle={title}>
        {title && (
          <P size="medium" style={{ paddingRight: "24px" }} emphasis>
            {title}
          </P>
        )}
        {!notShowCloseButton && (
          <StyledCloseButton
            src={CloseModalSVG}
            alt="close"
            onClick={handleClose}
          />
        )}
      </StyledDialogTitle>
      <DialogContent>
        <DialogContentText>
          <P size="small">{description}</P>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
}
