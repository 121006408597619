export const STEPS_DATA = [
  {
    title: "Invitation",
    description: "Email or share your referral link",
    referralStatusKey: "invited",
  },
  {
    title: "Sign up and pay for doola",
    description: "Email or share your referral link",
    referralStatusKey: "signed-up",
  },
  {
    title: "Bonus",
    description: "You receive a 15% commission",
    referralStatusKey: "bonus-sent",
  },
];
