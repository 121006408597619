import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import { store } from "./state/store";
import { Provider } from "react-redux";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import { BrowserRouter } from "react-router-dom";
import { Tracker } from "services/tracking";
import { IntercomProvider } from "react-use-intercom";
import config from "config";
import { ConfigProvider } from "contexts/ConfigContext";
import { ApolloProvider } from "@apollo/client";
import { client } from "Apollo/client";

Tracker.getInstance();
let persistor = persistStore(store);

const MainApp: React.FC<{}> = () => {
  return (
    <React.StrictMode>
      <ApolloProvider client={client}>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <ConfigProvider>
              <BrowserRouter>
                <IntercomProvider
                  appId={config.intercomAppId}
                  apiBase="https://api-iam.intercom.io"
                  autoBoot
                >
                  <App />
                </IntercomProvider>
              </BrowserRouter>
            </ConfigProvider>
          </PersistGate>
        </Provider>
      </ApolloProvider>
    </React.StrictMode>
  );
};

const container = document.getElementById("root");
const root = createRoot(container!);

root.render(<MainApp />);
