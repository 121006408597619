import { CheckboxProps } from "@mui/material/Checkbox";
import { RadioGroupProps } from "@mui/material/RadioGroup";
import { TextFieldProps } from "@mui/material/TextField";
import get from "lodash/get";
import { Controller } from "react-hook-form";
import {
  Control,
  DeepMap,
  FieldError,
  FieldValues,
  UseFormRegister,
} from "react-hook-form";
import {
  ExtraProps,
  RadioGroupCustomProps,
  SelectCustomProps,
  SelectProps,
  StyledCheckbox,
  StyledFieldProps,
  StyledRadioGroup,
  StyledSelect,
  StyledTextField,
} from "./FormFields";

type CheckboxCustomProps = {
  value?: boolean;
} & StyledFieldProps;

type ControlFunctions = {
  control: Control<FieldValues>;
  register: UseFormRegister<FieldValues>;
  errors?: DeepMap<FieldValues, FieldError>;
  name: string;
  defaultValue?: string;
};

export const ControlledRadioGroup = (
  props: RadioGroupProps & ControlFunctions & ExtraProps & RadioGroupCustomProps
) => {
  const {
    control,
    name,
    errors,
    required,
    helperText,
    defaultValue,
    ...componentProps
  } = props;
  const hasError = get(errors, name, false);
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue || ""}
      rules={{ required: required ? "Field is required" : false }}
      render={({ field }) => (
        <StyledRadioGroup
          error={hasError}
          required={required}
          helperText={hasError?.message ? hasError?.message : helperText}
          {...field}
          {...componentProps}
        />
      )}
    />
  );
};

export const ControlledCheckbox = (
  props: CheckboxProps & ControlFunctions & ExtraProps & CheckboxCustomProps
) => {
  const {
    control,
    name,
    errors,
    required,
    helperText,
    defaultValue,
    ...componentProps
  } = props;
  const hasError = get(errors, name, false);
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue || ""}
      render={({ field }) => (
        <StyledCheckbox
          id={name}
          error={hasError}
          required={required}
          helperText={hasError?.message ? hasError?.message : helperText}
          {...componentProps}
          {...field}
          value={componentProps.value}
        />
      )}
    />
  );
};

export const ControlledSelectField = (
  props: SelectProps & ControlFunctions & ExtraProps & SelectCustomProps
) => {
  const {
    control,
    name,
    errors,
    required,
    helperText,
    defaultValue,
    ...componentProps
  } = props;
  const hasError = get(errors, name, false);
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue || ""}
      rules={{ required: required ? "Field is required" : false }}
      render={({ field }) => (
        <StyledSelect
          error={hasError}
          required={required}
          helperText={hasError?.message ? hasError?.message : helperText}
          {...field}
          {...componentProps}
        />
      )}
    />
  );
};

export const ControlledTextField = (
  props: TextFieldProps & ControlFunctions & ExtraProps
) => {
  const {
    control,
    name,
    errors,
    required,
    helperText,
    register,
    rules,
    defaultValue,
    ...componentProps
  } = props;
  const hasError = get(errors, name, false);

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue || ""}
      render={({ field }) => (
        <StyledTextField
          id={name}
          error={!!hasError}
          required={required}
          helperText={hasError?.message ? hasError?.message : helperText}
          {...register(name, {
            ...(required && { required: "Field is required" }),
            ...rules,
          })}
          {...componentProps}
          {...field}
        />
      )}
    />
  );
};
