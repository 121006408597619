import { Grid } from "@mui/material";
import { CustomCard } from "components/Cards";
import { P } from "components/Fonts";
import { LoadingComponent } from "components/Loading";
import { useAuthBanking } from "hooks/useAuthBanking";
import { useEffect } from "react";
import Typography from "@mui/material/Typography";
import DescriptionIcon from "@mui/icons-material/Description";
import {
  useGetAccountStatementListMutation,
  downloadStatement,
} from "services/banking-api";
import { BANKING_API_FIXTUTES } from "../SandBoxData/banking-api-fixtures";
import { Statement } from "models/Requests";
import { buildStatementData, formatPeriod } from "./services";
import React from "react";
import {
  RowStatementHeaderWrapper,
  RowStatementWrapper,
  SVGDownloadWrapper,
} from "./styles";

/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */

type Props = {
  mockData: Statement[];
};

const Statements = ({ mockData }: Props): JSX.Element => {
  const [getTransactionsSinceByIdAndToken, { data, isLoading }] =
    useGetAccountStatementListMutation();

  const { currentAccountId, currentCustomerId } = useAuthBanking();

  useEffect(() => {
    getTransactionsSinceByIdAndToken({
      customerId: currentCustomerId,
    });
  }, [getTransactionsSinceByIdAndToken, currentAccountId, currentCustomerId]);

  if (isLoading || (!data && !mockData)) return <LoadingComponent />;

  const rowsData = buildStatementData(data || mockData);

  return (
    <Grid container item lg={12} spacing={2} m={2} mt={5}>
      <Grid item lg={12}>
        <CustomCard
          shadow="xstrong"
          borderRadius="strong"
          styles={{ width: "inherit", height: "calc(100vh - 150px)" }}
        >
          <P size="large" emphasis margin="10px 0px">
            Statements
          </P>
          <StatementListComponent rowsData={rowsData} />
        </CustomCard>
      </Grid>
    </Grid>
  );
};

type RowStatementProps = {
  id: string;
  period: string;
};
const RowStatement: React.FC<RowStatementProps> = ({ id, period }) => {
  const handleDownloadStatement = () => {
    downloadStatement(period, id);
  };

  return (
    <RowStatementWrapper key={id}>
      <P size="medium" emphasis>
        {formatPeriod(period)}
      </P>
      <SVGDownload onClick={handleDownloadStatement} />
    </RowStatementWrapper>
  );
};

export default (): JSX.Element => {
  const MockData = BANKING_API_FIXTUTES.getListStatements;

  return <Statements mockData={MockData} />;
};

const SVGDownload = ({ onClick }: { onClick: () => void }) => (
  <SVGDownloadWrapper onClick={onClick}>
    <svg width="24" height="24" viewBox="0 0 24 24">
      <path d="M5 20H19V18H5V20ZM19 9H15V3H9V9H5L12 16L19 9Z" fill="#1E1E1E" />
    </svg>
  </SVGDownloadWrapper>
);

type StatementListProps = {
  rowsData: { id: string; period: string }[];
};

const StatementListComponent: React.FC<StatementListProps> = ({
  rowsData,
}): JSX.Element => {
  if (rowsData && rowsData.length > 0) {
    return (
      <div>
        <RowStatementHeaderWrapper>
          <P size="xsmall" colorOpacity="50%">
            Statement period
          </P>
        </RowStatementHeaderWrapper>
        {rowsData.map(({ id, period }, index) => (
          <RowStatement key={index} id={id} period={period} />
        ))}
      </div>
    );
  }

  return (
    <div style={{ opacity: 0.75, marginTop: 50 }}>
      <Typography align="center" variant="h2" component="h1">
        <DescriptionIcon style={{ fontSize: 40 }} />
      </Typography>
      <Typography align="center" variant="h6">
        No statements yet
      </Typography>
      <br />
      <Typography align="center" variant="body2">
        There are currently no statements associated with your account.
        <br />
        As statements are created, they will start to be available here.
      </Typography>
    </div>
  );
};
