import { Grid } from "@mui/material";
import { P } from "components/Fonts";
import {
  ControlledSelectField,
  ControlledTextField,
} from "components/FormElements/FormControllers";
import { Controller, useFormContext } from "react-hook-form";
import countries from "resources/countries";
import { isValidPhoneNumber } from "react-phone-number-input";
import {
  PhoneInputError,
  PhoneInputLabel,
  StyledDatePicker,
  StyledPhoneInputWithCountry,
} from "./styles";
import "react-phone-number-input/style.css";
import "react-datepicker/dist/react-datepicker.css";
import stateKeys from "resources/stateKeys";

const CardData: React.FC<{}> = () => {
  const {
    register,
    control,
    formState: { errors },
    watch,
  } = useFormContext();
  const COMMON_PROPS = { control: control, errors: errors, register: register };
  const watchCountry = watch("country");

  return (
    <>
      <P size="Large" emphasis paddingBottom={2}>
        Who is this card for?
      </P>

      <Grid container>
        <Grid item xs={12} sm={12}>
          <ControlledTextField
            required
            {...COMMON_PROPS}
            name="firstName"
            label="First Name"
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <ControlledTextField
            required
            {...COMMON_PROPS}
            name="lastName"
            label="Last Name"
          />
        </Grid>

        <Grid item xs={12}>
          <ControlledTextField
            required
            {...COMMON_PROPS}
            name="address1"
            helperText="Your main address"
            label="Your address line 1"
          />
        </Grid>
        <Grid item xs={12}>
          <ControlledTextField
            {...COMMON_PROPS}
            name="address2"
            helperText="Any extra address details (e.g. apartment number, etc..)"
            label="Your address line 2"
          />
        </Grid>
        <Grid item xs={12} sm={12} mb={1}>
          <ControlledSelectField
            required
            {...COMMON_PROPS}
            native
            label="Country or Territory"
            name={"country"}
            options={[
              { value: "", name: "" },
              ...countries
                .sort((a, b) => a.label.localeCompare(b.label))
                .map((country) => ({
                  value: country.code,
                  name: country.label,
                })),
            ]}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ControlledTextField
            required
            {...COMMON_PROPS}
            name="city"
            label="Your city"
          />
        </Grid>

        <Grid item xs={12} sm={6} pl={2}>
          {watchCountry === "US" ? (
            <ControlledSelectField
              required
              {...COMMON_PROPS}
              native
              label="Your state"
              name="state"
              options={[
                { value: "", name: "" },
                ...Object.entries(stateKeys).map(([key, value]) => ({
                  name: value,
                  value: key,
                })),
              ]}
            />
          ) : (
            <ControlledTextField
              required
              {...COMMON_PROPS}
              name="state"
              label="Your state"
            />
          )}
        </Grid>

        <Grid item xs={12} sm={12}>
          <ControlledTextField
            required
            {...COMMON_PROPS}
            name="postalCode"
            label="Zip / Postal code"
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <PhoneInputLabel>Date of birth*</PhoneInputLabel>
          <Controller
            control={control}
            name="dateOfBirth"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <StyledDatePicker
                name="dateOfBirth"
                onChange={onChange}
                selected={value}
                dateFormat="yyyy-MM-dd"
                showYearDropdown
                scrollableYearDropdown
                placeholderText="Click to select your date of birth"
                yearDropdownItemNumber={80}
                maxDate={new Date()}
              />
            )}
          />
          {errors["dateOfBirth"] && (
            <PhoneInputError>The date of birth is required</PhoneInputError>
          )}
        </Grid>
        <Grid item xs={12} sm={6} pl={2}>
          <PhoneInputLabel>Phone number*</PhoneInputLabel>
          <Controller
            name="phone"
            control={control}
            rules={{ required: true, validate: isValidPhoneNumber }}
            render={({ field: { onChange, value } }) => (
              <StyledPhoneInputWithCountry
                name="phone"
                value={value}
                onChange={onChange}
                defaultCountry="US"
                international
              />
            )}
          />
          {errors["phone"] && (
            <PhoneInputError>The phone number is invalid</PhoneInputError>
          )}
        </Grid>
        <Grid item xs={12} sm={12}>
          <ControlledTextField
            required
            {...COMMON_PROPS}
            name="email"
            label="E-mail address"
            rules={{
              minLength: { value: 3, message: "Invalid email address" },
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: "Invalid email address",
              },
            }}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default CardData;
