import TextField, { TextFieldProps } from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import { RegisterOptions } from "react-hook-form/dist/types";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Radio from "@mui/material/Radio";
import RadioGroup, { RadioGroupProps } from "@mui/material/RadioGroup";
import FormLabel from "@mui/material/FormLabel";
import { forwardRef } from "react";
import { P } from "components/Fonts";

const SmallInputLabel: any = InputLabel;

export type ExtraProps = {
  rules?: RegisterOptions;
  noFullWidth?: boolean;
  required?: boolean;
  helperText?: string;
  disableHelpertext?: boolean;
};

export interface StyledFieldProps {
  error?: boolean;
  label?: string | React.ReactElement;
}

export type SelectProps = {
  native?: boolean;
};

export interface Option {
  name: string | JSX.Element;
  value: string;
}

export type RadioGroupCustomProps = {
  options: Option[];
} & StyledFieldProps;

export type SelectCustomProps = {
  options: Option[];
  name?: string;
  value?: string;
  autoComplete?: string;
  onChange?: any;
  style?: React.CSSProperties;
  dependentSelectValue?: string;
} & StyledFieldProps;

export const StyledTextField = forwardRef((props: TextFieldProps, ref) => {
  const { children, helperText, label, ...componentProps } = props;

  return (
    <TextField
      variant="standard"
      inputRef={ref}
      fullWidth
      {...componentProps}
      label={
        <P size="small" display="contents">
          {label}
        </P>
      }
      helperText={<span style={{ fontSize: "12px" }}>{helperText || " "}</span>}
      sx={{
        ".MuiInputLabel-root": {
          color: "rgba(0, 0, 0, 0.5)",
        },
        ".MuiFormHelperText-root": {
          color: "rgba(0, 0, 0, 0.75)",
        },
        input: {
          fontStyle: "normal",
          fontSize: "1.125rem",
        },
        ".MuiInput-root:before": {
          borderBottom: "1px solid rgba(0, 0, 0, .42)",
        },
      }}
    >
      {children}
    </TextField>
  );
});

export const StyledSelect = forwardRef(
  (props: SelectProps & ExtraProps & SelectCustomProps, ref) => {
    const {
      options,
      helperText,
      native,
      name,
      error,
      required,
      noFullWidth,
      disableHelpertext,
      label,
      dependentSelectValue,
      ...componentProps
    } = props;
    const labelText =
      typeof label === "string" ? `${label}${(required && " *") || ""}` : label;

    return (
      <FormControl
        fullWidth={!noFullWidth}
        variant="standard"
        sx={{
          ".MuiInputLabel-root": {
            color: "rgba(0, 0, 0, 0.5)",
          },
          ".MuiInput-root:before": {
            borderBottom: "1px solid rgba(0, 0, 0, .42)",
          },
          ".MuiSvgIcon-root": {
            color: "1px solid rgba(0, 0, 0, .54)",
          },
        }}
      >
        {label && (
          <SmallInputLabel error={error} size="small">
            <P size="small" display="contents" colorOpacity="50%">
              {labelText}
            </P>
          </SmallInputLabel>
        )}

        <Select
          native={native}
          {...componentProps}
          size="small"
          error={error}
          inputRef={ref}
          label={labelText}
        >
          {options.map((option: Option) => {
            const isWyomingLLC =
              dependentSelectValue === "LLC" && option.value === "WY";
            const isCCorpDelaware =
              dependentSelectValue === "CCorp" && option.value === "DE";

            return !native ? (
              <MenuItem
                key={`${option.name}_${option.value}`}
                value={option.value}
                style={{
                  fontSize: "14px",
                }}
              >
                <div className="selectedOption" style={{ fontSize: "16px" }}>
                  {option.name}
                  {isWyomingLLC || isCCorpDelaware ? (
                    <small style={{ fontSize: "12px" }}>
                      &nbsp;({isWyomingLLC ? "Most Popular" : "Recommended"})
                    </small>
                  ) : (
                    ""
                  )}
                </div>
              </MenuItem>
            ) : (
              <option
                key={`${option.name}_${option.value}`}
                value={option.value}
              >
                {option.name}
              </option>
            );
          })}
        </Select>
        {!disableHelpertext && helperText && (
          <FormHelperText error={error}>{helperText || " "}</FormHelperText>
        )}
      </FormControl>
    );
  }
);

//https://react-hook-form.com/api/usecontroller/controller
//CheckboxProps & ExtraProps & CheckboxCustomProps

export const StyledCheckbox = forwardRef((props: any, ref) => {
  const {
    helperText,
    name,
    value,
    error,
    required,
    noFullWidth,
    disableHelpertext,
    label,
    register,
    ...componentProps
  } = props;
  const labelText =
    typeof label === "string" ? `${label}${(required && " *") || ""}` : label;

  return (
    <FormControl fullWidth={!noFullWidth}>
      {label ? (
        <FormControlLabel
          labelPlacement="end"
          control={
            <Checkbox
              checked={value}
              name={name}
              {...componentProps}
              onBlur={componentProps.onBlur}
              onChange={(e) => props.onChange(e.target.checked)}
              inputRef={ref}
            />
          }
          label={labelText}
        />
      ) : (
        <Checkbox
          checked={value}
          name={name}
          {...componentProps}
          onBlur={componentProps.onBlur}
          onChange={(e) => props.onChange(e.target.checked)}
          inputRef={ref}
        />
      )}

      {!disableHelpertext && (
        <FormHelperText error={error}>{helperText || " "}</FormHelperText>
      )}
    </FormControl>
  );
});

export const StyledRadioGroup = forwardRef(
  (props: RadioGroupProps & ExtraProps & RadioGroupCustomProps, ref) => {
    const {
      options,
      helperText,
      error,
      name,
      required,
      noFullWidth,
      disableHelpertext,
      label,
      ...componentProps
    } = props;
    const labelText =
      typeof label === "string" ? `${label}${(required && " *") || ""}` : label;
    return (
      <FormControl fullWidth={!noFullWidth} component="fieldset">
        <FormLabel component="legend">{labelText}</FormLabel>

        <RadioGroup {...componentProps}>
          {options.map((option: Option) => {
            return (
              <FormControlLabel
                key={`${option.name}_${option.value}`}
                control={<Radio />}
                value={option.value}
                label={option.name}
              />
            );
          })}
        </RadioGroup>

        {!disableHelpertext && (
          <FormHelperText error={error}>{helperText || " "}</FormHelperText>
        )}
      </FormControl>
    );
  }
);
