type VariantNames = "Old" | "New";

type VariantsStr = "0" | "1" | string | undefined;

export class AbTestPricing {
  variant: VariantNames;

  constructor(variantSelected?: VariantsStr) {
    this.variant = this._matchNumberVariant(variantSelected);
  }

  isOldPricingPage = (): boolean => this.variant === "Old";
  isNewPricingPage = (): boolean => this.variant === "New";
  variantName = () => this.variant;

  _matchNumberVariant = (variantStr: VariantsStr): VariantNames => {
    switch (variantStr) {
      case "0":
        return "Old";
      case "1":
        return "New";
      default:
        return "New";
    }
  };
}
