export enum PaymentOptionEnum {
  ach = "ach",
  wire = "wire",
  internationalTransfer = "international-transfer",
}

type ValueOf<T> = T[keyof T];

export type PaymentOption = ValueOf<PaymentOptionEnum>;

export type MakePaymentForm = {
  // slide 1
  recipientName: string;
  recipientEmail: string;
  notifyRecipient: boolean;
  // slide 2
  optionType: PaymentOption;
  // slide 3
  intTransRecipientType: string;
  intTransCountry: string;
  aChRoutingNumber: string;
  intTransSwiftBic: string;
  description: string;
  accountNumber: string;
  aChAccountType: string;
  // slide 4
  recipientCountry: string;
  recipientAddress1: string;
  recipientAddress2: string;
  recipientCity: string;
  recipientState: string;
  recipientPostalCode: string;
  // slide 5
  transactionValue: number;
  transactionMessage: string;
  // slide 7
  verificationToken: string;
  verificationCode: string;
};
