import Grid from "@mui/material/Grid";
import { P } from "components/Fonts";
import { WrappAnimation } from "./styles";

type Props = {
  text?: string;
};

export const LoadingComponent: React.FC<Props> = ({ text = "Loading..." }) => (
  <Grid
    container
    spacing={0}
    direction="column"
    justifyContent="center"
    style={{ height: "100%", textAlign: "center" }}
  >
    <Grid item>
      <WrappAnimation>
        <P size="medium" colorOpacity="50%">
          {text}
        </P>
      </WrappAnimation>
    </Grid>
  </Grid>
);
