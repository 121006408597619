import { CustomCard } from "components/Cards";
import { P } from "components/Fonts";
import { StatusLabel } from "components/StatusLabel";
import { FunctionComponent } from "react";
import { STEPS_DATA } from "./data";
import { StatusLabelWrapper, StepWrapper } from "./styles";
import { SectionType } from "./types";
import { StatussesData, StatussesKey } from "../Services/StatusKey";

type Props = {};

export const HowItWork: FunctionComponent<Props> = () => (
  <CustomCard shadow="strong" borderRadius="strong">
    <P size="medium" emphasis>
      How it works
    </P>
    <br />
    {STEPS_DATA.map((sectionData, index) => (
      <Section key={index} {...sectionData} />
    ))}
  </CustomCard>
);

const Section: FunctionComponent<SectionType> = ({
  title,
  description,
  referralStatusKey,
}) => {
  const sectionData = StatussesData[referralStatusKey as StatussesKey];
  return (
    <StepWrapper>
      <P size="small">{title}</P>
      <P size="small" colorOpacity="75%">
        {description}
      </P>
      <StatusLabelWrapper>
        <StatusLabel label={sectionData.label} color={sectionData.color} />
      </StatusLabelWrapper>
    </StepWrapper>
  );
};
