import { useEffect, useRef, useState } from "react";
import {
  Box,
  ClickAwayListener,
  Paper,
  Popper,
  Stack,
  useTheme,
} from "@mui/material";
import { P } from "components/Fonts";
import { useAuth } from "hooks/useAuth";
import { useCurrentCompanyMutation } from "services/companies-api";
import utils from "utils";
import { CompanySelectorContent } from "./CompanySelectorContent";
import { SelectorDataType } from "./CompanySelectorContent/types";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Transitions from "components/@extended/Transitions";
import { IconButtonStyled } from "./styles";
import Avatar from "components/@extended/Avatar";
import { getInitials } from "utils/formatters";
import MainCard from "components/MainCard";
import useCurrentUser from "graphql/hooks/useCurrentUser";

const CompanySelector: React.FC<{}> = () => {
  const { currentCompany } = useAuth();
  const { currentUser } = useCurrentUser();
  const theme = useTheme();
  const [fetchCompany] = useCurrentCompanyMutation();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<any>(null);

  const [companySelectorData, setCompanySelectorData] =
    useState<SelectorDataType>({
      data: [],
      loading: true,
    });

  useEffect(() => {
    if (currentUser?.companyIds) {
      Promise.all(
        currentUser.companyIds.map((id: string) => fetchCompany(id))
      ).then((values) => {
        setCompanySelectorData({
          data: values.map((e: any) => e.data),
          loading: false,
        });
      });
    }
  }, [fetchCompany]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: MouseEvent | TouchEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  if (companySelectorData.loading || !currentCompany || !currentUser)
    return null;

  const isDarkMode = utils.isBankingRoute();
  const iconColor = theme.palette.mode === "dark" ? "grey.200" : "grey.900";

  return (
    <Box mt={2}>
      <IconButtonStyled
        color="secondary"
        variant="light"
        aria-label="open companyselector"
        ref={anchorRef}
        aria-controls={open ? "companyselector-grow" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <Stack
          direction={"row"}
          alignItems="center"
          justifyContent={"space-between"}
          spacing={1}
        >
          <Avatar
            type="outlined"
            sx={{ bgcolor: "#FF7A45" }}
            {...getInitials(currentCompany.companyDetails.name)}
          />
          <P
            size={"small"}
            color={isDarkMode ? utils.colors.white : "inherit"}
            style={{ textOverflow: "ellipsis", overflow: "hidden" }}
          >
            {currentCompany.companyDetails.name}
          </P>
          <MoreVertIcon sx={{ color: iconColor }} />
        </Stack>
      </IconButtonStyled>
      <Popper
        placement={"bottom-start"}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal={false}
        sx={{ zIndex: "1200" }}
        popperOptions={{
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, 9],
              },
            },
          ],
        }}
      >
        {({ TransitionProps }) => (
          <Transitions type="fade" in={open} {...TransitionProps}>
            {open && (
              <Paper
                sx={{
                  boxShadow: theme.customShadows.z1,
                  width: 290,
                  minWidth: 240,
                  maxWidth: 290,
                  [theme.breakpoints.down("md")]: {
                    maxWidth: 250,
                  },
                }}
              >
                <ClickAwayListener onClickAway={handleClose}>
                  <MainCard elevation={0} border={false} content={false}>
                    <CompanySelectorContent
                      companies={companySelectorData.data}
                      currentCompany={currentCompany}
                      user={currentUser}
                    />
                  </MainCard>
                </ClickAwayListener>
              </Paper>
            )}
          </Transitions>
        )}
      </Popper>
    </Box>
  );
};

export default CompanySelector;
