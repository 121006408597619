import { Grid } from "@mui/material";
import { WrappFadeAnimation } from "assets/GlobalStyled";
import { P } from "components/Fonts";
import {
  ControlledSelectField,
  ControlledTextField,
} from "components/FormElements/FormControllers";
import { useFormContext } from "react-hook-form";
import countries from "resources/countries";

/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default (): JSX.Element => {
  const {
    register,
    control,
    formState: { errors },
  } = useFormContext();
  const COMMON_PROPS = { control: control, errors: errors, register: register };

  return (
    <>
      <WrappFadeAnimation>
        <P size="Large" emphasis>
          Recipients Legal Address
        </P>

        <Grid container spacing={1}>
          <Grid item xs={12} sm={12}>
            <ControlledSelectField
              {...COMMON_PROPS}
              required={true}
              native={true}
              label="Country or Territory"
              name={"recipientCountry"}
              options={[
                { value: "", name: "" },
                ...countries
                  .sort((a, b) => a.label.localeCompare(b.label))
                  .map((country) => ({
                    value: country.label,
                    name: country.label,
                  })),
              ]}
            />
          </Grid>
          <Grid item xs={12}>
            <ControlledTextField
              {...COMMON_PROPS}
              name="recipientAddress1"
              required
              helperText="Your main address"
              label="Your address line 1"
              autoComplete="shipping address-line1"
            />
          </Grid>
          <Grid item xs={12}>
            <ControlledTextField
              {...COMMON_PROPS}
              name="recipientAddress2"
              helperText="Any extra address details (e.g. apartment number, etc..)"
              label="Your address line 2"
              autoComplete="shipping address-line2"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <ControlledTextField
              {...COMMON_PROPS}
              name="recipientCity"
              required
              label="Your city"
              autoComplete="shipping address-level2"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <ControlledTextField
              {...COMMON_PROPS}
              required
              name="recipientState"
              label="Your state"
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <ControlledTextField
              {...COMMON_PROPS}
              required
              name="recipientPostalCode"
              label="Zip / Postal code"
              autoComplete="shipping postal-code"
            />
          </Grid>
        </Grid>
      </WrappFadeAnimation>
    </>
  );
};
