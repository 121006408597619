import { CustomCard } from "components/Cards";
import { P } from "components/Fonts";
import { CustomButton } from "components/Buttons";
import { Stack, Typography, useMediaQuery } from "@mui/material";
import { CustomButtonVariants } from "components/Buttons/types";
import utils from "utils";
import { BannerImg } from "./styles";
import { IBannerProps } from "./types";

export const Banner: React.FC<IBannerProps> = ({
  textColor,
  bgColor,
  imageSrc,
  title,
  text,
  buttonText,
  buttonType,
  onClick,
}) => {
  const isTablet = useMediaQuery(`(max-width:${utils.screenSize.tablet})`);

  return (
    <CustomCard
      styles={{
        position: "relative",
        background: bgColor,
      }}
      shadow="xstrong"
      borderRadius="strong"
      padding={3}
    >
      {!isTablet && <BannerImg src={imageSrc} alt={title} />}
      <Stack sx={{ maxWidth: "70%" }} spacing={3} mb={3}>
        <P size="large" color={textColor} emphasis>
          {title}
        </P>
        <P size="medium" color={textColor}>
          {text}
        </P>
      </Stack>
      <CustomButton
        onClick={onClick}
        variant={buttonType || CustomButtonVariants.black}
        style={{
          minWidth: "200px",
        }}
      >
        <Typography fontSize={16}>{buttonText}</Typography>
      </CustomButton>
    </CustomCard>
  );
};
