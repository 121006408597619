import { Grid } from "@mui/material";
import { CustomCard } from "components/Cards";
import { P } from "components/Fonts";
import { Label } from "components/Label";
import utils from "utils";
import {
  SVGPhysicalCardMini,
  SVGVirtualCardMini,
} from "views/DashboardApp/Banking/SharedComponents/SVGs";
import {
  CustomizeCardWrapper,
  LabelWrapper,
  OptionCardSelectedStyled,
  OptionCardStyled,
} from "./styles";
/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */

type Props = {};

export default (props: Props): JSX.Element => {
  // const {
  //   register,
  //   control,
  //   formState: { errors },
  // } = useFormContext();
  // const COMMON_PROPS = { control: control, errors: errors, register: register };

  return (
    <CustomizeCardWrapper>
      <P size="Large" emphasis>
        Customize Card
      </P>

      <Grid container>
        <CardOptions />

        {/* <Grid item xs={12} sm={12}>
          <ControlledTextField
            {...COMMON_PROPS}
            name="nickName"
            label="Card nickname"
          />
        </Grid>

        <Grid item xs={12} sm={12}>
          <ControlledSelectField
            {...COMMON_PROPS}
            required={true}
            native={true}
            label="Account number"
            name={"limitType"}
            options={[{ value: "Daily", name: "Daily" }]}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <ControlledTextField
            required
            {...COMMON_PROPS}
            name="dailySpendingLimit"
            label={"Daily spending limit $"}
            type="number"
          />
        </Grid> */}
      </Grid>
    </CustomizeCardWrapper>
  );
};

const CardOptions: React.FC = () => {
  return (
    <>
      <Grid item xs={12} sm={6} mt={3}>
        <CustomCard
          borderRadius="strong"
          styles={OptionCardSelectedStyled}
          padding={1}
        >
          <SVGVirtualCardMini />
          Virtual Card
        </CustomCard>
      </Grid>

      <Grid item xs={12} sm={6} mt={3}>
        <CustomCard borderRadius="strong" styles={OptionCardStyled} padding={1}>
          <LabelWrapper>
            <Label
              color={`${utils.colors.grass}50`}
              text={<b>COMING SOON</b>}
            />
          </LabelWrapper>
          <SVGPhysicalCardMini />
          Physical Card
        </CustomCard>
      </Grid>
    </>
  );
};
