import { useAuth } from "hooks/useAuth";
import { useLocation } from "react-router-dom";
import { EF_UTILS } from "resources/everflow";
import { TP_GA4_CLICK_EVENT_NAMES } from "resources/googleAnalytics";
import { Tracker } from "services/tracking";
import { selectEverflowPartner } from "state/authSlice";
import { selectFormSubmit } from "state/formSlice";
import { useAppSelector } from "state/hooks";
import { AbTestPricing } from "./ABtest";

const tracker = Tracker.getInstance();
type Props = {
  children: JSX.Element | string;
  event: TP_GA4_CLICK_EVENT_NAMES;
  labelReference?: string;
};

const ClickTracker: React.FC<Props> = ({ children, event, labelReference }) => {
  const location = useLocation();
  const { currentTestABVariant } = useAuth();
  const { partnerId } = useAppSelector(selectEverflowPartner);
  const formSubmitState = useAppSelector(selectFormSubmit);
  const { planSelected, email } = formSubmitState;

  const commonParams = {
    ABtestingVariant: new AbTestPricing(currentTestABVariant).variantName(),
    everflowPartnerName: EF_UTILS.patnerName(partnerId),
    GA4Source: location.pathname,
    GA4Reference: labelReference || "",
    email: email || "",
    planId: planSelected?.stripePriceId || "",
  };

  return (
    <span
      style={{ display: "initial" }}
      onClick={() => tracker.trackGA4Custom(event, commonParams)}
    >
      {children}
    </span>
  );
};

export default ClickTracker;
