import InfoModal from "components/InfoModal";
import { CompanyDetails, PersistedCompany } from "models/CompanyModel";
import { CustomerTypeforms, ICurrentUser } from "models/CustomerModel";
import { memo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BusinessTypeNames } from "resources/entityTypes";
import { ROUTER_PATHES } from "routes/routes";
import CompanyFormationTypeform from "./CompanyFormationTypeform";

type Props = {
  user: ICurrentUser;
  company: PersistedCompany;
};

const shouldTypeformBeShown = (
  typefromObj: CustomerTypeforms | undefined,
  companyDetails: CompanyDetails
) => {
  if (!typefromObj || companyDetails.owners) return false; // fix it if find the solution how to set all existing users with typeforms val
  return Object.values(typefromObj).includes("");
};

const MissingFormPopup: React.FC<Props> = ({ user, company }) => {
  const navigate = useNavigate();
  const { typeforms } = user;
  const { companyDetails } = company;

  const [showTypeform, setShowTypeform] = useState<boolean>(
    shouldTypeformBeShown(typeforms, companyDetails)
  );

  if (!showTypeform || !typeforms) return <></>;

  const callbackSuccess = () => {
    setShowTypeform(false);
    navigate(`${ROUTER_PATHES.DASHBOARD}?origin=success`);
    window.location.reload();
  };

  const extraHiddenVariables = {
    customer_id_hidden: user.id,
    email_hidden: user.email,
    fullname_hidden: user.fullName,
    phone_hidden: user.phone,
    company_name_hidden: companyDetails.name,
    company_state_abbreviated_hidden: companyDetails.geographicState,
    llc_name_hidden: companyDetails.businessType.name as BusinessTypeNames,
    llc_style_hidden: companyDetails.businessType.style,
  };

  return (
    <InfoModal
      title={""}
      fullWidth
      notShowCloseButton
      description={
        <CompanyFormationTypeform
          callbackSuccess={callbackSuccess}
          extraHiddenVar={extraHiddenVariables}
        />
      }
      handleClose={() => {}}
    />
  );
};

export default memo(MissingFormPopup);
