import { css } from "styled-components/macro";

export const NoNotificationsWrapper = css`
  width: 200px;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: auto;
`;
