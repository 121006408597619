import {
  Step1,
  Step2,
  Step3,
  Step4,
  Step5,
  Step6,
  Step7,
  Step8,
} from "./MakePaymentSteps";
import { PaymentOption } from "./types";

export const FlowSteps = [
  {
    route: "/recipient",
    render: () => <Step1 />,
  },
  { route: "/transfer-option", render: () => <Step2 /> },
  { route: "/ach-details", render: () => <Step3 /> },
  { route: "/recipient-address", render: () => <Step4 /> },
  { route: "/transaction-details", render: () => <Step5 /> },
  {
    route: "/summary",
    render: (onNext: () => void) => <Step6 onNext={onNext} />,
  },
  { route: "/2FA", render: () => <Step7 /> },
  { route: "/success", render: () => <Step8 /> },
];

export const FORM_INIT_VALUES = {
  // slide 1
  recipientName: "",
  recipientEmail: "",
  notifyRecipient: false,
  // slide 2
  optionType: "ach",
  // slide 3
  intTransRecipientType: "",
  intTransCountry: "",
  aChRoutingNumber: "",
  intTransSwiftBic: "",
  description: "",
  accountNumber: "",
  aChAccountType: "",
  // slide 4
  recipientCountry: "",
  recipientAddress1: "",
  recipientAddress2: "",
  recipientCity: "",
  recipientState: "",
  recipientPostalCode: "",
  // slide 5
  transactionValue: 0,
  transactionMessage: "",
};

export const logicOverShowContinue = (currentStep: number) => {
  const isLastStep = currentStep === FlowSteps.length - 1;
  const isVerifyStep = currentStep === FlowSteps.length - 2;
  const isSummaryPayment = currentStep === FlowSteps.length - 3;

  if (isLastStep || isSummaryPayment || isVerifyStep) return false;
  return true;
};

export const logicOverShowBack = (currentStep: number) => {
  const isfirstStep = currentStep === 0;
  const isLastStep = currentStep === FlowSteps.length - 1;

  if (isfirstStep || isLastStep) return false;
  return true;
};

export const getPaymentOptionTitle = (optionType: PaymentOption) => {
  switch (optionType) {
    case "ach":
      return "ACH ";
    case "international-transfer":
      return "International Wire ";
    case "wire":
      return "Wire ";
    default:
      return "ACH ";
  }
};
