import { FunctionComponent } from "react";
import { P } from "components/Fonts";
import { CustomCard } from "components/Cards";
import VerticalLinearStepper from "./Stepper";
import { ChartProgress } from "./ChartProgress";
import { StyledWrapperCircularProgress } from "./styles";
import { TStepData } from "./Stepper/types";

type Props = {
  title: string;
  percentajeValue: number;
  stepData: TStepData[];
  shadow?: "xstrong" | "strong" | "light" | "none" | undefined;
  style?: any;
};

export const ProgressStepper: FunctionComponent<Props> = ({
  title,
  percentajeValue,
  stepData,
  shadow = "xstrong",
  style = {},
}) => {
  return (
    <CustomCard
      styles={{ display: "grid", position: "relative", ...style }}
      shadow={shadow}
      borderRadius="strong"
    >
      <P size="large" emphasis={true}>
        {title}
      </P>
      <br />

      <StyledWrapperCircularProgress>
        <ChartProgress value={validatePercentajeValue(percentajeValue)} />
      </StyledWrapperCircularProgress>

      <VerticalLinearStepper stepData={stepData} />
    </CustomCard>
  );
};

const validatePercentajeValue = (value: number) => {
  const maxValue = 100;
  const minValue = 0;
  return value > maxValue ? maxValue : value < minValue ? minValue : value;
};
