import { PersistedCompany } from "models/CompanyModel";
import {
  CustomerTypeformsDefined,
  PersistedCustomer,
} from "models/CustomerModel";
import { emptySplitAuthApi } from "./empty-auth-api";

const FORMS_URL = `/forms`;

type DeepPartial<T> = {
  [P in keyof T]?: DeepPartial<T[P]>;
};

type RecurringProduct = {
  recurringProductId: string;
};

type FormationRecurringProduct = RecurringProduct & {
  type: string;
};

interface SubmitCompanyFromFormPayload {
  paymentMethodId: string;
  email: string;
  recurringProductIds: [FormationRecurringProduct, ...RecurringProduct[]];
  companyState?: string;
  companyStateAbbreviated: string;
  promotionCode: string;
  couponId: string;
  companyId: string;
  customerId: string;
  referral: string;
  businessType: {
    name: string;
    style: string;
  };
  signupTypeformRequiered: CustomerTypeformsDefined;
}

interface SubmitCompanyFromFormResponse {
  client_secret: string;
  status: string;
}

interface SubmitTypeformFormPayload {
  typeformId: string;
  responseId: string;
}

export const formsApi = emptySplitAuthApi.injectEndpoints({
  endpoints: (builder) => ({
    createOrUpdateCustomerFromForm: builder.mutation<
      Partial<PersistedCustomer> & { id: string },
      Partial<PersistedCustomer>
    >({
      query: (formData) => ({
        url: `${FORMS_URL}/`,
        method: "POST",
        body: {
          ...formData,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
      }),
    }),
    createOrUpdateCompanyFromForm: builder.mutation<
      DeepPartial<PersistedCompany> & { id: string },
      DeepPartial<PersistedCompany & { customerId: string }>
    >({
      query: (formData) => ({
        url: `${FORMS_URL}/companies`,
        method: "POST",
        body: {
          ...formData,
        },
      }),
    }),
    submitCompanyFromFormOld: builder.mutation<
      [SubmitCompanyFromFormResponse],
      SubmitCompanyFromFormPayload
    >({
      query: (formData) => ({
        url: `${FORMS_URL}/submit-old`,
        method: "POST",
        body: {
          payment_method: formData.paymentMethodId,
          email: formData.email,
          recurringProductIds: formData.recurringProductIds,
          companyState: formData.companyState,
          companyStateAbbreviated: formData.companyStateAbbreviated,
          promotionCode: formData.promotionCode,
          couponId: formData.couponId,
          companyId: formData.companyId,
          customerId: formData.customerId,
          referral: formData.referral,
          businessType: formData.businessType,
          signupTypeformRequiered: formData.signupTypeformRequiered,
        },
      }),
    }),
    submitTypeformForm: builder.mutation<any, SubmitTypeformFormPayload>({
      query: ({ typeformId, responseId }) => ({
        url: `${FORMS_URL}/typeform/${typeformId}/submit-callback/${responseId}`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useCreateOrUpdateCustomerFromFormMutation,
  useCreateOrUpdateCompanyFromFormMutation,
  useSubmitCompanyFromFormOldMutation,
  useSubmitTypeformFormMutation,
} = formsApi;
