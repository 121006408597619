import utils from "utils";

type IconTransferprops = {
  selected?: boolean;
};

const IconOutCommingTransfer: React.FC<IconTransferprops> = ({ selected }) => (
  <svg
    width="29"
    height="29"
    viewBox="0 0 29 29"
    fill="none"
    style={{ marginTop: "5px" }}
  >
    <rect
      opacity={selected ? "1" : "0.1"}
      x="29"
      y="29"
      width="29"
      height="29"
      rx="12"
      transform="rotate(-180 29 29)"
      fill={utils.colors.pink}
    />
    <g clipPath="url(#clip0_5160_3283)">
      <path
        d="M8.33775 19.627V14.4374C8.33775 14.1514 8.45367 13.8925 8.64111 13.705C8.82855 13.5176 9.08746 13.4017 9.37349 13.4017C9.94549 13.4017 10.4092 13.8654 10.4092 14.4374L10.4092 17.1266L18.8942 8.6416C19.2986 8.23713 19.9544 8.23713 20.3589 8.6416C20.7633 9.04606 20.7633 9.70185 20.3589 10.1063L11.8739 18.5913L14.5631 18.5913C15.1351 18.5913 15.5988 19.055 15.5988 19.627C15.5988 20.199 15.1351 20.6627 14.563 20.6627L9.37345 20.6627C8.80147 20.6627 8.33775 20.199 8.33775 19.627Z"
        fill={selected ? "white" : utils.colors.pink}
      />
    </g>
    <defs>
      <clipPath id="clip0_5160_3283">
        <rect
          width="16.5714"
          height="16.5714"
          fill="white"
          transform="translate(22.7856 22.7861) rotate(-180)"
        />
      </clipPath>
    </defs>
  </svg>
);

const IconInCommingTransfer: React.FC<IconTransferprops> = ({ selected }) => (
  <svg
    width="29"
    height="29"
    viewBox="0 0 29 29"
    fill="none"
    style={{ marginTop: "5px" }}
  >
    <rect
      opacity={selected ? "1" : "0.1"}
      width="29"
      height="29"
      rx="12"
      fill={utils.colors.gold}
    />
    <g clipPath="url(#clip0_5160_12153)">
      <path
        d="M20.6622 9.37297V14.5626C20.6622 14.8486 20.5463 15.1075 20.3589 15.295C20.1715 15.4824 19.9125 15.5983 19.6265 15.5983C19.0545 15.5983 18.5908 15.1346 18.5908 14.5626L18.5908 11.8734L10.1058 20.3584C9.70137 20.7629 9.04559 20.7629 8.64112 20.3584C8.23665 19.9539 8.23665 19.2982 8.64112 18.8937L17.1261 10.4087L14.4369 10.4087C13.8649 10.4087 13.4012 9.94501 13.4012 9.37302C13.4012 8.80098 13.8649 8.3373 14.437 8.33728L19.6266 8.33728C20.1985 8.33728 20.6622 8.801 20.6622 9.37297Z"
        fill={selected ? "white" : utils.colors.gold}
      />
    </g>
    <defs>
      <clipPath id="clip0_5160_12153">
        <rect
          width="16.5714"
          height="16.5714"
          fill="white"
          transform="translate(6.21436 6.21387)"
        />
      </clipPath>
    </defs>
  </svg>
);

export { IconOutCommingTransfer, IconInCommingTransfer };
