import { Grid } from "@mui/material";
import { P } from "components/Fonts";
import React from "react";
import { DataSection } from "./DataSection";
import { DataEnteredSummaryProps } from "./types";

export const DataEnteredSummary: React.FC<DataEnteredSummaryProps> = ({
  contactDetails,
  companyInfo,
  memberData,
  mailingAddress,
  extraData,
}) => {
  return (
    <>
      <Grid container mb={4}>
        <Grid item lg={4} xs={12}>
          <P size="medium">Your Contact Details</P>
        </Grid>
        <Grid item lg={8}>
          <Grid container>
            <DataSection data={contactDetails} />
          </Grid>
        </Grid>
      </Grid>

      <Grid container mb={4}>
        <Grid item lg={4} xs={12}>
          <P size="medium">Company Information</P>
        </Grid>
        <Grid item lg={8}>
          <Grid container>
            <DataSection data={companyInfo} />
          </Grid>
        </Grid>
      </Grid>

      <Grid container mb={4}>
        <Grid item lg={4} xs={12}>
          <P size="medium">Company Members</P>
        </Grid>
        <Grid item lg={8} pt={1}>
          {memberData &&
            memberData.map((memberData, index) => (
              <React.Fragment key={index}>
                <P size="medium">
                  {`${"Member "}`}
                  <small>#</small>
                  {index + 1}
                </P>
                <br />
                <Grid container>
                  <DataSection data={memberData} />
                </Grid>
              </React.Fragment>
            ))}
        </Grid>
      </Grid>

      <Grid container mb={4}>
        <Grid item lg={4} xs={12}>
          <P size="medium">Mailing address</P>
        </Grid>
        <Grid item lg={8}>
          <Grid container>
            {mailingAddress && <DataSection data={mailingAddress} />}
          </Grid>
        </Grid>
      </Grid>

      <Grid container mb={4}>
        <Grid item lg={4} xs={12}>
          <P size="medium">Extras</P>
        </Grid>
        <Grid item lg={8} className=".fs-exclude">
          <Grid container>{extraData && <DataSection data={extraData} />}</Grid>
        </Grid>
      </Grid>
    </>
  );
};
