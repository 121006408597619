import { useEffect, useState } from "react";
import { CustomButton } from "components/Buttons";
import { P } from "components/Fonts";
import { CustomButtonVariants } from "components/Buttons/types";
import { Auth } from "aws-amplify";
import { Grid, Stack, Typography } from "@mui/material";
import { FieldValues, useForm } from "react-hook-form";
import { toast } from "toast";
import * as Sentry from "@sentry/react";
import { useNavigate } from "react-router-dom";
import { ROUTER_PATHES } from "routes/routes";
import { AuthControlledTextField } from "../styles";

export const AuthRecoverPassword: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const navigate = useNavigate();
  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
  } = useForm();
  const COMMON_PROPS = { control: control, errors: errors, register: register };

  useEffect(() => {
    if (redirect) {
      navigate(
        `${ROUTER_PATHES.RESET_PASSWORD}/?username=` +
          encodeURIComponent(userEmail),
        {
          replace: true,
        }
      );
    }
  }, [redirect, userEmail, navigate]);

  const onSubmitHandler = ({ username }: FieldValues) => {
    setIsLoading(true);

    Auth.forgotPassword(username)
      .then(() => {
        setUserEmail(username);
        setRedirect(true);
      })
      .catch((error: any) => {
        Sentry.captureException(error);
        toast.error(error?.message as string);

        setIsLoading(false);
      });
  };

  return (
    <form noValidate onSubmit={handleSubmit(onSubmitHandler)}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Stack spacing={1}>
            <AuthControlledTextField
              {...COMMON_PROPS}
              required
              fullWidth
              id="username"
              label="Email Address"
              name="username"
              autoComplete="email"
              autoFocus
              variant="outlined"
            />
          </Stack>
        </Grid>
        <Grid item xs={12} sx={{ mb: -2 }}>
          <Typography variant="caption">
            Do not forgot to check SPAM box.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <CustomButton
            type="submit"
            variant={CustomButtonVariants.black}
            loading={isLoading}
            width="100%"
          >
            <P size="small">Send Password Reset Email</P>
          </CustomButton>
        </Grid>
      </Grid>
    </form>
  );
};
