import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import config from "config";
import { AUTH_USER_TOKEN_KEY, validateToken } from "utils/auth";

const baseQuery = fetchBaseQuery({
  baseUrl: config.api.URL,
  prepareHeaders: async (headers) => {
    const token = localStorage.getItem(AUTH_USER_TOKEN_KEY);
    const isTokenValid = validateToken(token);

    if (isTokenValid) {
      headers.set("authorization", token!);
    }

    return headers;
  },
});

export const emptySplitAuthApi = createApi({
  baseQuery: baseQuery,
  endpoints: () => ({}),
});
