import styled, { css } from "styled-components/macro";

export const CustomizeCardWrapper = styled.div``;

const communSVGStyles = css`
  position: relative;
  svg {
    height: 45px;
    width: 80px;
    margin-top: 2px;
    margin-right: 10px;
  }
  display: flex;
  align-items: center;
  margin-right: 50px;
`;

export const OptionCardSelectedStyled = css`
  background: #f8f8f8;
  ${communSVGStyles}
`;

export const OptionCardStyled = css`
  background: transparent;
  ${communSVGStyles}
  svg {
    opacity: 0.6;
  }
  color: #919191;
`;

export const LabelWrapper = styled.div`
  position: absolute;
  left: 15px;
  top: -20px;
`;
