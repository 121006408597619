import styled from "styled-components/macro";
import { ShadowLight, ShadowStrong, HoverPointer } from "assets/GlobalStyled";
import utils from "utils";

export const Wrapper = styled.div`
  ${ShadowLight}
  text-align: center;
  background: #fafafa;
  border-radius: 8px;
  display: grid;
  position: relative;

  @media only screen and (max-width: ${utils.screenSize.mobile}) {
    display: none;
  }
`;

export const DiscoverWrapper = styled.div`
  ${HoverPointer}
  color: ${utils.colors.grass};
  padding: 15px;
  border-top: ${utils.colors.fog} solid;
`;

export const NeedGuidanceWrapper = styled.div`
  padding: 15px;
  margin-top: 15px;
`;

export const IconWrapper = styled.div`
  ${ShadowStrong}
  padding: 10px 17px;
  background: #fafafa;
  color: ${utils.colors.gold};
  border-radius: 8px;
  position: absolute;
  left: 50%;
  top: -35px;
  transform: translateX(-50%);
`;
