import styled from "styled-components/macro";

export const CardNumberWrapper = styled.div`
  height: 20px;
  display: block;
  opacity: 0.75;
  :hover {
    opacity: 1;
    cursor: pointer;
  }
`;
export const CCVExpWrapper = styled.div`
  display: -webkit-inline-box;
`;

export const CVCWrapper = styled.div`
  vertical-align: top;
  opacity: 0.75;
  :hover {
    opacity: 1;
    cursor: pointer;
  }
`;
