import styled from "styled-components/macro";
import utils from "utils";
import { ShadowStrong, HoverPointer } from "assets/GlobalStyled";

interface ItemProps {
  readonly isSelected: boolean;
}

export const ItemWrapper = styled.div<ItemProps>`
  ${(props) => (props.isSelected ? ShadowStrong : "")};
  background: ${(props) =>
    props.isSelected ? utils.colors.white : "transparent"};
  color: ${(props) =>
    props.isSelected ? utils.colors.charcoal : utils.colors.middleGray};
  border-radius: 6px;
  margin: 0px 16px 0px 16px;
  display: flex;
  padding: 6px;
  transition: all 0.1s ease-out;
  ${HoverPointer}

  &:hover, &:focus {
    background: ${utils.colors.fog};
  }
`;

export const IconWrapper = styled.div<ItemProps>`
  font-size: 18px;
  margin: auto 10px;
  color: ${(props) =>
    props.isSelected ? utils.colors.gold : utils.colors.middleGray};
`;
