import { ServiceNames, ServiceOptionsNames } from "models/ServiceModel";

export const formatServiceName = (
  serviceName: ServiceNames | ServiceOptionsNames.speedFilingPriority
) => {
  switch (serviceName) {
    case ServiceNames.llcFormation:
      return "LLC Formation";
    case ServiceNames.banking:
      return "Business Bank Account Setup";
    case ServiceNames.einCreation:
      return "EIN (Employer Identification Number)";
    case ServiceNames.annualComplianceReminder:
      return "Annual Compliance Reminders";
    case ServiceNames.phoneLine:
      return "US Phone Line with a Utility Bill";
    case ServiceNames.taxFiling:
      return "Form 5472/1120 Tax Filing";
    case ServiceNames.iTin:
      return "ITIN (Individual Taxpayer Identification Number)";
    case ServiceNames.annualReportFiling:
      return "Annual Compliance Filing";
    case ServiceNames.paymentGateway:
      return "Payment Gateway";
    case ServiceOptionsNames.speedFilingPriority:
      return "Top Priority Filing Speed";
    default:
      return serviceName;
  }
};

export const capitalizedSentence = (mySentence: string) =>
  mySentence?.replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase());

export const getInitials = (myString: string) => {
  return {
    children: myString
      ? ((myString) =>
          myString
            .map((n, i) => (i === 0 || i === myString.length - 1) && n[0])
            .filter((n) => n)
            .join(""))(myString.split(" "))
      : "DO",
  };
};

export const colorWithOpacity = (color: string, opacity: number) =>
  color + opacity;

export const moneyFormatter = (value: number, round: boolean = true) => {
  return new Intl.NumberFormat("en-US", {
    //   style: "currency",
    currency: "USD",
    maximumFractionDigits: round ? 0 : 2,
    // These options are needed to round to whole numbers if that's what you want.
    minimumFractionDigits: round ? 0 : 2, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  }).format(value);
};

export const timeSince = (date: string) => {
  const dateEnteredInSeconds = Date.parse(date);
  const seconds = Math.floor(
    (new Date().valueOf() - dateEnteredInSeconds) / 1000
  );

  const isSingular = (value: number) => value < 2;

  var interval = seconds / 31536000;
  if (interval > 1) {
    return Math.floor(interval) + " years";
  }

  interval = seconds / 2592000;
  if (interval > 1) {
    return Math.floor(interval) + " months";
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return Math.floor(interval) + (isSingular(interval) ? " day" : " days");
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) + (isSingular(interval) ? " hour" : " hours");
  }
  interval = seconds / 60;
  if (interval > 1) {
    return (
      Math.floor(interval) + (isSingular(interval) ? " minute" : " minutes")
    );
  }
  return Math.floor(seconds) + " seconds";
};

/**
 * Banking-specific helpers
 */

export const formatMoney = (amount: number, currency: string = "USD") =>
  new Intl.NumberFormat("en-US", {
    style: "currency",
    currency,
  }).format(amount / 100);

type FormatDateProps = {
  date: string | Date;
  format?: "dots" | "monthWritten";
};

export const formatDate = ({ date, format = "dots" }: FormatDateProps) => {
  const dateEntered = new Date(date);

  switch (format) {
    case "dots":
      const formatValueOverTen = (value: number) => {
        if (value < 10) {
          return "0" + value;
        } else {
          return value;
        }
      };

      return (
        formatValueOverTen(dateEntered.getDate()) +
        "." +
        formatValueOverTen(dateEntered.getMonth() + 1) +
        "." +
        dateEntered.getFullYear()
      );

    default:
      return dateEntered.toDateString();
  }
};

export const formatAddresForYouLLC = (optionSelectedOnSignup: string) => {
  if (optionSelectedOnSignup === "registeredAgent") {
    return "Our US Registered Agent Address (Most Popular)";
  } else {
    return "Your mailing address (the one displayed here)";
  }
};
