import { Grid } from "@mui/material";
import { WrappFadeAnimation } from "assets/GlobalStyled";
import { P } from "components/Fonts";
import { ControlledTextField } from "components/FormElements/FormControllers";
import { useFormContext } from "react-hook-form";

/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default (): JSX.Element => {
  const {
    register,
    control,
    formState: { errors },
  } = useFormContext();

  const COMMON_PROPS = { control: control, errors: errors, register: register };

  return (
    <>
      <WrappFadeAnimation>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12}>
            <ControlledTextField
              required
              {...COMMON_PROPS}
              name="transactionValue"
              label={
                <P size="medium" display="contents">
                  Amount to transfer $
                </P>
              }
              type="number"
            />
            <ControlledTextField
              {...COMMON_PROPS}
              name="transactionMessage"
              label="Message for recipient"
            />
          </Grid>
        </Grid>
      </WrappFadeAnimation>
    </>
  );
};
