import styled from "styled-components/macro";
import CardBalanceSVG from "./card-balance.svg";

export const BalanceWrapper = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  row-gap: 15px;
  color: #ffff;
  background: url("${CardBalanceSVG}");
  background-size: cover;
  background-repeat: no-repeat;
  padding: 30px;
  border-radius: 30px;
`;
